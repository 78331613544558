/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListConfig,
  postDataListSetting,
} from "../services/retrieveData";

const { Content } = Layout;
const { Title } = Typography;

const MasterSpesification = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataForm, setdataForm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchataListConfig = useCallback(async () => {
    setIsLoading(true);
    const res = await getDataListConfig().catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200) {
      let tempFormValue = [];
      res.data.forEach((data) =>
        tempFormValue.push({
          name: [`${data.fieldName}`],
          value: data.defaultValue,
        })
      );

      setFields(tempFormValue);
      setdataForm(res.data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchataListConfig();
  }, [fetchataListConfig]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const res = await postDataListSetting(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <>
            <p>
              {err.response.data.errors.material_group &&
                err.response.data.errors.material_group[0]}
            </p>
            <p>
              {err.response.data.errors.material_group_description &&
                err.response.data.errors.material_group_description[0]}
            </p>
          </>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      modal.success({
        title: "Success Message!",
        content: <p>Configuration Successfully</p>,
      });
    }
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Setting</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          layout="vertical"
          form={form}
          name="setting"
          onFinish={onSubmit}
          fields={fields}
          onFieldsChange={(_, allFields) => setFields(allFields)}
          scrollToFirstError
        >
          <Row gutter={16}>
            {isLoading ? (
              <Spin tip="load form..." />
            ) : (
              dataForm.map((data, index) => (
                <Col lg={24} md={24}>
                  <Form.Item
                    name={data.fieldName}
                    label={data.fieldLabel}
                    rules={[
                      {
                        required: data.required === "required" ? true : false,
                        message: `Please input ${data.fieldLabel}`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder={data.fieldPlaceholder}
                      type={data.fieldText}
                    />
                  </Form.Item>
                </Col>
              ))
            )}
          </Row>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesification;
