/* eslint-disable react-hooks/exhaustive-deps */
import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, Layout, Modal, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import { getDataListApprovalCompanyAndPeriode } from "../services/retrieveData";

const { Content } = Layout;
const { Title } = Typography;

const MasterSpesification = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [
    dataListApprovalCompanyAndPeriode,
    setDataListApprovalCompanyAndPeriode,
  ] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataListApprovalCompanyAndPeriode = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListApprovalCompanyAndPeriode(params).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data.items.total,
        });
        setDataListApprovalCompanyAndPeriode(res.data.items.data);
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchDataListApprovalCompanyAndPeriode({
      pagination: pagination,
    });
  }, [fetchDataListApprovalCompanyAndPeriode]);

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Periode",
      dataIndex: "periode",
      key: "periode",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                props.history.push("/approval-vendor-alloc", record)
              }
              type="primary"
              icon={<UnorderedListOutlined />}
            >
              List
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListApprovalCompanyAndPeriode({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Approval Vendor Alloc</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Table
          pagination={pagination}
          onChange={handleTableChange}
          loading={isLoading}
          bordered
          size="small"
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable}
          dataSource={dataListApprovalCompanyAndPeriode}
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesification;
