import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListBudget,
  getDataListBudgetReportDownload,
  getDataListCompany,
  getDataListMasterMaterialGroup,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const BudgetReport = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [paginationBudgetReport, setPaginationBudgetReport] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListBudget, setDataListBudget] = useState([]);
  const [state, setState] = useState({
    type: "BUDGET",
    validFrom: "",
    validTo: "",
    company: "",
    materialGroup: "",
  });
  const { type, materialGroup, validFrom, validTo, company } = state;
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterSpec(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMasterMaterialGroup({});
    fetchDataListCompany({});
  }, [fetchDataListMasterMaterialGroup, fetchDataListCompany]);

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find(
      (data) => data !== "materialGroup" && state[data] === ""
    );

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        type: type,
        materialGroup: materialGroup,
        company: company,
        validFrom: moment(validFrom).format("MM/YYYY"),
        validTo: moment(validTo).format("MM/YYYY"),
      };
      const res = await getDataListBudgetReportDownload(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "SKU Desc.",
      dataIndex: "sku_desc",
      key: "sku_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Submit Date",
      dataIndex: "submit_date",
      key: "submit_date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Submit By",
      dataIndex: "submit_by",
      key: "submit_by",
      render: (text) => <p>{text}</p>,
    },
  ];

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const fetchDataListBudgetReport = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListBudget(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationBudgetReport({
        ...params.pagination,
        total: res.data.items.total,
      });
      setIsLoading(false);
      setDataListBudget(res?.data?.items?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDataTable = async (value) => {
    setFilter({
      type: "BUDGET",
      company: company,
      materialGroup: value.materialGroup || "",
      validFrom: moment(value.validFrom).format("MM/YYYY"),
      validTo: moment(value.validTo).format("MM/YYYY"),
    });

    fetchDataListBudgetReport({
      type: "BUDGET",
      company: company,
      materialGroup: value.materialGroup || "",
      validFrom: moment(value.validFrom).format("MM/YYYY"),
      validTo: moment(value.validTo).format("MM/YYYY"),
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const handleTableChangeBudgetReport = (pagination, filters, sorter) => {
    fetchDataListBudgetReport({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const resetTable = useCallback(() => {
    setDataListBudget([]);
  }, []);

  useEffect(() => {
    if (company || validFrom || validTo || materialGroup) {
      resetTable();
    }
  }, [resetTable, company, validFrom, validTo, materialGroup]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Budget Report</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("company", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="validFrom"
                  label="Valid From"
                  rules={[
                    {
                      required: true,
                      message: "Please input your valid from!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("validFrom", e)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="validTo"
                  label="Valid To"
                  rules={[
                    {
                      required: true,
                      message: "Please input your valid to!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("validTo", e)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    onChange={(e) => handleChange("materialGroup", e)}
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          onChange={handleTableChangeBudgetReport}
          pagination={paginationBudgetReport}
          bordered
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable}
          dataSource={dataListBudget}
          size="small"
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default BudgetReport;
