import {
  CloudUploadOutlined,
  DownloadOutlined,
  EyeOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListMasterMaterialGroup,
  getDataListSchedulePurchaseOrder,
  getDataListSchedulePurchaseOrderExport,
  getDataListSchedulePurchaseOrderVersion,
  getDataListSchedulePurchaseOrderVersionAdjustment,
  postFileSchedulePurchaseOrderAdjustment,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const SchedulePurchaseOrder = (props) => {
  const [form] = Form.useForm();
  const [dataImportFile, setDataImportFile] = useState(null);
  const [defaultKeyTab, setDefaultKeyTab] = useState("details");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataSubmitAllocation, setDataSubmitAllocation] = useState([]);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListPlanSceduleVersion, setDataListPlanSceduleVersion] = useState(
    []
  );
  const [dataListVAAjustmentVersion, setDataListVAAjustmentVersion] = useState(
    []
  );
  const [state, setState] = useState({
    companyCodeState: "",
    versionState: "",
    periodeState: "",
    adjustmentVersionState: "",
    typeState: "",
  });
  const {
    companyCodeState,
    periodeState,
    versionState,
    typeState,
    adjustmentVersionState,
  } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    typeAction: "",
    isShowModalAction: false,
  });
  const { isShowModalAction, typeAction } = isModalActionVisible;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const fetchDataListScheduleplanVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
      type: "sugestion",
    };
    const res = await getDataListSchedulePurchaseOrderVersion(data).catch(
      (err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListPlanSceduleVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState]);

  const fetchDataListVAAdjustmentVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
      version: versionState || "",
      type: typeState || "",
    };
    const res = await getDataListSchedulePurchaseOrderVersionAdjustment(
      data
    ).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVAAjustmentVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState, versionState, typeState]);

  const fetchDataListSchedulePurchaseOrder = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListSchedulePurchaseOrder(params).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data[params.key || "details"].total,
        });
        setDataSubmitAllocation(res.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterSpec(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMasterMaterialGroup({});
    fetchDataListCompany({});
  }, [fetchDataListMasterMaterialGroup, fetchDataListCompany]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  let dataQtyList = [];

  for (let i = 0; i <= dataSubmitAllocation["dates"]?.length; i++) {
    dataQtyList.push({
      title:
        dataSubmitAllocation &&
        dataSubmitAllocation["dates"] &&
        dataSubmitAllocation["dates"][i],
      dataIndex: "list_qty",
      key: "list_qty",
      render: (text) => <p>{text[i]}</p>,
    });
  }

  let dataQtyListSku = [];

  for (let i = 0; i <= dataSubmitAllocation["dates"]?.length; i++) {
    dataQtyListSku.push({
      title:
        dataSubmitAllocation &&
        dataSubmitAllocation["dates"] &&
        dataSubmitAllocation["dates"][i],
      dataIndex: "list_sku",
      key: "list_sku",
      render: (text) => <p>{text[i]}</p>,
    });
  }

  const columnsTableDetails = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "PR Number",
      dataIndex: "pr_no",
      key: "pr_no",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "PR Item",
      dataIndex: "pr_item",
      key: "pr_item",
      render: (text) => <p>{text}</p>,
      width: 80,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
      width: 130,
    },
    {
      title: "Material Code",
      dataIndex: "material_code",
      key: "material_code",
      render: (text) => <p>{text}</p>,
      width: 130,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Qty PR",
      dataIndex: "qty_pr",
      key: "qty_pr",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
      width: 120,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
      width: 120,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_desc",
      key: "vendor_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Qty PO",
      dataIndex: "qty_po",
      key: "qty_po",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text) => <p>{text}</p>,
    },
  ];

  const columnsTableVendors = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Code",
      dataIndex: "material_code",
      key: "material_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_desc",
      key: "vendor_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Forecast 2",
      dataIndex: "forecast_2",
      key: "forecast_2",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Forecast 3",
      dataIndex: "forecast_3",
      key: "forecast_3",
      render: (text) => <p>{text}</p>,
    },
  ];

  const columnsTableQtyVendors = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_desc",
      key: "vendor_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Code",
      dataIndex: "material_code",
      key: "material_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Total",
      dataIndex: "actual",
      key: "actual",
      render: (text) => <p>{text}</p>,
    },
    ...dataQtyList,
  ];

  const columnsTableSkuVendors = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_desc",
      key: "vendor_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Code",
      dataIndex: "material_code",
      key: "material_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
      render: (text) => <p>{text}</p>,
    },
    ...dataQtyListSku,
  ];

  const columnsTable = {
    details: columnsTableDetails,
    vendors: columnsTableVendors,
    qty_vendors: columnsTableQtyVendors,
    sku_vendors: columnsTableSkuVendors,
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const uploadData = async () => {
    let isFilled = Object.keys(state).find((data) => state[data] === "");
    if (!isFilled) {
      setIsLoading(true);
      let data = new FormData();

      data.append("excel", dataImportFile && dataImportFile[0].originFileObj);
      data.append("periode", moment(periodeState).format("MM.YYYY"));
      data.append("version", versionState);
      data.append("companyCode", companyCodeState);

      const res = await postFileSchedulePurchaseOrderAdjustment(data).catch(
        (err) => {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {
              form.resetFields();
            },
            onCancel: () => {
              form.resetFields();
            },
          });
          setIsLoading(false);
        }
      );

      if (res && res.status === 200) {
        if (res.data?.url) {
          window.open(res.data.url, "_blank");
          setTimeout(() => window.location.reload(), 1000);
        } else {
          modal.success({
            title: "Success Message!",
            content: <p>Success import data</p>,
            onOk: () => setTimeout(() => window.location.reload(), 1000),
          });
        }
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListSchedulePurchaseOrder({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const downloadTable = async () => {
    let isFilled = null;

    if (typeState === "sugestion") {
      isFilled = Object.keys(state).find(
        (data) => data !== "adjustmentVersionState" && state[data] === ""
      );
    } else {
      isFilled = Object.keys(state).find((data) => state[data] === "");
    }

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        type: typeState,
        version: versionState,
        adjustment_version: adjustmentVersionState || null,
        companyCode: companyCodeState,
        periode: moment(periodeState).format("MM.YYYY"),
      };
      const res = await getDataListSchedulePurchaseOrderExport(data).catch(
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const resetTable = useCallback(() => {
    setDataSubmitAllocation([]);
  }, []);

  useEffect(() => {
    if (
      typeState ||
      companyCodeState ||
      periodeState ||
      versionState ||
      adjustmentVersionState
    ) {
      resetTable();
    }
  }, [
    typeState,
    resetTable,
    companyCodeState,
    periodeState,
    versionState,
    adjustmentVersionState,
  ]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Schedule Purchase Order</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          scrollToFirstError
          onFinish={(e) => {
            setDefaultKeyTab("details");
            setFilter({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
              adjustment_version: e.adjustment_version || "",
              version: e.version || "",
              type: e.type || "",
              materialGroup: e.materialGroup || "",
            });

            fetchDataListSchedulePurchaseOrder({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
              adjustment_version: e.adjustment_version || "",
              version: e.version || "",
              type: e.type || "",
              materialGroup: e.materialGroup || "",
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
        >
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    style={{ width: 180 }}
                    onChange={(e) => handleChange("typeState", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="sugestion">sugestion</Option>
                    <Option value="adjustment">adjustment</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("companyCodeState", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("periodeState", e)}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[
                    {
                      required: true,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={!periodeState || !companyCodeState || !typeState}
                    showSearch
                    style={{ width: 300 }}
                    onChange={(e) => handleChange("versionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListScheduleplanVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListPlanSceduleVersion &&
                      dataListPlanSceduleVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col hidden={typeState === "sugestion"}>
                <Form.Item
                  name="adjustment_version"
                  label="Adjustment Version"
                  rules={[
                    {
                      required: typeState === "sugestion" ? false : true,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={
                      !periodeState || !companyCodeState || !versionState
                    }
                    showSearch
                    style={{ width: 180 }}
                    onChange={(e) => handleChange("adjustmentVersionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVAAdjustmentVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVAAjustmentVersion &&
                      dataListVAAjustmentVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col hidden>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Space>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
              {typeState === "adjustment" && (
                <Button
                  hidden
                  onClick={() =>
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      typeAction: "UPLOAD",
                      isShowModalAction: true,
                    })
                  }
                  style={{
                    backgroundColor: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  type="primary"
                  icon={<CloudUploadOutlined />}
                >
                  Upload
                </Button>
              )}
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Space>
          </Row>
        </Form>
        <Tabs
          onChange={(e) => {
            setDefaultKeyTab(e);
            setFilter({
              ...filter,
              key: e,
            });
            fetchDataListSchedulePurchaseOrder({
              ...filter,
              key: e,
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
          activeKey={defaultKeyTab}
          defaultActiveKey={defaultKeyTab}
        >
          {Object.keys(dataSubmitAllocation).map((key, index) => {
            if (key === "dates") {
              // eslint-disable-next-line
              return;
            }
            return (
              <TabPane
                tab={
                  key === "details"
                    ? "Detail"
                    : key === "vendors"
                    ? "Rekap Vendor"
                    : key === "qty_vendors"
                    ? "Rekap Vendor (Qty)"
                    : "Rekap Vendor (Jml SKU)"
                }
                key={key}
              >
                <Table
                  pagination={pagination}
                  onChange={handleTableChange}
                  loading={isLoading}
                  bordered
                  rowClassName={(record, index) => {
                    if (index % 2 === 1) {
                      return "color-gray-2";
                    } else {
                      return "color-gray-1";
                    }
                  }}
                  size="small"
                  columns={
                    key === "vendors"
                      ? columnsTable["vendors"].filter(
                          (filtering) => filtering.dataIndex !== "key"
                        )
                      : key === "details"
                      ? columnsTable["details"].filter(
                          (filtering) => filtering.dataIndex !== "id"
                        )
                      : key === "qty_vendors"
                      ? columnsTable["qty_vendors"].filter(
                          (filtering) =>
                            filtering.dataIndex !== "material_code" &&
                            filtering.dataIndex !== "key" &&
                            filtering.dataIndex !== "material_description"
                        )
                      : key === "sku_vendors"
                      ? columnsTable["sku_vendors"].filter(
                          (filtering) =>
                            filtering.dataIndex !== "key" &&
                            filtering.dataIndex !== "actual" &&
                            filtering.dataIndex !== "material_code" &&
                            filtering.dataIndex !== "material_description"
                        )
                      : columnsTable[key]
                  }
                  dataSource={
                    dataSubmitAllocation && dataSubmitAllocation[key]?.data
                  }
                  scroll={
                    key === "vendors" || key === "details"
                      ? undefined
                      : { x: 3200 }
                  }
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          if (typeAction === "UPLOAD" && dataImportFile) {
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            form.resetFields();
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        footer={null}
      >
        {typeAction === "UPLOAD" ? (
          <>
            <Form.Item>
              <Form.Item
                name="uploadFileMdDesign"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Button
              block
              onClick={uploadData}
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                marginBottom: MarginEnum["2x"],
              }}
              type="primary"
              loading={isLoading}
              disabled={dataImportFile ? false : true}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
          </>
        ) : null}
      </Modal>

      {contextHolder}
    </Content>
  );
};

export default SchedulePurchaseOrder;
