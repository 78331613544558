import {
  CloudUploadOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListAllocationPlanOrder,
  getDataListAllocationPlanOrderAdjustmentVersion,
  getDataListAllocationPlanOrderExport,
  getDataListAllocationPlanOrderVersion,
  getDataListCompany,
  postFileAllocationPlanOrderAdjustment,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const AllocationPlanOrder = (props) => {
  const [form] = Form.useForm();
  const [filter, setFilter] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const [dataImportFile, setDataImportFile] = useState(null);
  const [dataPlanAllocationOrder, setDataPlanAllocationOrder] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [openFilter, setIsOpenFilter] = useState(true);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListVASuggestionVersion, setDataListVASuggestionVersion] =
    useState([]);
  const [dataListVAAjustmentVersion, setDataListVAAjustmentVersion] = useState(
    []
  );
  const [state, setState] = useState({
    companyCodeState: "",
    periodeState: "",
    typeState: "",
    versionState: "",
    versionAdjustmentState: "",
  });
  const {
    companyCodeState,
    periodeState,
    typeState,
    versionState,
    versionAdjustmentState,
  } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    typeAction: "",
    isShowModalAction: false,
  });
  const { isShowModalAction, typeAction } = isModalActionVisible;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const uploadData = async () => {
    let isFilled = Object.keys(state).find((data) => state[data] === "");
    if (!isFilled) {
      setIsLoading(true);
      let data = new FormData();

      data.append("excel", dataImportFile && dataImportFile[0].originFileObj);
      data.append("periode", moment(periodeState).format("MM.YYYY"));
      data.append("version", versionState);
      data.append("companyCode", companyCodeState);

      const res = await postFileAllocationPlanOrderAdjustment(data).catch(
        (err) => {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {
              form.resetFields();
            },
            onCancel: () => {
              form.resetFields();
            },
          });
          setIsLoading(false);
        }
      );

      if (res && res.status === 200) {
        if (res.data?.url) {
          window.open(res.data.url, "_blank");
          setTimeout(() => window.location.reload(), 1000);
        } else {
          modal.success({
            title: "Success Message!",
            content: <p>Success import data</p>,
            onOk: () => setTimeout(() => window.location.reload(), 1000),
          });
        }
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const fetchDataListVASuggestionVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
    };

    const res = await getDataListAllocationPlanOrderVersion(data).catch(
      (err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVASuggestionVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState, typeState]);

  const fetchDataListVAAdjustmentVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
      version: versionState || "",
    };
    const res = await getDataListAllocationPlanOrderAdjustmentVersion(
      data
    ).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVAAjustmentVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState, versionState]);

  const fetchDataListPlanAllocationOrder = useCallback(
    async (params) => {
      setIsLoading(true);
      const res = await getDataListAllocationPlanOrder(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data.items.total,
        });

        setDataPlanAllocationOrder(res.data.items.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCompany({});
  }, [fetchDataListCompany]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const columnsTable = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material",
      dataIndex: "material_code",
      key: "material_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_desc",
      key: "vendor_desc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Allocation %",
      dataIndex: "allocation",
      key: "allocation",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Total PR Qty",
      dataIndex: "total_qty",
      key: "total_qty",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Allocation Qty",
      dataIndex: "allocation_qty",
      key: "allocation_qty",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      key: "moq",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Adj. Allocation %",
      dataIndex: "adjustment_allocation",
      key: "adjustment_allocation",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Total PR",
      children: [
        {
          title: "K103",
          dataIndex: "total_pr_qty",
          key: "total_pr_qty",
          render: (text) => <p>{text.K103}</p>,
        },
        {
          title: "K104",
          dataIndex: "total_pr_qty",
          key: "total_pr_qty",
          render: (text) => <p>{text.K104}</p>,
        },
        {
          title: "K118",
          dataIndex: "total_pr_qty",
          key: "total_pr_qty",
          render: (text) => <p>{text.K118}</p>,
        },
      ],
    },
    {
      title: "MOQ Kirim",
      dataIndex: "moq_kirim",
      key: "moq_kirim",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Sugestion",
      children: [
        {
          title: "K103",
          dataIndex: "sugestion_qty",
          key: "sugestion_qty",
          render: (text) => <p>{text.K103}</p>,
        },
        {
          title: "K104",
          dataIndex: "sugestion_qty",
          key: "sugestion_qty",
          render: (text) => <p>{text.K104}</p>,
        },
        {
          title: "K118",
          dataIndex: "sugestion_qty",
          key: "sugestion_qty",
          render: (text) => <p>{text.K118}</p>,
        },
      ],
    },
    {
      title: "Adjustment",
      children: [
        {
          title: "K103",
          dataIndex: "adjustment_qty",
          key: "adjustment_qty",
          render: (text) => <p>{text.K103}</p>,
        },
        {
          title: "K104",
          dataIndex: "adjustment_qty",
          key: "adjustment_qty",
          render: (text) => <p>{text.K104}</p>,
        },
        {
          title: "K118",
          dataIndex: "adjustment_qty",
          key: "adjustment_qty",
          render: (text) => <p>{text.K118}</p>,
        },
      ],
    },
  ];

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListPlanAllocationOrder({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const downloadTable = async () => {
    let isFilled = null;

    if (typeState === "sugestion") {
      isFilled = Object.keys(state).find(
        (data) => data !== "versionAdjustmentState" && state[data] === ""
      );
    } else {
      isFilled = Object.keys(state).find((data) => state[data] === "");
    }

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        type: typeState,
        version: versionState,
        adjustment_version: versionAdjustmentState || null,
        companyCode: companyCodeState,
        periode: moment(periodeState).format("MM.YYYY"),
      };
      const res = await getDataListAllocationPlanOrderExport(data).catch(
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const resetTable = useCallback(() => {
    setDataPlanAllocationOrder([]);
  }, []);

  useEffect(() => {
    if (
      typeState ||
      companyCodeState ||
      periodeState ||
      versionState ||
      versionAdjustmentState
    ) {
      resetTable();
    }
  }, [
    typeState,
    resetTable,
    companyCodeState,
    periodeState,
    versionState,
    versionAdjustmentState,
  ]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Allocation Plan Order</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          scrollToFirstError
          onFinish={(e) => {
            setFilter({
              periode: moment(e.periode).format("MM.YYYY"),
              companyCode: e.companyCode,
              adjustment_version: e.adjustment_version || "",
              version: e.version,
              type: e.type,
            });
            fetchDataListPlanAllocationOrder({
              periode: moment(e.periode).format("MM.YYYY"),
              companyCode: e.companyCode,
              adjustment_version: e.adjustment_version || "",
              version: e.version,
              type: e.type,
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
        >
          {openFilter && (
            <>
              <Row style={{ marginBottom: MarginEnum["2x"] }}>
                <Space size="large">
                  <Col>
                    <Form.Item
                      name="type"
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Please input your type!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        style={{ width: 180 }}
                        onChange={(e) => handleChange("typeState", e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="sugestion">sugestion</Option>
                        <Option value="adjustment">adjustment</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="companyCode"
                      label="Company"
                      rules={[
                        {
                          required: true,
                          message: "Please input your company!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        loading={isLoading}
                        onSearch={onSearchCompany}
                        onFocus={() => fetchDataListCompany({})}
                        placeholder="Input min. 3 char"
                        onChange={(e) => handleChange("companyCodeState", e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataListCompany &&
                          dataListCompany.map((data, index) => {
                            const { company_code } = data;
                            return (
                              <Option key={index} value={company_code}>
                                {company_code}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="periode"
                      label="Periode"
                      rules={[
                        {
                          required: true,
                          message: "Please input your periode!",
                        },
                      ]}
                      hasFeedback
                    >
                      <DatePicker
                        picker="month"
                        inputReadOnly={true}
                        onChange={(e) => handleChange("periodeState", e)}
                      />
                    </Form.Item>
                  </Col>
                </Space>
              </Row>
              <Row style={{ marginBottom: MarginEnum["2x"] }}>
                <Space size="large">
                  <Col>
                    <Form.Item
                      name="version"
                      label="Version"
                      rules={[
                        {
                          required: true,
                          message: "Please input version!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        disabled={
                          !periodeState || !companyCodeState || !typeState
                        }
                        showSearch
                        style={{ width: 300 }}
                        onChange={(e) => handleChange("versionState", e)}
                        loading={isLoading}
                        onFocus={() => fetchDataListVASuggestionVersion()}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataListVASuggestionVersion &&
                          dataListVASuggestionVersion.map((data, index) => {
                            const { labels, id } = data;
                            return (
                              <Option key={index} value={id}>
                                {labels}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col hidden={typeState === "sugestion"}>
                    <Form.Item
                      name="adjustment_version"
                      label="Adjustment Version"
                      rules={[
                        {
                          required: typeState === "sugestion" ? false : true,
                          message: "Please input version!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        disabled={
                          !periodeState || !companyCodeState || !versionState
                        }
                        showSearch
                        style={{ width: 180 }}
                        onChange={(e) =>
                          handleChange("versionAdjustmentState", e)
                        }
                        loading={isLoading}
                        onFocus={() => fetchDataListVAAdjustmentVersion()}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataListVAAjustmentVersion &&
                          dataListVAAjustmentVersion.map((data, index) => {
                            const { labels, id } = data;
                            return (
                              <Option key={index} value={id}>
                                {labels}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Space>
              </Row>
            </>
          )}
          <Row style={{ marginBottom: MarginEnum["3x"] }} justify="end">
            <Space>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
              {typeState === "adjustment" && (
                <Button
                  hidden
                  onClick={() =>
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      typeAction: "UPLOAD",
                      isShowModalAction: true,
                    })
                  }
                  style={{
                    backgroundColor: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  type="primary"
                  icon={<CloudUploadOutlined />}
                >
                  Upload
                </Button>
              )}
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
              <Button
                onClick={() => setIsOpenFilter(!openFilter)}
                style={{
                  color: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                loading={isLoading}
                icon={<FilterOutlined />}
              >
                {openFilter ? "Close Filter" : "Open Filter"}
              </Button>
            </Space>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          bordered
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
          columns={columnsTable.filter(
            (filtering) => filtering.dataIndex !== "id"
          )}
          dataSource={dataPlanAllocationOrder}
          scroll={{ x: 2500 }}
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          if (typeAction === "UPLOAD" && dataImportFile) {
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            form.resetFields();
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        footer={null}
      >
        {typeAction === "UPLOAD" ? (
          <>
            <Form.Item>
              <Form.Item
                name="uploadFileMdDesign"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Button
              block
              onClick={uploadData}
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                marginBottom: MarginEnum["2x"],
              }}
              type="primary"
              loading={isLoading}
              disabled={dataImportFile ? false : true}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
          </>
        ) : null}
      </Modal>

      {contextHolder}
    </Content>
  );
};

export default AllocationPlanOrder;
