import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import { ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const ConfigCompany = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const onFinish = async (values) => {
    setIsLoading(true);
    modal.confirm("add");
    setIsLoading(false);
  };

  const onEditSupplier = async (values) => {
    setIsLoading(true);
    modal.confirm("udpate");
    setIsLoading(false);
  };

  const onDeleteSupplier = async (values) => {
    window.alert("Success delete supplier");
    setIsModalActionVisible({
      ...isModalActionVisible,
      isShowModalAction: false,
    });
    window.location.reload();
  };

  const dataTable = [
    {
      key: 1,
      company: "KSNI",
      plant: "K100",
      name: "HO KSN",
      address: "Jl. Soekarno Hatta",
      city: "Bandung",
    },
  ];

  const columnsTable = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "VIEW",
                })
              }
              type="primary"
              icon={<EyeOutlined />}
            >
              View
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                })
              }
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Plant</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row
          style={{
            marginBottom: 12,
          }}
        >
          <Button
            onClick={() =>
              setIsModalActionVisible({
                ...isModalActionVisible,
                typeAction: "ADD",
                isShowModalAction: true,
              })
            }
            style={{
              backgroundColor: ColorPrimaryEnum.greenTosca,
              border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
            }}
            type="primary"
            icon={<FileAddOutlined />}
          >
            Add
          </Button>
        </Row>
        <Table
          loading={isLoading}
          bordered
          rowClassName={(record, index) => {
            console.log("@record", record);
            console.log("@index", index);
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable}
          dataSource={dataTable}
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onOk={() => {
          if (typeAction === "DELETE") {
            onDeleteSupplier(dataRow.guid);
          } else {
            form.resetFields();
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (
              data === "key" ||
              data === "updated_at" ||
              data === "created_at"
            ) {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="addSupplier"
            onFinish={onEditSupplier}
            fields={[
              {
                name: ["materialGroup"],
                value: dataRow.materialGroup,
              },
              {
                name: ["materialGroupDesc"],
                value: dataRow.materialGroupDesc,
              },
            ]}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: true,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroupDesc"
                  label="Material Group Desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input material group desc. !",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              {contextHolder}
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="addSupplier"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: true,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroupDesc"
                  label="Material Group Desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input material group desc. !",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
              {contextHolder}
            </div>
          </Form>
        ) : (
          <p>Apakah anda ingin menghapus data ini ?</p>
        )}
      </Modal>
    </Content>
  );
};

export default ConfigCompany;
