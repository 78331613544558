/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListMasterSpecItem,
  getDataListCharacteritic,
  getDataListMasterSpecItem,
  postDataListMasterSpecItem,
  putDataListMasterSpecItem,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const groupListData = {
  Warna: "Color",
  Bahan: "Material",
  Flute: "Flute",
};

const MasterSpesificationItem = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [paginationMasterSpec, setPaginationMasterSpec] = useState({
    current: 1,
    pageSize: 10,
  });
  const [form] = Form.useForm();
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataCharacter, setDataCharacter] = useState([]);
  const [fields, setFields] = useState([]);
  const [dataGroup, setDataGroup] = useState(null);
  const [characteristicDesc, setCharacteristicDesc] = useState("");
  const [characteristic, setCharacteristic] = useState(null);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const fetchDataListMasterSpec = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListMasterSpecItem(params).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationMasterSpec({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListMasterSpec(res.data.items.data);
      setDataGroup(res.data.groups);
      setIsLoading(false);
    }
  }, []);

  const fetchDataLisCharacteritic = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    const data = {
      material_group_id: props.location.state.material_group,
      search: keyword || "",
    };

    const res = await getDataListCharacteritic(data).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setDataCharacter(res.data.items);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataLisCharacteritic({});
    fetchDataListMasterSpec({
      pagination: paginationMasterSpec,
      material_group: props.location.state.material_group,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListMasterSpec, fetchDataLisCharacteritic]);

  const onFinish = async (values) => {
    setIsLoading(true);
    let data = {
      characteristic_id: values.characteristic_id,
      specification_id: props.location.state.id,
      group_id: parseInt(values.group_id),
      characteristic_description: values.characteristic_description,
      range: values.range,
    };

    const res = await postDataListMasterSpecItem(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      characteristic_id: values.characteristic_id,
      specification_id: props.location.state.id,
      group_id: parseInt(values.group_id),
      characteristic_description: values.characteristic_description,
      range: values.range,
    };

    const res = await putDataListMasterSpecItem(dataRow.id, data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterSpecItem(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  let columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Characteristic",
      dataIndex: "characteristic",
      key: "characteristic",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic ID",
      dataIndex: "characteristic_id",
      key: "characteristic_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic Desc",
      dataIndex: "characteristic_description",
      key: "characteristic_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Range",
      dataIndex: "range",
      key: "range",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Group ID",
      dataIndex: "group_id",
      key: "group_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["characteristic_id"],
                    value: characteristic || record.characteristic_id,
                  },
                  {
                    name: ["group_id"],
                    value: record.group_id,
                  },
                  {
                    name: ["characteristic_description"],
                    value:
                      characteristicDesc || record.characteristic_description,
                  },
                  {
                    name: ["range"],
                    value: record.range,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (data) => {
    if (data.length > 3) {
      fetchDataListMasterSpec({
        search: data,
        material_group: props.location.state.material_group,
      });
    } else {
      fetchDataListMasterSpec({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        material_group: props.location.state.material_group,
      });
    }
  };

  const handleTableChangeMasterSpec = (pagination, filters, sorter) => {
    fetchDataListMasterSpec({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  const onChangeCharacteristic = (data) => {
    let tempData = dataCharacter.find((finding) => finding.id === data);
    setCharacteristicDesc(tempData.name);
    setCharacteristic(data);
  };

  const onSearchCharacteristic = (data) => {
    if (data.length === 0) {
      fetchDataLisCharacteritic({});
    } else if (data.length > 3) {
      fetchDataLisCharacteritic({ keyword: data });
    }
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Master Specification</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row justify="space-between">
          <Title level={4}>
            {props?.location?.state?.material_group} -{" "}
            {props?.location?.state?.material_group_description}
          </Title>
          <Button
            onClick={() => props.history.push("/master-specification")}
            type="default"
            icon={<ArrowLeftOutlined />}
          >
            Back to list
          </Button>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Specification" key="1">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearch}
                allowClear
                placeholder="Input min. 4 char"
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              pagination={paginationMasterSpec}
              onChange={handleTableChangeMasterSpec}
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTable.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "created_at" &&
                  col.dataIndex !== "updated_at" &&
                  col.dataIndex !== "characteristic_id" &&
                  col.dataIndex !== "group_id" &&
                  col.dataIndex !== "state" &&
                  col.dataIndex !== "type" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "specification_id"
              )}
              dataSource={dataListMasterSpec}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fields}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_id"
                  label="Characteristic"
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    onFocus={() => fetchDataLisCharacteritic({})}
                    onSearch={onSearchCharacteristic}
                    optionFilterProp="children"
                    loading={isLoading}
                    onChange={onChangeCharacteristic}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="select your characteristic"
                  >
                    {dataCharacter &&
                      dataCharacter.map((data, index) => {
                        const { id, char_code_variable, name } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${char_code_variable} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="group_id"
                  label="Group"
                  help="1 = Color, 2 = Material, 3 = Flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select loading={isLoading} placeholder="select your group">
                    {dataGroup &&
                      Object.keys(dataGroup).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {groupListData[dataGroup[data]]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_description"
                  label="Characteristic desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic desc.!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="range"
                  label="Range"
                  rules={[
                    {
                      required: true,
                      message: "Please input range!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            fields={[
              {
                name: ["characteristic_description"],
                value: characteristicDesc,
              },
            ]}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_id"
                  label="Characteristic"
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    loading={isLoading}
                    onFocus={() => fetchDataLisCharacteritic({})}
                    onSearch={onSearchCharacteristic}
                    onChange={onChangeCharacteristic}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="select your characteristic"
                  >
                    {dataCharacter &&
                      dataCharacter.map((data, index) => {
                        const { id, char_code_variable, name } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${char_code_variable} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="group_id"
                  label="Group"
                  rules={[
                    {
                      required: true,
                      message: "Please input group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select loading={isLoading} placeholder="select your group">
                    {dataGroup &&
                      Object.keys(dataGroup).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {groupListData[dataGroup[data]]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_description"
                  label="Characteristic desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic desc.!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="range"
                  label="Range"
                  rules={[
                    {
                      required: true,
                      message: "Please input range!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>
            Do you want to delete this data <b>{dataRow?.characteristic}</b> ?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesificationItem;
