/* eslint-disable react-hooks/exhaustive-deps */
import {
  FileAddOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Layout,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataTemplateRfqDownload,
  getDataListMasterSpec,
  postDataListRfqUploadTemplate,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const UploadRFQ = (props) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [paginationMasterSpec, setPaginationMasterSpec] = useState({
    current: 1,
    pageSize: 15,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [materialGroupId, setMaterialGroupId] = useState("");
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [type, setType] = useState("SKU");
  const [dataImportFile, setDataImportFile] = useState(null);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const fetchDataListMasterSpec = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListMasterSpec(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationMasterSpec({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListMasterSpec(res.data.items.data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataListMasterSpec({ pagination: paginationMasterSpec });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListMasterSpec]);

  const onChangeMaterial = (data) => {
    let tempData = dataListMasterSpec.find(
      (finding) => finding.material_group === data
    );
    setMaterialGroupId(tempData.material_group);
  };

  const downloadTemplateExcelRfq = async () => {
    setIsLoading(true);
    let data = {
      type: type,
      material_group: materialGroupId,
    };
    const res = await getDataTemplateRfqDownload(data).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200) {
      setIsLoading(false);
      window.open(res.data.url, "_blank");
    }
  };

  const uploadTemplateRfq = async (value) => {
    setIsLoading(true);
    let data = new FormData();

    if (value.uploadFileRFQ) {
      delete value.uploadFileRFQ;
    }

    data.append("excel", dataImportFile && dataImportFile[0].originFileObj);
    data.append("type", value.type ?? "SPEC");
    data.append("material_group", value.material_group);

    const res = await postDataListRfqUploadTemplate(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      if (res.data?.url) {
        window.open(res.data.url, "_blank");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        modal.success({
          title: "Success Message!",
          content: <p>Success import data</p>,
          onOk: () => setTimeout(() => window.location.reload(), 1000),
        });
      }
    }
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>RFQ Upload</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={uploadTemplateRfq} form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input type!",
              },
            ]}
            name="type"
            label="Type:"
          >
            <Radio.Group onChange={(e) => setType(e.target.value)}>
              <Radio value="SKU">SKU</Radio>
              <Radio value="Specification">Specification</Radio>
            </Radio.Group>
          </Form.Item>

          {type === "Specification" && (
            <Form.Item
              name="material_group"
              label="Material Group"
              rules={[
                {
                  required: true,
                  message: "Please input material group!",
                },
              ]}
              hasFeedback
            >
              <Select
                showSearch
                loading={isLoading}
                onFocus={() =>
                  fetchDataListMasterSpec({ pagination: paginationMasterSpec })
                }
                placeholder="Input min. 4 char"
                onChange={onChangeMaterial}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dataListMasterSpec &&
                  dataListMasterSpec.map((data, index) => {
                    const { material_group_description, material_group } = data;
                    return (
                      <Option key={index} value={material_group}>
                        {`${material_group} - ${material_group_description}`}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Form.Item
              name="uploadFileRFQ"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger name="files">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              disabled={dataImportFile ? false : true}
              loading={isLoading}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
          </Form.Item>
        </Form>

        <Button
          block
          onClick={downloadTemplateExcelRfq}
          style={{
            backgroundColor: ColorBaseEnum.white,
            color: ColorPrimaryEnum.greenTosca,
            border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
          }}
          loading={isLoading}
          icon={<FileAddOutlined />}
        >
          Download Template
        </Button>
      </div>
      {contextHolder}
    </Content>
  );
};

export default UploadRFQ;
