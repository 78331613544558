import {
  ArrowLeftOutlined,
  EnterOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { currencyFormatter } from "../global/currencyFormatter";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListApprovalCompanyAndPeriodeItem,
  putDataSubmitAllocation,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const { Content } = Layout;
const { TabPane } = Tabs;
const { Title } = Typography;

const ApprovalVendorAlloc = (props) => {
  const [form] = Form.useForm();
  const [defaultKeyTab, setDefaultKeyTab] = useState("rekap");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const [dataSubmitAllocation, setDataSubmitAllocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    typeAction: "",
    isShowModalAction: false,
  });
  const { isShowModalAction, typeAction } = isModalActionVisible;

  const fetchDataListApprovalItem = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListApprovalCompanyAndPeriodeItem(params).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data[params.key || "rekap"].total,
        });
        setDataSubmitAllocation(res.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchDataListApprovalItem({
      pagination: {
        current: 1,
        pageSize: 10,
      },
      id: props.location.state.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListApprovalItem]);

  const columnsTableRekap = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: `PR ${dataSubmitAllocation && dataSubmitAllocation.p1}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "pr1_qty",
              key: "pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "pr1_amount",
              key: "pr1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "pr1_contribution",
              key: "pr1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "pr1_sku",
              key: "pr1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_pr1_qty",
              key: "adj_pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_pr1_amount",
              key: "adj_pr1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_pr1_contribution",
              key: "adj_pr1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_pr1_sku",
              key: "adj_pr1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m1}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp1_qty",
              key: "mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp1_amount",
              key: "mrp1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp1_contribution",
              key: "mrp1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp1_sku",
              key: "mrp1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp1_qty",
              key: "adj_mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp1_amount",
              key: "adj_mrp1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp1_contribution",
              key: "adj_mrp1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp1_sku",
              key: "adj_mrp1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m2}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp2_qty",
              key: "mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp2_amount",
              key: "mrp2_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp2_contribution",
              key: "mrp2_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp2_sku",
              key: "mrp2_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp2_qty",
              key: "adj_mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp2_amount",
              key: "adj_mrp2_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp2_contribution",
              key: "adj_mrp2_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp2_sku",
              key: "adj_mrp2_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m3}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp3_qty",
              key: "mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp3_amount",
              key: "mrp3_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp3_contribution",
              key: "mrp3_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp3_sku",
              key: "mrp3_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp3_qty",
              key: "adj_mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp3_amount",
              key: "adj_mrp3_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp3_contribution",
              key: "adj_mrp3_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp3_sku",
              key: "adj_mrp3_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
  ];

  const columnsTableBudget = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: `PR ${dataSubmitAllocation && dataSubmitAllocation.p1}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "pr1_sugestion",
          key: "pr1_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "pr1_adjustment",
          key: "pr1_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "pr1_budget",
          key: "pr1_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "pr1_gain_loss",
          key: "pr1_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "pr1_sku",
          key: "pr1_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "pr1_os_budget",
          key: "pr1_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "pr1_os_budget_sku",
          key: "pr1_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "pr1_os_allocation",
          key: "pr1_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "pr1_os_allocation_sku",
          key: "pr1_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 1 ${dataSubmitAllocation && dataSubmitAllocation.m1}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp1_sugestion",
          key: "mrp1_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp1_adjustment",
          key: "mrp1_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp1_budget",
          key: "mrp1_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp1_gain_loss",
          key: "mrp1_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp1_sku",
          key: "mrp1_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp1_os_budget",
          key: "mrp1_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp1_os_budget_sku",
          key: "mrp1_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp1_os_allocation",
          key: "mrp1_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp1_os_allocation_sku",
          key: "mrp1_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 2 ${dataSubmitAllocation && dataSubmitAllocation.m2}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp2_sugestion",
          key: "mrp2_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp2_adjustment",
          key: "mrp2_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp2_budget",
          key: "mrp2_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp2_gain_loss",
          key: "mrp2_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp2_sku",
          key: "mrp2_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp2_os_budget",
          key: "mrp2_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp2_os_budget_sku",
          key: "mrp2_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp2_os_allocation",
          key: "mrp2_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp2_os_allocation_sku",
          key: "mrp2_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 3 ${dataSubmitAllocation && dataSubmitAllocation.m3}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp3_sugestion",
          key: "mrp3_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp3_adjustment",
          key: "mrp3_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp3_budget",
          key: "mrp3_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp3_gain_loss",
          key: "mrp3_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp3_sku",
          key: "mrp3_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp3_os_budget",
          key: "mrp3_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp3_os_budget_sku",
          key: "mrp3_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp3_os_allocation",
          key: "mrp3_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp3_os_allocation_sku",
          key: "mrp3_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
  ];

  const columnsTable = {
    rekap: columnsTableRekap,
    budget: columnsTableBudget,
  };

  const onApprove = async (values) => {
    const data = {
      message: values.message,
      state: "approved",
    };

    const res = await putDataSubmitAllocation(
      dataSubmitAllocation.submitted?.id,
      data
    ).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        setIsModalActionVisible({
          ...isModalActionVisible,
          isShowModalAction: false,
        });
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res) {
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Success Approve data 🥳🥳🥳</p>,
      });
      setIsLoading(false);
      props.history.push("/approval-vendor-alloc-home");
    }
  };

  const onReject = async (values) => {
    const data = {
      message: values.message,
      state: "rejected",
    };

    const res = await putDataSubmitAllocation(
      dataSubmitAllocation.submitted?.id,
      data
    ).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        setIsModalActionVisible({
          ...isModalActionVisible,
          isShowModalAction: false,
        });
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res) {
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Success Approve data 🥳🥳🥳</p>,
      });
      setIsLoading(false);
      props.history.push("/approval-vendor-alloc-home");
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListApprovalItem({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      id: props.location.state.id,
      ...filters,
      ...filter,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Approval Vendor Alloc</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Title level={4}>
            {props.location?.state?.company_code} -{" "}
            {moment(props.location?.state?.periode).format("MMM-YYYY")}
          </Title>
          <Button
            onClick={() => props.history.push("/approval-vendor-alloc-home")}
            type="default"
            icon={<ArrowLeftOutlined />}
          >
            Back to list
          </Button>
        </Row>
        <Row justify="end">
          <Space>
            <Button
              hidden={
                dataSubmitAllocation.length === 0
                  ? true
                  : dataSubmitAllocation
                  ? false
                  : true
              }
              onClick={() =>
                setIsModalActionVisible({
                  typeAction: "APPROVE",
                  isShowModalAction: true,
                })
              }
              loading={isLoading}
              type="primary"
              style={{
                backgroundColor: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              icon={<EnterOutlined />}
            >
              Approve
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  typeAction: "REJECT",
                  isShowModalAction: true,
                })
              }
              hidden={
                dataSubmitAllocation.length === 0
                  ? true
                  : dataSubmitAllocation
                  ? false
                  : true
              }
              loading={isLoading}
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              icon={<EyeOutlined />}
            >
              Reject
            </Button>
          </Space>
        </Row>
        <Spin spinning={isLoading} tip="Loading data...">
          <Tabs
            onChange={(e) => {
              setDefaultKeyTab(e);
              setFilter({
                ...filter,
                key: e,
              });
              fetchDataListApprovalItem({
                ...filter,
                key: e,
                pagination: {
                  current: 1,
                  pageSize: 10,
                },
                id: props.location.state.id,
              });
            }}
            activeKey={defaultKeyTab}
            defaultActiveKey={defaultKeyTab}
          >
            {Object.keys(dataSubmitAllocation).map((key, index) => {
              if (
                key === "submitted" ||
                key === "m1" ||
                key === "m2" ||
                key === "m3" ||
                key === "p1"
              ) {
                // eslint-disable-next-line
                return;
              }
              return (
                <TabPane
                  tab={`${key.charAt(0).toUpperCase() + key.slice(1)} Data`}
                  key={key}
                >
                  <Table
                    pagination={pagination}
                    onChange={handleTableChange}
                    bordered
                    rowClassName={(record, index) => {
                      if (index % 2 === 1) {
                        return "color-gray-2";
                      } else {
                        return "color-gray-1";
                      }
                    }}
                    size="small"
                    columns={columnsTable[key]}
                    dataSource={
                      dataSubmitAllocation && dataSubmitAllocation[key]?.data
                    }
                    scroll={key === "rekap" ? { x: 2800 } : { x: 3800 }}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </Spin>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["APPROVE", "REJECT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={null}
      >
        {typeAction === "APPROVE" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="approve"
            onFinish={onApprove}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                  hasFeedback
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <Form
            {...formItemLayout}
            form={form}
            name="reject"
            onFinish={onReject}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message",
                    },
                  ]}
                  hasFeedback
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>

      {contextHolder}
    </Content>
  );
};

export default ApprovalVendorAlloc;
