/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
  InputNumber,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListFluteVendorItem,
  getDataListFluteVendorItem,
  postDataListFluteVendorItem,
  putDataListFluteVendorItem,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterSpesificationFluteVendorItem = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [paginationFluteVendorItem, setPaginationFluteVendorItem] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataListFluteVendorItem, setDataListFluteVendorItem] = useState([]);
  const [fields, setFields] = useState([]);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const fetchDataListFluteVendorItem = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListFluteVendorItem(params).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationFluteVendorItem({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListFluteVendorItem(res.data.items.data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataListFluteVendorItem({
      flute_vendor: props.location.state.id,
      pagination: paginationFluteVendorItem,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListFluteVendorItem]);

  const onFinish = async (values) => {
    setIsLoading(true);
    let data = {
      flute: values.flute,
      flute_vendor: props.location.state.id,
      p_tol: values.p_tol,
      l_tol: values.l_tol,
      take_up: values.take_up,
      kuping: values.kuping,
    };

    const res = await postDataListFluteVendorItem(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListFluteVendorItem({
        flute_vendor: props.location.state.id,
        pagination: paginationFluteVendorItem,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute Vendor Item Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      flute: values.flute,
      flute_vendor: props.location.state.id,
      p_tol: values.p_tol,
      l_tol: values.l_tol,
      take_up: values.take_up,
      kuping: values.kuping,
    };

    const res = await putDataListFluteVendorItem(dataRow.id, data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListFluteVendorItem({
        flute_vendor: props.location.state.id,
        pagination: paginationFluteVendorItem,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute Vendor Item Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListFluteVendorItem(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteVendorItem({
        flute_vendor: props.location.state.id,
        pagination: paginationFluteVendorItem,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute Vendor Item Deleted Successfully</p>,
      });
    }
  };

  let columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Flute Vendor",
      dataIndex: "flute_vendor",
      key: "flute_vendor",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Flute",
      dataIndex: "flute",
      key: "flute",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "P Tol (mm)",
      dataIndex: "p_tol",
      key: "p_tol",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "L Tol (mm)",
      dataIndex: "l_tol",
      key: "l_tol",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Take Up",
      dataIndex: "take_up",
      key: "take_up",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Kuping (mm)",
      dataIndex: "kuping",
      key: "kuping",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["flute"],
                    value: record.flute,
                  },
                  {
                    name: ["p_tol"],
                    value: record.p_tol,
                  },
                  {
                    name: ["l_tol"],
                    value: record.l_tol,
                  },
                  {
                    name: ["take_up"],
                    value: record.take_up,
                  },
                  {
                    name: ["kuping"],
                    value: record.kuping,
                  },
                ]);

                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (data) => {
    if (data.length > 2) {
      fetchDataListFluteVendorItem({
        search: data,
        flute_vendor: props.location.state.id,
      });
    } else {
      fetchDataListFluteVendorItem({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        flute_vendor: props.location.state.id,
      });
    }
  };

  const handleTableChangeFluteVendorItem = (pagination, filters, sorter) => {
    fetchDataListFluteVendorItem({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      flute_vendor: props.location.state.id,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Master Specification</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row justify="space-between">
          <Title level={4}>
            {props.location.state.vendor_code} -{" "}
            {props.location.state.vendor_name}
          </Title>
          <Button
            onClick={() => props.history.goBack()}
            type="default"
            icon={<ArrowLeftOutlined />}
          >
            Back to list
          </Button>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Specification" key="1">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearch}
                placeholder="Input min. 3 char"
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              onChange={handleTableChangeFluteVendorItem}
              pagination={paginationFluteVendorItem}
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTable.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "state" &&
                  col.dataIndex !== "type" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "flute_vendor" &&
                  col.dataIndex !== "material_group_description" &&
                  col.dataIndex !== "specification_id"
              )}
              dataSource={dataListFluteVendorItem}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fields}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="flute"
                  label="Flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input your flute!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="p_tol"
                  label="P Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input p tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="l_tol"
                  label="L Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your l tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="take_up"
                  label="Take Up"
                  rules={[
                    {
                      required: true,
                      message: "Please input take up!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="kuping"
                  label="Kuping (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your kuping!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="flute"
                  label="Flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input your flute!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="p_tol"
                  label="P Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input p tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="l_tol"
                  label="L Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your l tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="take_up"
                  label="Take Up"
                  rules={[
                    {
                      required: true,
                      message: "Please input take up!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="kuping"
                  label="Kuping (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your kuping!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>
            Do you want to delete this data <b>{dataRow?.characteristic}</b>?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesificationFluteVendorItem;
