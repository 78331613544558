import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListVendor,
  getDataListVendorPerformanceIndex,
  getDataVendorPerformanceExport,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const ReportVendorPerformance = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [paginationReportPerfVendor, setPaginationReportPerfVendor] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListVendor, setDataListVendor] = useState([]);
  const [dataListVendorPerfomance, setDataListVendorPerfomance] = useState([]);
  const [state, setState] = useState({
    type: "VENDOR PERFORMANCE",
    companyCode: "",
    vendorCode: "",
    validOn: "",
  });
  const { type, companyCode, vendorCode, validOn } = state;

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVendor(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListVendor({});
    fetchDataListCompany({});
  }, [fetchDataListVendor, fetchDataListCompany]);

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc,",
      dataIndex: "vendor_description",
      key: "vendor_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Code",
      dataIndex: "material_group_code",
      key: "material_group_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc.",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Achievement",
      dataIndex: "achievement",
      key: "achievement",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Validity Period",
      dataIndex: "validity_period",
      key: "validity_period",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Valid To",
      dataIndex: "valid_to",
      key: "valid_to",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Submit Date",
      dataIndex: "submit_date",
      key: "submit_date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Submit By",
      dataIndex: "submit_by",
      key: "submit_by",
      render: (text) => <p>{text}</p>,
    },
  ];

  const fetchDataListReportPerfVendor = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListVendorPerformanceIndex(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res.status === 201) {
      setPaginationReportPerfVendor({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListVendorPerfomance(res?.data?.items?.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDataTable = async (value) => {
    setFilter({
      type: "VENDOR PERFORMANCE",
      materialGroup: value.materialGroup,
      vendorCode: value.vendorCode,
      companyCode: value.companyCode,
      validOn: moment(value.validOn).format("MM.YYYY"),
    });
    fetchDataListReportPerfVendor({
      type: "VENDOR PERFORMANCE",
      materialGroup: value.materialGroup,
      vendorCode: value.vendorCode,
      companyCode: value.companyCode,
      validOn: moment(value.validOn).format("MM.YYYY"),
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const handleTableChangeReportVendorPerf = (pagination, filters, sorter) => {
    fetchDataListReportPerfVendor({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find(
      (data) => data !== "vendorCode" && state[data] === ""
    );

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        type: type,
        companyCode: companyCode,
        vendorCode: vendorCode,
        validOn: validOn ? moment(validOn).format("MM.YYYY") : "",
      };
      const res = await getDataVendorPerformanceExport(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const resetTable = useCallback(() => {
    setDataListVendorPerfomance([]);
  }, []);

  useEffect(() => {
    if (companyCode || vendorCode || validOn) {
      resetTable();
    }
  }, [resetTable, companyCode, vendorCode, validOn]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Vendor Performance Report</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("companyCode", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="vendorCode"
                  label="Vendor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    onChange={(e) => handleChange("vendorCode", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="select your vendor"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code } = data;
                        return (
                          <Option key={index} value={vendor_code}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="validOn"
                  label="Valid On"
                  rules={[
                    {
                      required: true,
                      message: "Please input your valid on!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    inputReadOnly={true}
                    picker="month"
                    onChange={(e) => handleChange("validOn", e)}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>

          <Row
            justify="end"
            style={{
              marginBottom: MarginEnum["2x"],
            }}
          >
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          pagination={paginationReportPerfVendor}
          onChange={handleTableChangeReportVendorPerf}
          bordered
          columns={columnsTable.filter(
            (col) =>
              col.dataIndex !== "valid_from" &&
              col.dataIndex !== "valid_to" &&
              col.dataIndex !== "company"
          )}
          dataSource={dataListVendorPerfomance}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
          scroll={{ x: 1210 }}
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default ReportVendorPerformance;
