import {
  Request,
  RequestDownloadFile,
  RequestPublic,
  RequestUploadFile,
} from "./headerRequest";
/**
 * @author wahyu fatur rizki (+62 822 7458 6011)
 * @return { obj }
 */

export const postLogin = (data) => {
  const response = RequestPublic().post("/api/v1/login", data);
  return response;
};

export const getDataListMasterSpecItem = (data) => {
  const response = Request().get(
    `/api/v1/material/specification?material_group=${data.material_group}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListMasterMaterialGroup = (data) => {
  const response = Request().get(
    `/api/v1/master/material_group?search=${data.search}`
  );
  return response;
};

export const getDataListConfig = () => {
  const response = Request().get("/api/v1/config");
  return response;
};

export const getDataListFinalReport = (data) => {
  const response = Request().get(
    `/api/v1/final-report?periode=${data.periode}&companyCode=${data.companyCode}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListSubmitAllocation = (data) => {
  const response = Request().get(
    `/api/v1/submit-allocation?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&materialGroup=${data.materialGroup}&adjustment_version=${data.adjustment_version}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListApprovalSchedulePurchaseOrder = (data) => {
  const response = Request().get(
    `/api/v1/submit-schedule-plan-order-list-approval?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListApprovalCompanyAndPeriode = (data) => {
  const response = Request().get(
    `/api/v1/list-approval?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListApprovalCompanyAndPeriodeItem = (data) => {
  const response = Request().get(
    `/api/v1/approval-allocation/${data.id}?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListApprovalSchedulePurchaseItem = (data) => {
  const response = Request().get(
    `/api/v1/approval-schedule-plan-order/${data.id}?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListSchedulePurchaseOrder = (data) => {
  const response = Request().get(
    `/api/v1/schedule-purchase-order?type=${data.type}&periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&materialGroup=${data.materialGroup}&adjustment_version=${data.adjustment_version}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getFinalDataListSchedulePurchaseOrder = (data) => {
  const response = Request().get(
    `/api/v1/final-report/schedule-purchase-order?periode=${data.periode}&companyCode=${data.companyCode}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListVendorAllocationCockpit = (data) => {
  const response = Request().get(
    `/api/v1/cockpit/vendor-allocation?companyCode=${data.companyCode}&periode=${data.periode}`
  );
  return response;
};

export const postVendorAllocationCockpitExecuteProgram = (data) => {
  const response = Request().post(
    `/api/v1/cockpit/vendor-allocation/execute?companyCode=${data.companyCode}&periode=${data.periode}&schedule=${data.schedule}`
  );
  return response;
};

export const getDataListReportMRP = (data) => {
  const response = Request().get(
    `/api/v1/report/mrp?periode=${data.periode || ""}&version=${
      data.version || ""
    }&companyCode=${data.companyCode || ""}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListAllocationPlanOrder = (data) => {
  const response = Request().get(
    `/api/v1/allocation-plan-order?periode=${data.periode || ""}&version=${
      data.version || ""
    }&companyCode=${data.companyCode || ""}&adjustment_version=${
      data.adjustment_version || ""
    }&type=${data.type || ""}&per_page=${data.pagination.pageSize}&page=${
      data.pagination.current
    }`
  );
  return response;
};

export const getDataListVendorAllocationSuggestion = (data) => {
  const response = Request().get(
    `/api/v1/vendor-allocation?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListVendorAllocationAdjustment = (data) => {
  const response = Request().get(
    `/api/v1/report/vendor-allocation/adjustment?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&adjustment_version=${data.adjustment_version}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListReportPR = (data) => {
  const response = Request().get(
    `/api/v1/report/pr?periode=${data.periode || ""}&version=${
      data.version || ""
    }&companyCode=${data.companyCode || ""}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListReportMRPVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/mrp/version?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListReportVASuggestionVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/vendor-allocation/version?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListVAAdjustmentVersion = (data) => {
  const response = Request().get(
    data.version
      ? `/api/v1/report/vendor-allocation/adjustment/version?periode=${data.periode}&companyCode=${data.companyCode}&version=${data.version}`
      : `/api/v1/report/vendor-allocation/adjustment/version?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListAllocationPlanOrderAdjustmentVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/allocation-plan-order/adjustment/version?periode=${data.periode}&companyCode=${data.companyCode}&version=${data.version}`
  );
  return response;
};

export const getDataListAllocationPlanOrderVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/allocation-plan-order/version?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListSchedulePurchaseOrderVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/schedule-purchase-order/version?type=${data.type}&periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListSchedulePurchaseOrderVersionAdjustment = (data) => {
  const response = Request().get(
    `/api/v1/report/schedule-purchase-order/version?type=${
      data.type || ""
    }&periode=${data.periode}&companyCode=${data.companyCode}&version=${
      data.version
    }`
  );
  return response;
};

export const getDataListReportPRVersion = (data) => {
  const response = Request().get(
    `/api/v1/report/pr/version?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getCockpitPriceRanking = (data) => {
  const response = Request().get("/api/v1/cockpit/price-ranking");
  return response;
};

export const getDataListStandardAllocationRatio = () => {
  const response = Request().get("/api/v1/master/standard-allocation-ratio");
  return response;
};

export const getDataListStandardAllocationJumlah = (data) => {
  const response = Request().get(
    `/api/v1/master/standard-allocation?material_group=${data.material_group}`
  );
  return response;
};

export const getDataListBobotRangkingIndex = (data) => {
  const response = Request().get(
    `/api/v1/master/bobot-rangking${
      data.search
        ? `?search=${data.search}`
        : `?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListPriceAchievementIndex = (data) => {
  const response = Request().get(
    `/api/v1/master/price-achievement?company_id=${data.company_id || ""}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListCommitUtamaIndex = (data) => {
  const response = Request().get(
    `/api/v1/master/commit-utama?vendorCode=${
      data.vendorCode || ""
    }&vendorDesc=${data.vendorDesc || ""}&companyCode=${
      data.companyCode || ""
    }&vendorType=${data.vendorType || ""}&ratio=${data.ratio || ""}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListUser = (data) => {
  const response = Request().get(
    `/api/v1/user?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListCommitRegulerIndex = (data) => {
  const response = Request().get(
    `/api/v1/master/commit-reguler?vendorCode=${
      data.vendorCode || ""
    }&vendorDesc=${data.vendorDesc || ""}&companyCode=${
      data.companyCode || ""
    }&ratio=${data.ratio || ""}&per_page=${data.pagination.pageSize}&page=${
      data.pagination.current
    }`
  );
  return response;
};

export const getDataListPopUpNotif = (data) => {
  const response = Request().get(
    `/api/v1/notifications/po?type=${data.type}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListRfq = (data) => {
  const response = Request().get(
    `/api/v1/rfq?materialGroup=${data.materialGroup}&type=${
      data.type
    }&vendorCode=${data.vendorCode || ""}&validOn=${data.validOn}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListBudget = (data) => {
  const response = Request().get(
    `/api/v1/budget?type=${data.type}&materialGroup=${data.materialGroup}&company=${data.company}&validFrom=${data.validFrom}&validTo=${data.validTo}&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListPriceRankingIndex = (data) => {
  const response = Request().get(
    `/api/v1/price-ranking?validOn=${data.validOn}&materialGroup=${
      data.materialGroup
    }&companyCode=${data.companyCode || ""}&vendorCode=${
      data.vendorCode
    }&type=${data.type}&validOn=${data.validOn}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListVendorPerformanceIndex = (data) => {
  const response = Request().get(
    `/api/v1/vendor-performance?type=${data.type}&companyCode=${
      data.companyCode
    }&vendorCode=${data.vendorCode || ""}&validOn=${data.validOn}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListCharacteritic = (data) => {
  const response = Request().get(
    `/api/v1/master/characteristic?material_group_id=${data.material_group_id}&search=${data.search}`
  );
  return response;
};

export const getDataListMasterSpecCartoonBox = (data) => {
  const response = Request().get(
    `/api/v1/material/specification-carton-box?material_group=${
      data.material_group
    }${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListDesignAvailability = (data) => {
  const response = Request().get(
    `/api/v1/master/design-availability?vendorCode=${
      data.vendorCode || ""
    }&vendorDesc=${data.vendorDesc || ""}&materialDesc=${
      data.materialDesc || ""
    }&oldMaterialDesc=${data.oldMaterialDesc || ""}&activeDesign=${
      data.activeDesign || ""
    }&activePr=${data.activePr || ""}&material=${
      data.material || ""
    }&oldMaterial=${data.oldMaterial || ""}&created_at=${
      data.created_at || ""
    }&updated_at=${data.updated_at || ""}&status=${
      data.status || ""
    }&companyCode=${data.companyCode || ""}&per_page=${
      data.pagination.pageSize
    }&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListDesignAvailabilityTemplateDownload = () => {
  const response = Request().get(
    "/api/v1/master/download-template/design-availability"
  );
  return response;
};

export const getCommitRegulerTemplateDownload = () => {
  const response = Request().get(
    "/api/v1/master/download-template/commit-reguler"
  );
  return response;
};

export const getDataTemplateRfqDownload = (data) => {
  const response = Request().get(
    `/api/v1/download-template/rfq?type=${data.type}&material_group=${data.material_group}`
  );
  return response;
};

export const getDataTemplateBudgetDownload = (data) => {
  const response = Request().get(
    `/api/v1/download-template/budget?type=${data.type}`
  );
  return response;
};

export const getDataVendorPerformanceDownloadTemplate = (data) => {
  const response = Request().get(
    `/api/v1/download-template/vendor-performance?type=${data.type}`
  );
  return response;
};

export const getDataListDesignAvailabilityDownload = () => {
  const response = RequestDownloadFile().get(
    "/api/v1/master/export/design-availability"
  );
  return response;
};

export const getDataListAllocationPlanOrderExport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/allocation-plan-order?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&adjustment_version=${data.adjustment_version}&type=${data.type}`
  );
  return response;
};

export const getDataListSchedulePurchaseOrderExport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/schedule-purchase-order?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&adjustment_version=${data.adjustment_version}&type=${data.type}`
  );
  return response;
};

export const getFinalDataListSchedulePurchaseOrderExport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/final-report/schedule-purchase-order?periode=${data.periode}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getExportFinalReport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/final-report?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&adjustment_version=${data.adjustment_version}&type=${data.type}`
  );
  return response;
};

export const getDataListRFQReportDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/rfq?materialGroup=${data.materialGroup}&type=${data.type}&vendorCode=${data.vendorCode}&validOn=${data.validOn}`
  );
  return response;
};

export const getDataCommitRegulerReportDownload = (data) => {
  const response = RequestDownloadFile().get(
    "/api/v1/master/export/commit-reguler"
  );
  return response;
};

export const getPriceRankingExport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/price-ranking?materialGroup=${data.materialGroup}&vendorCode=${data.vendorCode}&validOn=${data.validOn}&type=${data.type}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListBudgetReportDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/budget?materialGroup=${data.materialGroup}&type=${data.type}&validFrom=${data.validFrom}&validTo=${data.validTo}&company=${data.company}`
  );
  return response;
};

export const getDataListMRPReportDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/report/mrp/export?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListPRReportDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/report/pr/export?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListVASuggestionDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/vendor-allocation?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}`
  );
  return response;
};

export const getDataListVAAdjustmentDownload = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/vendor-allocation/adjustment?periode=${data.periode}&version=${data.version}&companyCode=${data.companyCode}&adjustment_version=${data.adjustment_version}`
  );
  return response;
};

export const getDataVendorPerformanceExport = (data) => {
  const response = RequestDownloadFile().get(
    `/api/v1/export/vendor-performance?type=${data.type}&companyCode=${data.companyCode}&vendorCode=${data.vendorCode}&validOn=${data.validOn}`
  );
  return response;
};

export const getDataListMasterCartoonBoxFluteGeneral = (data) => {
  const response = Request().get(
    `/api/v1/material/flute-general?material_group=${data.material_group}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListMasterMinimumOrderQty = (data) => {
  const response = Request().get(
    `/api/v1/master/moq${
      data.search
        ? `?search=${data.search}`
        : `?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListMasterCartoonBoxFluteVendor = (data) => {
  const response = Request().get(
    `/api/v1/material/flute-vendor?material_group=${data.material_group}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListMasterCartoonBoxLayerSekat = (data) => {
  const response = Request().get(
    `/api/v1/material/layer-sekat?material_group=${data.material_group}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListMasterSpec = (data) => {
  const response = Request().get(
    `/api/v1/master/specification?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
  );
  return response;
};

export const getDataListVendor = (data) => {
  const response = Request().get(`/api/v1/master/vendor?search=${data.search}`);
  return response;
};

export const getDataListTableVendor = (data) => {
  const response = Request().get(
    `/api/v1/master/vendor${
      data.search
        ? `?search=${data.search}`
        : `?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListTableCompany = (data) => {
  const response = Request().get(
    `/api/v1/master/company${
      data.search
        ? `?search=${data.search}`
        : `?per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListCompany = (data) => {
  const response = Request().get(
    `/api/v1/master/company?search=${data.search || ""}`
  );
  return response;
};

export const getDataListRole = (data) => {
  const response = Request().get("/api/v1/role");
  return response;
};

export const getDataListMaterial = (data) => {
  const response = Request().get(
    `/api/v1/master/material?search=${data.search}`
  );
  return response;
};

export const getDataListFluteVendorItem = (data) => {
  const response = Request().get(
    `/api/v1/material/flute-vendor-item?flute_vendor=${data.flute_vendor}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const getDataListLayerSekatItem = (data) => {
  const response = Request().get(
    `/api/v1/material/layer-sekat-item?layer_sekat_id=${data.layer_sekat_id}${
      data.search
        ? `&search=${data.search}`
        : `&per_page=${data.pagination.pageSize}&page=${data.pagination.current}`
    }`
  );
  return response;
};

export const postDataListMasterSpec = (data) => {
  const response = Request().post("/api/v1/master/specification", data);
  return response;
};

export const postDataListSetting = (data) => {
  const response = Request().post("/api/v1/config", data);
  return response;
};

export const postDataListMasterMinimumOrderQty = (data) => {
  const response = Request().post("/api/v1/master/moq", data);
  return response;
};

export const postDataSubmitAllocation = (data) => {
  const response = Request().post("/api/v1/submit-allocation", data);
  return response;
};

export const postDataSubmitSchedulePurchaseOrder = (data) => {
  const response = Request().post("/api/v1/submit-schedule-plan-order", data);
  return response;
};

export const postDataCockpitMRP = (data) => {
  const response = Request().post("/api/v1/cockpit/mrp", data);
  return response;
};

export const postDataCockpitAllocationPlanOrder = (data) => {
  const response = Request().post(
    "/api/v1/cockpit/allocation-plan-order/execute",
    data
  );
  return response;
};

export const postDataCockpitPR = (data) => {
  const response = Request().post("/api/v1/cockpit/pr", data);
  return response;
};

export const postDataStandardAllocation = (data) => {
  const response = Request().post("/api/v1/master/standard-allocation", data);
  return response;
};

export const postDataStandardAllocationRatio = (data) => {
  const response = Request().post(
    "/api/v1/master/standard-allocation-ratio",
    data
  );
  return response;
};

export const postDataCommitUtama = (data) => {
  const response = Request().post("/api/v1/user", data);
  return response;
};

export const postDataUser = (data) => {
  const response = Request().post("/api/v1/user", data);
  return response;
};

export const postDataCommitReguler = (data) => {
  const response = Request().post("/api/v1/master/commit-reguler", data);
  return response;
};

export const postDataListBobotRangkingStore = (data) => {
  const response = Request().post("/api/v1/master/bobot-rangking", data);
  return response;
};

export const postDataListPriceAchievementStore = (data) => {
  const response = Request().post("/api/v1/master/price-achievement", data);
  return response;
};

export const postDataListMasterSpecItem = (data) => {
  const response = Request().post("/api/v1/material/specification", data);
  return response;
};

export const postDataListMasterSpecCartonBox = (data) => {
  const response = Request().post(
    "/api/v1/material/specification-carton-box",
    data
  );
  return response;
};

export const postDataListMasterCartoonBoxFluteGeneral = (data) => {
  const response = Request().post("/api/v1/material/flute-general", data);
  return response;
};

export const postDataListMasterFluteByVendor = (data) => {
  const response = Request().post("/api/v1/material/flute-vendor", data);
  return response;
};

export const postDataListMasterLayerSekat = (data) => {
  const response = Request().post("/api/v1/material/layer-sekat", data);
  return response;
};

export const postDataListFluteVendorItem = (data) => {
  const response = Request().post("/api/v1/material/flute-vendor-item", data);
  return response;
};

export const postDataListLayerSekatItem = (data) => {
  const response = Request().post("/api/v1/material/layer-sekat-item", data);
  return response;
};

export const postDataListDesignAvailability = (data) => {
  const response = Request().post("/api/v1/master/design-availability", data);
  return response;
};

export const postDataListDesignAvailabilityUploadTemplate = (data) => {
  const response = RequestUploadFile().post(
    "/api/v1/master/import/design-availability",
    data
  );
  return response;
};

export const postFileAllocationPlanOrderAdjustment = (data) => {
  const response = RequestUploadFile().post(
    "/api/v1/allocation-plan-order/adjustment",
    data
  );
  return response;
};

export const postFileSchedulePurchaseOrderAdjustment = (data) => {
  const response = RequestUploadFile().post(
    "/api/v1/schedule-purchase-order/adjustment",
    data
  );
  return response;
};

export const postDataListVAAdjustmentUpload = (data) => {
  console.log(data);
  const response = RequestUploadFile().post(
    "/api/v1/vendor-allocation/adjustment",
    data
  );
  return response;
};

export const postDataListVendorPerformanceUpload = (data) => {
  const response = RequestUploadFile().post(
    "/api/v1/import/vendor-performance",
    data
  );
  return response;
};

export const postDataListRfqUploadTemplate = (data) => {
  const response = RequestUploadFile().post("/api/v1/import/rfq", data);
  return response;
};

export const postDataListBudgetUploadTemplate = (data) => {
  const response = RequestUploadFile().post("/api/v1/import/budget", data);
  return response;
};

export const postDataListUploadCommitReguler = (data) => {
  const response = RequestUploadFile().post(
    "/api/v1/master/import/commit-reguler",
    data
  );
  return response;
};

export const deleteDataListMasterSpec = (id) => {
  const response = Request().delete(`/api/v1/master/specification/${id}`);
  return response;
};

export const deleteDataListMasterMinimumOrderQty = (id) => {
  const response = Request().delete(`/api/v1/master/moq/${id}`);
  return response;
};

export const deleteStandartAllocation = (id) => {
  const response = Request().delete(`/api/v1/master/standard-allocation/${id}`);
  return response;
};

export const deleteStandartAllocationRatio = (id) => {
  const response = Request().delete(
    `/api/v1/master/standard-allocation-ratio/${id}`
  );
  return response;
};

export const deleteDataListPriceAchievementDelete = (id) => {
  const response = Request().delete(`/api/v1/master/price-achievement/${id}`);
  return response;
};

export const deleteDataListBobotRangkingDelete = (id) => {
  const response = Request().delete(`/api/v1/master/bobot-rangking/${id}`);
  return response;
};

export const deleteDataListMasterSpecItem = (id) => {
  const response = Request().delete(`/api/v1/material/specification/${id}`);
  return response;
};

export const deleteDataListMasterSpecCartonBox = (id) => {
  const response = Request().delete(
    `/api/v1/material/specification-carton-box/${id}`
  );
  return response;
};

export const deleteDataListMasterCartoonBoxFluteGeneral = (id) => {
  const response = Request().delete(`/api/v1/material/flute-general/${id}`);
  return response;
};

export const deleteDataListMasterCartoonBoxFluteByVendor = (id) => {
  const response = Request().delete(`/api/v1/material/flute-vendor/${id}`);
  return response;
};

export const deleteDataListMasterCartoonBoxLayerSekat = (id) => {
  const response = Request().delete(`/api/v1/material/layer-sekat/${id}`);
  return response;
};

export const deleteDataListFluteVendorItem = (id) => {
  const response = Request().delete(`/api/v1/material/flute-vendor-item/${id}`);
  return response;
};

export const deleteDataListLayerSekatItem = (id) => {
  const response = Request().delete(`/api/v1/material/layer-sekat-item/${id}`);
  return response;
};

export const deleteDataListDesignAvailability = (id) => {
  const response = Request().delete(`/api/v1/master/design-availability/${id}`);
  return response;
};

export const deleteDataCommitUtama = (id) => {
  const response = Request().delete(`/api/v1/master/commit-utama/${id}`);
  return response;
};

export const deleteDataCommitReguler = (id) => {
  const response = Request().delete(`/api/v1/master/commit-reguler/${id}`);
  return response;
};

export const putDataListMasterSpec = (id, data) => {
  const response = Request().put(`/api/v1/master/specification/${id}`, data);
  return response;
};

export const putDataListMasterMinimumOrderQty = (id, data) => {
  const response = Request().put(`/api/v1/master/moq/${id}`, data);
  return response;
};

export const putDataSubmitAllocation = (id, data) => {
  const response = Request().put(`/api/v1/submit-allocation/${id}`, data);
  return response;
};

export const onApproveOrRejectSchedulePurchaseOrder = (id, data) => {
  const response = Request().put(
    `/api/v1/submit-schedule-plan-order/${id}`,
    data
  );
  return response;
};

export const putDataStandartAllocation = (id, data) => {
  const response = Request().put(
    `/api/v1/master/standard-allocation/${id}`,
    data
  );
  return response;
};

export const putDataStandardAllocationRatio = (id, data) => {
  const response = Request().put(
    `/api/v1/master/standard-allocation-ratio/${id}`,
    data
  );
  return response;
};

export const putDataCommitUtama = (id, data) => {
  const response = Request().put(`/api/v1/master/commit-utama/${id}`, data);
  return response;
};

export const putDataUser = (id, data) => {
  const response = Request().put(`/api/v1/user/${id}`, data);
  return response;
};

export const putDataCommitReguler = (id, data) => {
  const response = Request().put(`/api/v1/master/commit-reguler/${id}`, data);
  return response;
};

export const putDataListPriceAchievementUpdate = (id, data) => {
  const response = Request().put(
    `/api/v1/master/price-achievement/${id}`,
    data
  );
  return response;
};

export const putDataListBobotRangkingUpdate = (id, data) => {
  const response = Request().put(`/api/v1/master/bobot-rangking/${id}`, data);
  return response;
};

export const putDataListMasterSpecItem = (id, data) => {
  const response = Request().put(`/api/v1/material/specification/${id}`, data);
  return response;
};

export const putDataListMasterSpecCartonBox = (id, data) => {
  const response = Request().put(
    `/api/v1/material/specification-carton-box/${id}`,
    data
  );
  return response;
};

export const putDataListMasterCartoonBoxFluteGeneral = (id, data) => {
  const response = Request().put(`/api/v1/material/flute-general/${id}`, data);
  return response;
};

export const putDataListMasterCartoonBoxFluteByVendor = (id, data) => {
  const response = Request().put(`/api/v1/material/flute-vendor/${id}`, data);
  return response;
};

export const putDataListMasterCartoonBoxLayerSekat = (id, data) => {
  const response = Request().put(`/api/v1/material/layer-sekat/${id}`, data);
  return response;
};

export const putDataListFluteVendorItem = (id, data) => {
  const response = Request().put(
    `/api/v1/material/flute-vendor-item/${id}`,
    data
  );
  return response;
};

export const putDataListLayerSekatItem = (id, data) => {
  const response = Request().put(
    `/api/v1/material/layer-sekat-item/${id}`,
    data
  );
  return response;
};

export const putDataListDesignAvailability = (id, data) => {
  const response = Request().put(
    `/api/v1/master/design-availability/${id}`,
    data
  );
  return response;
};
