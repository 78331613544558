import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataCommitUtama,
  getDataListCommitUtamaIndex,
  getDataListCompany,
  getDataListVendor,
  postDataCommitUtama,
  putDataCommitUtama,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterCommitUtama = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [paginationCommitUtama, setPaginationCommitUtama] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [state, setState] = useState({
    companyCodeState: "",
    vendorDescState: "",
    vendorCodeState: "",
    ratioState: "",
    vendorTypeState: "",
  });
  const {
    companyCodeState,
    vendorDescState,
    vendorCodeState,
    ratioState,
    vendorTypeState,
  } = state;
  const [dataListCompany, setDataListCompany] = useState([]);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;
  const [fields, setFields] = useState([]);
  const [dataListCommitUtama, setdataListCommitUtama] = useState([]);
  const [dataListVendorType, setdataListVendorType] = useState(null);
  const [dataListVendor, setDataListVendor] = useState(null);
  const [openFilter, setIsOpenFilter] = useState(false);

  const fetchDataListCommitUtama = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListCommitUtamaIndex(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPaginationCommitUtama({
          ...params.pagination,
          total: res.data.items.total,
        });
        setdataListCommitUtama(res.data.items.data);
        setdataListVendorType(res.data.vendor_types);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVendor(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCommitUtama({ pagination: paginationCommitUtama });
    fetchDataListVendor({});
    fetchDataListCompany({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListCommitUtama, fetchDataListVendor, fetchDataListCompany]);

  const onFinish = async (values) => {
    setIsLoading(true);

    const res = await postDataCommitUtama(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListCommitUtama({ pagination: paginationCommitUtama });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    const res = await putDataCommitUtama(dataRow.id, values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: <p>{err?.response?.data?.message}</p>,
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListCommitUtama({ pagination: paginationCommitUtama });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataCommitUtama(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: <p>{err?.response?.data?.message}</p>,
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListCommitUtama({ pagination: paginationCommitUtama });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "companyCode",
      key: "companyCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "VIEW",
                })
              }
              type="primary"
              icon={<EyeOutlined />}
            >
              View
            </Button>
            <Button
              onClick={() => {
                let tempVendorId = dataListVendor.find(
                  (finding) => finding.vendor_code === record.vendorCode
                );
                setFields([
                  {
                    name: ["company_id"],
                    value: record.companyCode === "KSNI" ? 1 : 2,
                  },
                  {
                    name: ["vendor_id"],
                    value: tempVendorId?.id,
                  },
                  {
                    name: ["vendor_type"],
                    value: record.vendorType,
                  },
                  {
                    name: ["ratio"],
                    value: record.ratio,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const viewDataTable = async (value) => {
    setFilter({
      vendorCode: value.vendorCode,
      vendorDesc: value.vendorDesc,
      companyCode: value.companyCode,
      vendorType: value.vendorType,
      ratio: value.ratio,
    });
    fetchDataListCommitUtama({
      vendorCode: value.vendorCode,
      vendorDesc: value.vendorDesc,
      companyCode: value.companyCode,
      vendorType: value.vendorType,
      ratio: value.ratio,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const handleTableChangeCommitUtama = (pagination, filters, sorter) => {
    fetchDataListCommitUtama({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const resetTable = useCallback(() => {
    setdataListCommitUtama([]);
  }, []);

  useEffect(() => {
    if (
      companyCodeState ||
      vendorDescState ||
      vendorCodeState ||
      ratioState ||
      vendorTypeState
    ) {
      resetTable();
    }
  }, [
    resetTable,
    companyCodeState,
    vendorDescState,
    vendorCodeState,
    ratioState,
    vendorTypeState,
  ]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Main Commitment</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        {openFilter && (
          <Form scrollToFirstError onFinish={viewDataTable}>
            <Row>
              <Space>
                <Col>
                  <Form.Item
                    name="vendorDesc"
                    label="Vendor Desc"
                    rules={[
                      {
                        required: false,
                        message: "Please input your vendor desc.!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListVendor({})}
                      onSearch={onSearchVendorItem}
                      onChange={(e) => handleChange("vendorDescState", e)}
                      optionFilterProp="children"
                      placeholder="select your vendor desc."
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListVendor &&
                        dataListVendor.map((data, index) => {
                          const { name } = data;
                          return (
                            <Option key={index} value={name}>
                              {name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="vendorCode"
                    label="Vendor Code"
                    rules={[
                      {
                        required: false,
                        message: "Please input vendor code!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListVendor({})}
                      onSearch={onSearchVendorItem}
                      onChange={(e) => handleChange("vendorCodeState", e)}
                      optionFilterProp="children"
                      placeholder="select"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListVendor &&
                        dataListVendor.map((data, index) => {
                          const { vendor_code } = data;
                          return (
                            <Option key={index} value={vendor_code}>
                              {vendor_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="companyCode"
                    label="Company"
                    rules={[
                      {
                        required: false,
                        message: "Please input your company!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      loading={isLoading}
                      onSearch={onSearchCompany}
                      onFocus={() => fetchDataListCompany({})}
                      placeholder="Input min. 3 char"
                      optionFilterProp="children"
                      onChange={(e) => handleChange("companyCodeState", e)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListCompany &&
                        dataListCompany.map((data, index) => {
                          const { company_code } = data;
                          return (
                            <Option key={index} value={company_code}>
                              {company_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>
            <Row>
              <Space>
                <Col>
                  <Form.Item
                    name="vendorType"
                    label="Vendor Type"
                    rules={[
                      {
                        required: false,
                        message: "Please input your vendor type!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isLoading}
                      optionFilterProp="children"
                      onChange={(e) => handleChange("vendorTypeState", e)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="select your vendor type"
                    >
                      {dataListVendorType &&
                        Object.keys(dataListVendorType).map((data, index) => {
                          return (
                            <Option
                              key={index}
                              value={dataListVendorType[data]}
                            >
                              {dataListVendorType[data]}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="ratio"
                    label="Ratio"
                    help="min. 0 and max. 100"
                    rules={[
                      {
                        required: false,
                        message: "Please input ratio!",
                      },
                    ]}
                    hasFeedback
                  >
                    <InputNumber
                      onChange={(e) => handleChange("ratioState", e)}
                      onWheel={(e) => e.target.blur()}
                      min={0}
                      max={100}
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Space>
              <Col style={{ marginLeft: MarginEnum["2x"] }}>
                <Button
                  loading={isLoading}
                  style={{
                    backgroundColor: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  type="primary"
                  htmlType="submit"
                  icon={<EyeOutlined />}
                >
                  View
                </Button>
              </Col>
              <Col style={{ marginLeft: MarginEnum["2x"] }}>
                <Button
                  loading={isLoading}
                  style={{
                    color: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  onClick={() => setIsOpenFilter(false)}
                  icon={<FilterOutlined />}
                >
                  Close Filter
                </Button>
              </Col>
            </Row>
          </Form>
        )}

        <Row
          justify="end"
          style={{
            marginBottom: 12,
          }}
        >
          <Space>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  typeAction: "ADD",
                  isShowModalAction: true,
                })
              }
              style={{
                backgroundColor: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              type="primary"
              icon={<FileAddOutlined />}
            >
              Add
            </Button>
            <Button
              onClick={() => setIsOpenFilter(true)}
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              loading={isLoading}
              icon={<FilterOutlined />}
            >
              Open Filter
            </Button>
          </Space>
        </Row>
        <Table
          loading={isLoading}
          pagination={paginationCommitUtama}
          onChange={handleTableChangeCommitUtama}
          bordered
          columns={columnsTable}
          dataSource={dataListCommitUtama}
          scroll={{ x: 1500 }}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {data === "created_at" || data === "updated_at"
                    ? moment(dataRow[data]).format("DD-MM-YYYY")
                    : dataRow[data]}
                </span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fields}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    allowClear
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={1} value={1}>
                      KSNI - Kaldu Sari Nabati
                    </Option>
                    <Option key={2} value={2}>
                      NSI - Nutribave sinergy I.
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_type"
                  label="Vendor Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    loading={isLoading}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="select your vendor type"
                  >
                    {dataListVendorType &&
                      Object.keys(dataListVendorType).map((data, index) => {
                        return (
                          <Option key={index} value={dataListVendorType[data]}>
                            {dataListVendorType[data]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  help="min. 0 and max. 100"
                  name="ratio"
                  label="Ratio"
                  rules={[
                    {
                      required: true,
                      message: "Please input ratio!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber min={0} max={100} type="number" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_type"
                  label="Vendor Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    loading={isLoading}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="select your vendor type"
                  >
                    {dataListVendorType &&
                      Object.keys(dataListVendorType).map((data, index) => {
                        return (
                          <Option key={index} value={dataListVendorType[data]}>
                            {dataListVendorType[data]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  help="min. 0 and max. 100"
                  name="ratio"
                  label="Ratio"
                  rules={[
                    {
                      required: true,
                      message: "Please input ratio!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    min={0}
                    max={100}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>Apakah anda ingin menghapus data ini ?</p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterCommitUtama;
