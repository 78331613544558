import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { currencyFormatter } from "../global/currencyFormatter";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListReportVASuggestionVersion,
  getDataListVASuggestionDownload,
  getDataListVendorAllocationSuggestion,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const VASuggestionReport = (props) => {
  const [defaultKeyTab, setDefaultKeyTab] = useState("rekap");
  const [modal, contextHolder] = Modal.useModal();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListVASuggestion, setDataListVASuggestion] = useState([]);
  const [dataListVASuggestionVersion, setDataListVASuggestionVersion] =
    useState([]);
  const [state, setState] = useState({
    companyCodeState: "",
    versionState: "",
    periodeState: "",
  });
  const { companyCodeState, versionState, periodeState } = state;
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataListVASuggestionVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
    };
    const res = await getDataListReportVASuggestionVersion(data).catch(
      (err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVASuggestionVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState]);

  const fetchDataListVASuggestion = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListVendorAllocationSuggestion(params).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data[params.key || "rekap"].total,
        });
        setDataListVASuggestion(res.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCompany({});
  }, [fetchDataListCompany]);

  const columnsTableRekap = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "PR",
      children: [
        {
          title: dataListVASuggestion?.p1,
          children: [
            {
              title: "Qty",
              dataIndex: "pr1_qty",
              key: "pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "pr1_amount",
              key: "pr1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "pr1_contribution",
              key: "pr1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "pr1_sku",
              key: "pr1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: "MRP",
      children: [
        {
          title: dataListVASuggestion?.m1,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp1_qty",
              key: "mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp1_amount",
              key: "mrp1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp1_contribution",
              key: "mrp1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp1_sku",
              key: "mrp1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: dataListVASuggestion?.m2,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp2_qty",
              key: "mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp2_amount",
              key: "mrp2_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp2_contribution",
              key: "mrp2_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp2_sku",
              key: "mrp2_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: dataListVASuggestion?.m3,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp3_qty",
              key: "mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp3_amount",
              key: "mrp3_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp3_contribution",
              key: "mrp3_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp3_sku",
              key: "mrp3_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
  ];

  const columnsTableDetail = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Replaced Material",
      dataIndex: "replacedMaterial",
      key: "replacedMaterial",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "materialDesc",
      key: "materialDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Replaced Material Desc.",
      dataIndex: "replacedMaterialDesc",
      key: "replacedMaterialDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Design",
      dataIndex: "design",
      key: "design",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "PR",
      dataIndex: "pr",
      key: "pr",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "PR",
      children: [
        {
          title: dataListVASuggestion?.p1,
          children: [
            {
              title: "Qty",
              dataIndex: "pr1_qty",
              key: "pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Ranking",
              dataIndex: "pr1_ranking",
              key: "pr1_ranking",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "pr1_price",
              key: "pr1_price",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Ratio",
              dataIndex: "pr1_ratio",
              key: "pr1_ratio",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: "MRP",
      children: [
        {
          title: dataListVASuggestion?.m1,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp1_qty",
              key: "mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Ranking",
              dataIndex: "mrp1_ranking",
              key: "mrp1_ranking",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp1_price",
              key: "mrp1_price",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Ratio",
              dataIndex: "mrp1_ratio",
              key: "mrp1_ratio",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: dataListVASuggestion?.m2,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp2_qty",
              key: "mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Ranking",
              dataIndex: "mrp2_ranking",
              key: "mrp2_ranking",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp2_price",
              key: "mrp2_price",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Ratio",
              dataIndex: "mrp2_ratio",
              key: "mrp2_ratio",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: dataListVASuggestion?.m3,
          children: [
            {
              title: "Qty",
              dataIndex: "mrp3_qty",
              key: "mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Ranking",
              dataIndex: "mrp3_ranking",
              key: "mrp3_ranking",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp3_price",
              key: "mrp3_price",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Ratio",
              dataIndex: "mrp3_ratio",
              key: "mrp3_ratio",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
  ];

  const columnsTableError = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "materialDesc",
      key: "materialDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Periode",
      dataIndex: "periode",
      key: "periode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Error Data",
      dataIndex: "errorData",
      key: "errorData",
      render: (text) => <p>{text}</p>,
    },
  ];

  const columnsTable = {
    rekap: columnsTableRekap,
    detail: columnsTableDetail,
    error: columnsTableError,
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const viewDataTable = (value) => {
    setDefaultKeyTab("rekap");
    setFilter({
      periode: moment(value.periode).format("MM.YYYY"),
      companyCode: value.companyCode,
      version: value.version,
    });
    fetchDataListVASuggestion({
      periode: moment(value.periode).format("MM.YYYY"),
      companyCode: value.companyCode,
      version: value.version,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find((data) => state[data] === "");

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        companyCode: companyCodeState || "",
        version: versionState,
        periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      };
      const res = await getDataListVASuggestionDownload(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListVASuggestion({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const resetTable = useCallback(() => {
    setDataListVASuggestion([]);
  }, []);

  useEffect(() => {
    if (companyCodeState || periodeState || versionState) {
      resetTable();
    }
  }, [resetTable, companyCodeState, periodeState, versionState]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>VA Suggestion Report</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("companyCodeState", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("periodeState", e)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[
                    {
                      required: true,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={!periodeState || !companyCodeState}
                    showSearch
                    onChange={(e) => handleChange("versionState", e)}
                    loading={isLoading}
                    style={{ width: 300 }}
                    onFocus={() => fetchDataListVASuggestionVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVASuggestionVersion &&
                      dataListVASuggestionVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Tabs
          onChange={(e) => {
            setDefaultKeyTab(e);
            setFilter({
              ...filter,
              key: e,
            });
            fetchDataListVASuggestion({
              ...filter,
              key: e,
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
          activeKey={defaultKeyTab}
          defaultActiveKey={defaultKeyTab}
        >
          {Object.keys(dataListVASuggestion).map((key) => {
            if (key === "m1" || key === "m2" || key === "m3" || key === "p1") {
              // eslint-disable-next-line
              return;
            }
            return (
              <TabPane
                tab={`${key.charAt(0).toUpperCase() + key.slice(1)} Data`}
                key={key}
              >
                <Table
                  loading={isLoading}
                  bordered
                  pagination={pagination}
                  onChange={handleTableChange}
                  rowClassName={(record, index) => {
                    if (index % 2 === 1) {
                      return "color-gray-2";
                    } else {
                      return "color-gray-1";
                    }
                  }}
                  size="small"
                  columns={columnsTable[key]}
                  dataSource={dataListVASuggestion[key].data}
                  scroll={
                    key === "rekap"
                      ? { x: 1800 }
                      : key === "detail"
                      ? { x: 2100 }
                      : undefined
                  }
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      {contextHolder}
    </Content>
  );
};

export default VASuggestionReport;
