/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  InputNumber,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListLayerSekatItem,
  getDataListLayerSekatItem,
  postDataListLayerSekatItem,
  putDataListLayerSekatItem,
} from "../services/retrieveData";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterSpesificationLayerSekatItem = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [paginationLayerSekatItem, setPaginationLayerSekatItem] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataListFluteVendorItem, setDataListFluteVendorItem] = useState([]);
  const [fields, setFields] = useState([]);
  const [dataListCurrency, setDataListCurrency] = useState(null);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const fetchDataListFluteVendorItem = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListLayerSekatItem(params).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationLayerSekatItem({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListFluteVendorItem(res.data.items.data);
      setDataListCurrency(res.data.currencies);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataListFluteVendorItem({
      layer_sekat_id: props.location.state.id,
      pagination: paginationLayerSekatItem,
    });
  }, [fetchDataListFluteVendorItem]);

  const onFinish = async (values) => {
    setIsLoading(true);
    let data = {
      value: values.value,
      layer_sekat_id: props.location.state.id,
      currency: values.currency,
    };

    const res = await postDataListLayerSekatItem(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListFluteVendorItem({
        layer_sekat_id: props.location.state.id,
        pagination: paginationLayerSekatItem,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      value: values.value,
      layer_sekat_id: props.location.state.id,
      currency: values.currency,
    };

    const res = await putDataListLayerSekatItem(dataRow.id, data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListFluteVendorItem({
        layer_sekat_id: props.location.state.id,
        pagination: paginationLayerSekatItem,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListLayerSekatItem(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteVendorItem({
        layer_sekat_id: props.location.state.id,
        pagination: paginationLayerSekatItem,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  let columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Layer Sekat",
      dataIndex: "layer_sekat_id",
      key: "layer_sekat_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic",
      dataIndex: "characteristic",
      key: "characteristic",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic Des",
      dataIndex: "characteristic_description",
      key: "characteristic_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Spec ID",
      dataIndex: "material_specification_id",
      key: "material_specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Carton Type",
      dataIndex: "carton_type",
      key: "carton_type",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Constanta",
      dataIndex: "constanta",
      key: "constanta",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["value"],
                    value: record.value,
                  },
                  {
                    name: ["currency"],
                    value: record.currency,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (data) => {
    if (data.length > 2) {
      fetchDataListFluteVendorItem({
        search: data,
        layer_sekat_id: props.location.state.id,
      });
    } else {
      fetchDataListFluteVendorItem({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        layer_sekat_id: props.location.state.id,
      });
    }
  };

  const handleTableChangeLayerSekatItem = (pagination, filters, sorter) => {
    fetchDataListFluteVendorItem({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      layer_sekat_id: props.location.state.id,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Master Specification</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row justify="space-between">
          <Title level={4}>
            {props.location.state.vendor_code} -{" "}
            {props.location.state.vendor_name}
          </Title>
          <Button
            onClick={() => props.history.goBack()}
            type="default"
            icon={<ArrowLeftOutlined />}
          >
            Back to list
          </Button>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Specification" key="1">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Input.Search
                onSearch={onSearch}
                placeholder="Input min. 3 char"
                loading={isLoading}
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              onChange={handleTableChangeLayerSekatItem}
              pagination={paginationLayerSekatItem}
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={
                props.location.state.type === "Constanta Amount"
                  ? columnsTable.filter(
                      (col) =>
                        col.dataIndex !== "uuid" &&
                        col.dataIndex !== "layer_sekat_id" &&
                        col.dataIndex !== "characteristic" &&
                        col.dataIndex !== "characteristic_description" &&
                        col.dataIndex !== "constanta" &&
                        col.dataIndex !== "material_specification_id"
                    )
                  : props.location.state.type === "Constanta %"
                  ? columnsTable.filter(
                      (col) =>
                        col.dataIndex !== "uuid" &&
                        col.dataIndex !== "layer_sekat_id" &&
                        col.dataIndex !== "characteristic" &&
                        col.dataIndex !== "currency" &&
                        col.dataIndex !== "characteristic_description" &&
                        col.dataIndex !== "constanta" &&
                        col.dataIndex !== "material_specification_id"
                    )
                  : columnsTable.filter(
                      (col) =>
                        col.dataIndex !== "uuid" &&
                        col.dataIndex !== "layer_sekat_id" &&
                        col.dataIndex !== "carton_type" &&
                        col.dataIndex !== "currency" &&
                        col.dataIndex !== "characteristic" &&
                        col.dataIndex !== "constanta" &&
                        col.dataIndex !== "material_specification_id"
                    )
              }
              dataSource={dataListFluteVendorItem}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            fields={fields}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="value"
                  label="Value"
                  rules={[
                    {
                      required: true,
                      message: "Please input your value!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    allowClear
                    type={
                      props.location.state.type === "Spec Reference"
                        ? "text"
                        : "number"
                    }
                  />
                </Form.Item>
              </Col>
              {props.location.state.type === "Constanta Amount" && (
                <Col lg={12} md={24}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[
                      {
                        required:
                          props.location.state.type === "Constanta Amount" ??
                          false,
                        message: "Please input your currency!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isLoading}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="select your currency"
                    >
                      {dataListCurrency &&
                        Object.keys(dataListCurrency).map((data, index) => {
                          return (
                            <Option key={index} value={dataListCurrency[data]}>
                              {dataListCurrency[data]}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="value"
                  label="Value"
                  rules={[
                    {
                      required: true,
                      message: "Please input your value!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>
            Do you want to delete this data <b>{dataRow?.characteristic}</b>?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesificationLayerSekatItem;
