import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  FilterOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListDesignAvailability,
  getDataListCompany,
  getDataListDesignAvailability,
  getDataListDesignAvailabilityDownload,
  getDataListDesignAvailabilityTemplateDownload,
  getDataListMaterial,
  getDataListVendor,
  postDataListDesignAvailability,
  postDataListDesignAvailabilityUploadTemplate,
  putDataListDesignAvailability,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterDesign = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [paginationMasterDesign, setPaginationMasterDesign] = useState({
    current: 1,
    pageSize: 10,
  });
  const [state, setState] = useState({
    companyCodeState: "",
    vendorDescState: "",
    vendorCodeState: "",
    ratioState: "",
    vendorTypeState: "",
    materialState: "",
    oldMaterialState: "",
    materialDescState: "",
    oldMaterialDescState: "",
    createdAtState: "",
    updatedAtState: "",
    statusState: "",
  });
  const {
    companyCodeState,
    vendorDescState,
    vendorCodeState,
    ratioState,
    vendorTypeState,
    materialState,
    oldMaterialState,
    materialDescState,
    oldMaterialDescState,
    createdAtState,
    updatedAtState,
    statusState,
  } = state;
  const [dataListVendor, setDataListVendor] = useState([]);
  const [dataListMaterial, setDataListMaterial] = useState([]);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setIsOpenFilter] = useState(false);
  const [dataImportFile, setDataImportFile] = useState(null);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListDesignAvailability, setDataListDesignAvailability] = useState(
    []
  );
  const [dataListStatuses, setDataListStatuses] = useState([]);
  const [fields, setFields] = useState([]);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const fetchDataListDesignAvailability = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListDesignAvailability(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPaginationMasterDesign({
          ...params.pagination,
          total: res.data.items.total,
        });
        setDataListDesignAvailability(res.data.items.data);
        setDataListStatuses(res.data.statuses);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setDataListVendor(res.data.items);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListMaterial = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMaterial(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setDataListMaterial(res.data.items);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListDesignAvailability({ pagination: paginationMasterDesign });
    fetchDataListVendor({});
    fetchDataListMaterial({});
    fetchDataListCompany({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDataListDesignAvailability,
    fetchDataListVendor,
    fetchDataListMaterial,
  ]);

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const onSearchMaterial = (data) => {
    if (data.length === 0) {
      fetchDataListMaterial({});
    } else if (data.length > 3) {
      fetchDataListMaterial({ keyword: data });
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    const res = await postDataListDesignAvailability(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListDesignAvailability({ pagination: paginationMasterDesign });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const uploadTemplateMasterDesign = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("excel", dataImportFile && dataImportFile[0].originFileObj);

    const res = await postDataListDesignAvailabilityUploadTemplate(data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
          onCancel: () => {
            form.resetFields();
          },
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      if (res.data?.url) {
        window.open(res.data.url, "_blank");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        modal.success({
          title: "Success Message!",
          content: <p>Success import data</p>,
          onOk: () => setTimeout(() => window.location.reload(), 1000),
        });
      }
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    const res = await putDataListDesignAvailability(dataRow.id, values).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListDesignAvailability({ pagination: paginationMasterDesign });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListDesignAvailability(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: <p>{err?.response?.data?.message}</p>,
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListDesignAvailability({ pagination: paginationMasterDesign });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "uuid",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text) => <p>{text}</p>,
      width: 90,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => <p>{text}</p>,
      width: 90,
    },
    {
      title: "Vendor Description",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
      width: 250,
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (text) => <p>{text}</p>,
      width: 120,
    },
    {
      title: "Material Description",
      dataIndex: "materialDesc",
      key: "materialDesc",
      render: (text) => <p>{text}</p>,
      width: 310,
    },
    {
      title: "Old Material",
      dataIndex: "oldMaterial",
      key: "oldMaterial",
      render: (text) => <p>{text || ""}</p>,
      width: 130,
    },
    {
      title: "Old Material Description",
      dataIndex: "oldMaterialDesc",
      key: "oldMaterialDesc",
      render: (text) => <p>{text || ""}</p>,
      width: 310,
    },
    {
      title: "Active Design",
      dataIndex: "activeDesign",
      key: "activeDesign",
      render: (text) => <p>{text}</p>,
      width: 100,
    },
    {
      title: "Active PR",
      dataIndex: "activePr",
      key: "activePr",
      render: (text) => <p>{text}</p>,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <p>{text}</p>,
      width: 100,
    },
    {
      title: "Create By",
      dataIndex: "createBy",
      key: "createBy",
      render: (text) => <p>{text}</p>,
      width: 100,
    },
    {
      title: "Change By",
      dataIndex: "changeBy",
      key: "changeBy",
      render: (text) => <p>{text || ""}</p>,
      width: 100,
    },
    {
      title: "Company ID",
      dataIndex: "company_id",
      key: "company_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "material ID",
      dataIndex: "material_id",
      key: "material_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Old Material ID",
      dataIndex: "old_material_id",
      key: "old_material_id",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Vendor ID",
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
      width: 100,
    },
    {
      title: "Update at",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "VIEW",
                })
              }
              type="primary"
              icon={<EyeOutlined />}
            >
              View
            </Button>
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["vendor_id"],
                    value: record.vendor_id,
                  },
                  {
                    name: ["company_id"],
                    value: record.company_id,
                  },
                  {
                    name: ["active_design"],
                    value: record.activeDesign,
                  },
                  {
                    name: ["active_pr"],
                    value: record.activePr,
                  },
                  {
                    name: ["material_id"],
                    value: record.material_id,
                  },
                  {
                    name: ["old_material_id"],
                    value: record.old_material_id,
                  },
                  {
                    name: ["status"],
                    value: record.status,
                  },
                  {
                    name: ["company_id"],
                    value: record.company_id,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
                fetchDataListVendor({ keyword: record.vendor_name });
                fetchDataListMaterial({ keyword: record.material_code });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const downloadTemplateExcel = async () => {
    setIsLoading(true);
    const res = await getDataListDesignAvailabilityTemplateDownload().catch(
      (err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res?.status === 200) {
      setIsLoading(false);
      window.open(res.data.url, "_blank");
    }
  };

  const downloadTable = async () => {
    setIsLoading(true);
    const res = await getDataListDesignAvailabilityDownload().catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200) {
      setIsLoading(false);
      window.open(res.data.url, "_blank");
    }
  };

  const viewDataTable = async (value) => {
    setFilter({
      vendorCode: value.vendorCode,
      vendorDesc: value.vendorDesc,
      materialDesc: value.materialDesc,
      oldMaterialDesc: value.oldMaterialDesc,
      activeDesign: value.activeDesign,
      activePr: value.activePr,
      status: value.status,
      material: value.material,
      oldMaterial: value.oldMaterial,
      created_at: value.created_at
        ? moment(value.created_at).format("DD.MM.YYYY")
        : "",
      updated_at: value.updated_at
        ? moment(value.updated_at).format("DD.MM.YYYY")
        : "",
      companyCode: value.companyCode,
    });
    fetchDataListDesignAvailability({
      vendorCode: value.vendorCode,
      vendorDesc: value.vendorDesc,
      materialDesc: value.materialDesc,
      oldMaterialDesc: value.oldMaterialDesc,
      activeDesign: value.activeDesign,
      activePr: value.activePr,
      status: value.status,
      material: value.material,
      oldMaterial: value.oldMaterial,
      created_at: value.created_at
        ? moment(value.created_at).format("DD.MM.YYYY")
        : "",
      updated_at: value.updated_at
        ? moment(value.updated_at).format("DD.MM.YYYY")
        : "",
      companyCode: value.companyCode,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const handleTableChangeMasterDesign = (pagination, filters, sorter) => {
    fetchDataListDesignAvailability({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const resetTable = useCallback(() => {
    setDataListDesignAvailability([]);
  }, []);

  useEffect(() => {
    if (
      companyCodeState ||
      vendorDescState ||
      vendorCodeState ||
      ratioState ||
      vendorTypeState ||
      materialState ||
      oldMaterialState ||
      materialDescState ||
      oldMaterialDescState ||
      createdAtState ||
      updatedAtState ||
      statusState
    ) {
      resetTable();
    }
  }, [
    resetTable,
    companyCodeState,
    vendorDescState,
    vendorCodeState,
    ratioState,
    vendorTypeState,
    materialState,
    oldMaterialState,
    materialDescState,
    oldMaterialDescState,
    createdAtState,
    updatedAtState,
    statusState,
  ]);

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Design Availability</Title>

      <div className="site-layout-background" style={{ padding: 24 }}>
        {openFilter && (
          <Form scrollToFirstError onFinish={viewDataTable}>
            <Row>
              <Space size="small">
                <Col>
                  <Form.Item
                    name="vendorDesc"
                    label="Vendor Desc"
                    rules={[
                      {
                        required: false,
                        message: "Please input your vendor desc.!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      style={{ width: 290 }}
                      showSearch
                      onChange={(e) => handleChange("vendorDescState", e)}
                      loading={isLoading}
                      onFocus={() => fetchDataListVendor({})}
                      onSearch={onSearchVendorItem}
                      optionFilterProp="children"
                      placeholder="select your vendor desc."
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListVendor &&
                        dataListVendor.map((data, index) => {
                          const { name } = data;
                          return (
                            <Option key={index} value={name}>
                              {name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="material"
                    label="Material"
                    rules={[
                      {
                        required: false,
                        message: "Please input your material!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      onChange={(e) => handleChange("materialState", e)}
                      style={{ width: 150 }}
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListMaterial({})}
                      onSearch={onSearchMaterial}
                      optionFilterProp="children"
                      placeholder="select your material"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMaterial &&
                        dataListMaterial.map((data, index) => {
                          const { material_code } = data;
                          return (
                            <Option key={index} value={material_code}>
                              {material_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="oldMaterial"
                    label="Old Material"
                    rules={[
                      {
                        required: false,
                        message: "Please input your old material!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      style={{ width: 150 }}
                      showSearch
                      onChange={(e) => handleChange("oldMaterialState", e)}
                      loading={isLoading}
                      onFocus={() => fetchDataListMaterial({})}
                      onSearch={onSearchMaterial}
                      optionFilterProp="children"
                      placeholder="select your old material"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMaterial &&
                        dataListMaterial.map((data, index) => {
                          const { material_code } = data;
                          return (
                            <Option key={index} value={material_code}>
                              {material_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>

            <Row>
              <Space size="small">
                <Col>
                  <Form.Item
                    name="materialDesc"
                    label="Material Desc."
                    rules={[
                      {
                        required: false,
                        message: "Please input your material desc.!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      onChange={(e) => handleChange("materialDescState", e)}
                      allowClear
                      style={{ width: 400 }}
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListMaterial({})}
                      onSearch={onSearchMaterial}
                      optionFilterProp="children"
                      placeholder="select your material desc."
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMaterial &&
                        dataListMaterial.map((data, index) => {
                          const { description } = data;
                          return (
                            <Option key={index} value={description}>
                              {description}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="oldMaterialDesc"
                    label="Old Material Desc."
                    rules={[
                      {
                        required: false,
                        message: "Please input your old material desc.!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      onChange={(e) => handleChange("oldMaterialDescState", e)}
                      allowClear
                      style={{ width: 400 }}
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListMaterial({})}
                      onSearch={onSearchMaterial}
                      optionFilterProp="children"
                      placeholder="select your old material desc."
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMaterial &&
                        dataListMaterial.map((data, index) => {
                          const { description } = data;
                          return (
                            <Option key={index} value={description}>
                              {description}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>

            <Row>
              <Space>
                <Col>
                  <Form.Item
                    name="created_at"
                    label="Created At"
                    rules={[
                      {
                        required: false,
                        message: "Please input your created at!",
                      },
                    ]}
                    hasFeedback
                  >
                    <DatePicker
                      onChange={(e) => handleChange("createdAtState", e)}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="updated_at"
                    label="Updated At"
                    rules={[
                      {
                        required: false,
                        message: "Please input your updated at!",
                      },
                    ]}
                    hasFeedback
                  >
                    <DatePicker
                      onChange={(e) => handleChange("updatedAtState", e)}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="vendorCode"
                    label="Vendor Code"
                    rules={[
                      {
                        required: false,
                        message: "Please input vendor code!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      onChange={(e) => handleChange("vendorCodeState", e)}
                      style={{ width: 110 }}
                      showSearch
                      loading={isLoading}
                      onFocus={() => fetchDataListVendor({})}
                      onSearch={onSearchVendorItem}
                      optionFilterProp="children"
                      placeholder="select"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListVendor &&
                        dataListVendor.map((data, index) => {
                          const { vendor_code } = data;
                          return (
                            <Option key={index} value={vendor_code}>
                              {vendor_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="companyCode"
                    label="Company"
                    rules={[
                      {
                        required: false,
                        message: "Please input your company!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      loading={isLoading}
                      onSearch={onSearchCompany}
                      onChange={(e) => handleChange("companyCodeState", e)}
                      onFocus={() => fetchDataListCompany({})}
                      placeholder="Input min. 3 char"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListCompany &&
                        dataListCompany.map((data, index) => {
                          const { company_code } = data;
                          return (
                            <Option key={index} value={company_code}>
                              {company_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>

            <Row>
              <Space>
                <Col>
                  <Form.Item
                    name="activePr"
                    label="Active PR"
                    rules={[
                      {
                        required: false,
                        message: "Please input your active PR!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Radio.Group>
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="activeDesign"
                    label="Active Design"
                    rules={[
                      {
                        required: false,
                        message: "Please input your active design!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Radio.Group>
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                      {
                        required: false,
                        message: "Please input your status!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      allowClear
                      style={{ width: 130 }}
                      onChange={(e) => handleChange("statusState", e)}
                      optionFilterProp="children"
                      placeholder="input min. 4 char"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListStatuses &&
                        dataListStatuses.map((data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {data}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
              <Col style={{ marginLeft: MarginEnum["2x"] }}>
                <Button
                  loading={isLoading}
                  style={{
                    backgroundColor: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  type="primary"
                  htmlType="submit"
                  icon={<EyeOutlined />}
                >
                  View
                </Button>
              </Col>
              <Col style={{ marginLeft: MarginEnum["2x"] }}>
                <Button
                  loading={isLoading}
                  style={{
                    color: ColorPrimaryEnum.redVelvet,
                    border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                  }}
                  onClick={() => setIsOpenFilter(false)}
                  icon={<FilterOutlined />}
                >
                  Close Filter
                </Button>
              </Col>
            </Row>
          </Form>
        )}

        <Row
          justify="end"
          style={{
            marginBottom: 12,
          }}
        >
          <Space>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  typeAction: "ADD",
                  isShowModalAction: true,
                })
              }
              style={{
                backgroundColor: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              type="primary"
              icon={<FileAddOutlined />}
            >
              Add
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  typeAction: "UPLOAD",
                  isShowModalAction: true,
                })
              }
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              icon={<CloudUploadOutlined />}
            >
              Upload
            </Button>
            <Button
              onClick={downloadTable}
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              loading={isLoading}
              icon={<CloudDownloadOutlined />}
            >
              Download
            </Button>
            <Button
              onClick={() => setIsOpenFilter(true)}
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              loading={isLoading}
              icon={<FilterOutlined />}
            >
              Open Filter
            </Button>
          </Space>
        </Row>
        <Table
          loading={isLoading}
          pagination={paginationMasterDesign}
          onChange={handleTableChangeMasterDesign}
          bordered
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable.filter(
            (col) =>
              col.dataIndex !== "uuid" &&
              col.dataIndex !== "id" &&
              col.dataIndex !== "material_id" &&
              col.dataIndex !== "old_material_id" &&
              col.dataIndex !== "vendor_id" &&
              col.dataIndex !== "company_id"
          )}
          dataSource={dataListDesignAvailability}
          scroll={{ x: 2400, y: 500 }}
          size="small"
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          if (typeAction === "UPLOAD" && dataImportFile) {
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            form.resetFields();
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {data === "created_at" || data === "updated_at"
                    ? moment(dataRow[data]).format("DD-MM-YYYY")
                    : dataRow[data]}
                </span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fields}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="material_id"
                  label="Material"
                  rules={[
                    {
                      required: true,
                      message: "Please input material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListMaterial({})}
                    onSearch={onSearchMaterial}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMaterial &&
                      dataListMaterial.map((data, index) => {
                        const { description, material_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${material_code} - ${description}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="active_design"
                  label="Active Design"
                  rules={[
                    {
                      required: true,
                      message: "Please input your active design!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="old_material_id"
                  label="Old Material"
                  rules={[
                    {
                      required: false,
                      message: "Please input old material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListMaterial({})}
                    onSearch={onSearchMaterial}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMaterial &&
                      dataListMaterial.map((data, index) => {
                        const { description, material_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${material_code} - ${description}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="active_pr"
                  label="Active PR"
                  rules={[
                    {
                      required: true,
                      message: "Please input your active PR!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Please input your status!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListStatuses &&
                      dataListStatuses.map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="material_id"
                  label="Material"
                  rules={[
                    {
                      required: true,
                      message: "Please input material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListMaterial({})}
                    onSearch={onSearchMaterial}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMaterial &&
                      dataListMaterial.map((data, index) => {
                        const { description, material_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${material_code} - ${description}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="active_design"
                  label="Active Design"
                  rules={[
                    {
                      required: true,
                      message: "Please input your active design!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="old_material_id"
                  label="Old Material"
                  rules={[
                    {
                      required: false,
                      message: "Please input old material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListMaterial({})}
                    onSearch={onSearchMaterial}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMaterial &&
                      dataListMaterial.map((data, index) => {
                        const { description, material_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${material_code} - ${description}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="active_pr"
                  label="Active PR"
                  rules={[
                    {
                      required: true,
                      message: "Please input your active PR!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Please input your status!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListStatuses &&
                      dataListStatuses.map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "UPLOAD" ? (
          <>
            <Form.Item>
              <Form.Item
                name="uploadFileMdDesign"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Button
              block
              onClick={uploadTemplateMasterDesign}
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                marginBottom: MarginEnum["2x"],
              }}
              type="primary"
              loading={isLoading}
              disabled={dataImportFile ? false : true}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
            <Button
              block
              onClick={downloadTemplateExcel}
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              type="primary"
              loading={isLoading}
              icon={<FileAddOutlined />}
            >
              Download Template
            </Button>
          </>
        ) : (
          <p>Apakah anda ingin menghapus data ini ?</p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterDesign;
