import {
  FileAddOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Form, Layout, Typography, Upload, Modal } from "antd";
import React, { useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataTemplateBudgetDownload,
  postDataListBudgetUploadTemplate,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Title } = Typography;

const UploadBudget = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataImportFile, setDataImportFile] = useState(null);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const downloadTemplateExcel = async () => {
    setIsLoading(true);
    let data = {
      type: "BUDGET",
    };
    const res = await getDataTemplateBudgetDownload(data).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200) {
      if (res.data?.url) {
        window.open(res.data.url, "_blank");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        modal.success({
          title: "Success Message!",
          content: <p>Success import data</p>,
          onOk: () => setTimeout(() => window.location.reload(), 1000),
        });
      }
    }
  };

  const uploadTemplate = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("excel", dataImportFile && dataImportFile[0].originFileObj);
    data.append("type", "BUDGET");

    const res = await postDataListBudgetUploadTemplate(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      if (res.data?.url) {
        window.open(res.data.url, "_blank");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        modal.success({
          title: "Success Message!",
          content: <p>Success import data</p>,
          onOk: () => setTimeout(() => window.location.reload(), 1000),
        });
      }
    }
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Upload Budget</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form onFinish={uploadTemplate} scrollToFirstError form={form}>
          <Form.Item>
            <Form.Item
              name="uploadFileRFQ"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger name="files">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              disabled={dataImportFile ? false : true}
              loading={isLoading}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
          </Form.Item>
        </Form>

        <Button
          block
          onClick={downloadTemplateExcel}
          style={{
            backgroundColor: ColorBaseEnum.white,
            color: ColorPrimaryEnum.greenTosca,
            border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
          }}
          type="primary"
          loading={isLoading}
          icon={<FileAddOutlined />}
        >
          Download Template
        </Button>
      </div>
      {contextHolder}
    </Content>
  );
};

export default UploadBudget;
