import { Form, Input, Layout, Modal, Row, Table, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import { getDataListTableCompany } from "../services/retrieveData";

const { Content } = Layout;
const { Title } = Typography;

const ConfigCompany = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [dataListCompany, setDataListCompany] = useState([]);
  const [paginationCompany, setPaginationCompany] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Compamy Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Postal Code",
      dataIndex: "postal_code",
      key: "postal_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      key: "country_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
  ];

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListTableCompany(params).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationCompany({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListCompany(res.data.items);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCompany({ pagination: paginationCompany });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (data) => {
    if (data.length > 2) {
      fetchDataListCompany({
        search: data,
      });
    } else {
      fetchDataListCompany({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    }
  };

  const handleTableChangeCompany = (pagination, filters, sorter) => {
    fetchDataListCompany({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Company</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row
          style={{
            marginBottom: 12,
          }}
        >
          <Input.Search
            onSearch={onSearch}
            allowClear
            placeholder="Input min. 3 char"
            style={{ width: "40%" }}
          />
        </Row>
        <Table
          scroll={{ x: 1500 }}
          loading={isLoading}
          onChange={handleTableChangeCompany}
          pagination={paginationCompany}
          bordered
          size="small"
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable}
          dataSource={dataListCompany}
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        afterClose={() => form.resetFields()}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
      >
        {typeAction === "VIEW"
          ? dataRow &&
            Object.keys(dataRow).map((data, index) => {
              if (data === "key") {
                return null;
              }
              return (
                <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                  {data.replace("_", " ")} :{" "}
                  <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
                </p>
              );
            })
          : null}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default ConfigCompany;
