import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileAddOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const ApprovalRFQ = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    modal.confirm("add");
    setIsLoading(false);
  };

  const onEditSupplier = async (values) => {
    setIsLoading(true);
    modal.confirm("udpate");
    setIsLoading(false);
  };

  const onDeleteSupplier = async (values) => {
    window.alert("Success delete supplier");
    setIsModalActionVisible({
      ...isModalActionVisible,
      isShowModalAction: false,
    });
    window.location.reload();
  };

  const dataTable = [
    {
      key: 1,
      vendor: "200012",
      vendorDesc: "Century MMS PT",
      layerOne: "PET12",
      layerTwo: "KZMB",
      layerThree: "CPP25",
      colorMin: "1",
      colorMax: "3",
      allPlant: "Yes",
      plant: "",
      validFrom: "01-04-2021",
      validTo: "01-08-2021",
      price: "1.200",
      currency: "",
      per: "1",
      unit: "1",
      submitDate: "01-04-2021",
    },
  ];

  const columnsTable = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Description",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 1",
      dataIndex: "layerOne",
      key: "layerOne",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 2",
      dataIndex: "layerTwo",
      key: "layerTwo",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 3",
      dataIndex: "layerThree",
      key: "layerThree",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Color Min",
      dataIndex: "colorMin",
      key: "colorMin",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Color Max",
      dataIndex: "colorMax",
      key: "colorMax",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "All Plant",
      dataIndex: "allPlant",
      key: "allPlant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Per",
      dataIndex: "per",
      key: "per",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Submit Date",
      dataIndex: "submitDate",
      key: "submitDate",
      render: (text) => <p>{text}</p>,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>RFQ Approval</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row
          justify="end"
          style={{
            marginBottom: MarginEnum["2x"],
          }}
        >
          <Space>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  typeAction: "ADD",
                  isShowModalAction: true,
                })
              }
              style={{
                backgroundColor: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              type="primary"
              icon={<CloseCircleOutlined />}
            >
              Reject
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  typeAction: "UPLOAD",
                  isShowModalAction: true,
                })
              }
              style={{
                backgroundColor: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
              }}
              type="primary"
              icon={<CheckCircleOutlined />}
            >
              Approve
            </Button>
          </Space>
        </Row>

        <Row
          style={{
            marginBottom: MarginEnum["2x"],
          }}
        >
          <Space>
            <Space direction="vertical">
              <Text>Material Group</Text>
              <Select
                allowClear
                showSearch
                style={{ width: 200 }}
                placeholder="Select"
                optionFilterProp="children"
                onChange={(e) => console.log(`onChange company ${e}`)}
                onFocus={(e) => console.log(`onFocus company ${e}`)}
                onBlur={(e) => console.log(`onBlur company ${e}`)}
                onSearch={(e) => console.log(`onSearch company ${e}`)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option>
              </Select>
            </Space>
            <Space direction="vertical">
              <Text>Vendor</Text>
              <Select
                allowClear
                showSearch
                style={{ width: 200 }}
                placeholder="Select"
                optionFilterProp="children"
                onChange={(e) => console.log(`onChange vendor ${e}`)}
                onFocus={(e) => console.log(`onFocus vendor ${e}`)}
                onBlur={(e) => console.log(`onBlur vendor ${e}`)}
                onSearch={(e) => console.log(`onSearch vendor ${e}`)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option>
              </Select>
            </Space>
            <Space direction="vertical">
              <Text>Valid From</Text>
              <DatePicker inputReadOnly={true} />
            </Space>
            <Space direction="vertical">
              <Text>Valid To</Text>
              <DatePicker inputReadOnly={true} />
            </Space>
            <Space direction="vertical">
              <Text style={{ color: ColorBaseEnum.white }}>text</Text>
              <Button
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Space>
          </Space>
        </Row>
        <Table
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          bordered
          columns={columnsTable}
          dataSource={dataTable}
          scroll={{ x: 1500 }}
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onOk={() => {
          if (typeAction === "DELETE") {
            onDeleteSupplier(dataRow.guid);
          } else {
            form.resetFields();
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (
              data === "key" ||
              data === "updated_at" ||
              data === "created_at"
            ) {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="addSupplier"
            onFinish={onEditSupplier}
            fields={[
              {
                name: ["company"],
                value: dataRow.company,
              },
              {
                name: ["vendor"],
                value: dataRow.vendor,
              },
              {
                name: ["vendorDesc"],
                value: dataRow.vendorDesc,
              },
              {
                name: ["materialNo"],
                value: dataRow.materialNo,
              },
              {
                name: ["materialDesc"],
                value: dataRow.materialDesc,
              },
              {
                name: ["ratio"],
                value: dataRow.ratio,
              },
              {
                name: ["status"],
                value: dataRow.status,
              },
            ]}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange company ${e}`)}
                    onFocus={(e) => console.log(`onFocus company ${e}`)}
                    onBlur={(e) => console.log(`onBlur company ${e}`)}
                    onSearch={(e) => console.log(`onSearch company ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange vendor ${e}`)}
                    onFocus={(e) => console.log(`onFocus vendor ${e}`)}
                    onBlur={(e) => console.log(`onBlur vendor ${e}`)}
                    onSearch={(e) => console.log(`onSearch vendor ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialNo"
                  label="Material"
                  rules={[
                    {
                      required: true,
                      message: "Please input material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange material ${e}`)}
                    onFocus={(e) => console.log(`onFocus material ${e}`)}
                    onBlur={(e) => console.log(`onBlur material ${e}`)}
                    onSearch={(e) => console.log(`onSearch material ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="ratio"
                  label="Ratio"
                  rules={[
                    {
                      required: true,
                      message: "Please input ratio!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              {contextHolder}
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="addSupplier"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange company ${e}`)}
                    onFocus={(e) => console.log(`onFocus company ${e}`)}
                    onBlur={(e) => console.log(`onBlur company ${e}`)}
                    onSearch={(e) => console.log(`onSearch company ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange vendor ${e}`)}
                    onFocus={(e) => console.log(`onFocus vendor ${e}`)}
                    onBlur={(e) => console.log(`onBlur vendor ${e}`)}
                    onSearch={(e) => console.log(`onSearch vendor ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialNo"
                  label="Material"
                  rules={[
                    {
                      required: true,
                      message: "Please input material!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(e) => console.log(`onChange material ${e}`)}
                    onFocus={(e) => console.log(`onFocus material ${e}`)}
                    onBlur={(e) => console.log(`onBlur material ${e}`)}
                    onSearch={(e) => console.log(`onSearch material ${e}`)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="ratio"
                  label="Ratio"
                  rules={[
                    {
                      required: true,
                      message: "Please input ratio!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
              {contextHolder}
            </div>
          </Form>
        ) : typeAction === "UPLOAD" ? (
          <>
            <Form.Item>
              <Form.Item
                name="uploadFileMdDesign"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Button
              block
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                marginBottom: MarginEnum["2x"],
              }}
              type="primary"
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
            <Button
              block
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.greenTosca,
                border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
              }}
              type="primary"
              icon={<FileAddOutlined />}
            >
              Download Template
            </Button>
          </>
        ) : (
          <p>Apakah anda ingin menghapus data ini ?</p>
        )}
      </Modal>
    </Content>
  );
};

export default ApprovalRFQ;
