import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListMasterMaterialGroup,
  getDataListPriceRankingIndex,
  getDataListVendor,
  getPriceRankingExport,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const PriceAndRanking = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [paginationPriceAndRanking, setPaginationPriceAndRanking] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataListVendor, setDataListVendor] = useState([]);
  const [dataListPriceAndRangking, setDataListPriceAndRangking] = useState([]);
  const [state, setState] = useState({
    materialGroup: "",
    validOn: "",
    companyCode: "",
    vendorCode: "",
    type: "mrp",
  });
  const { validOn, materialGroup, vendorCode, companyCode, type } = state;
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterSpec(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVendor(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMasterMaterialGroup({});
    fetchDataListVendor({});
    fetchDataListCompany({});
  }, [
    fetchDataListMasterMaterialGroup,
    fetchDataListVendor,
    fetchDataListCompany,
  ]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find(
      (data) =>
        data !== "vendorCode" && data !== "materialGroup" && state[data] === ""
    );

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        materialGroup: materialGroup,
        companyCode: companyCode,
        type: type,
        vendorCode: vendorCode,
        validOn: moment(validOn).format("MM.YYYY"),
      };

      const res = await getPriceRankingExport(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "keyname",
      key: "keyname",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Company ID",
      dataIndex: "company_id",
      key: "company_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Rank Price",
      dataIndex: "ranking_price",
      key: "ranking_price",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Desc.",
      dataIndex: "description",
      key: "description",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Dimensi",
      dataIndex: "dimensi",
      key: "dimensi",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Keyname",
      dataIndex: "keyname",
      key: "keyname",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Lifnr",
      dataIndex: "lifnr",
      key: "lifnr",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Kbetr",
      dataIndex: "kbetr",
      key: "kbetr",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Material Group Label",
      dataIndex: "material_group_label",
      key: "material_group_label",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Matnr",
      dataIndex: "matnr",
      key: "matnr",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Bahan",
      dataIndex: "bahan",
      key: "bahan",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Ranking Perf.",
      dataIndex: "ranking_performance",
      key: "ranking_performance",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Ranking",
      dataIndex: "ranking",
      key: "ranking",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Score Total",
      dataIndex: "score_total",
      key: "score_total",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Valid To",
      dataIndex: "valid_to",
      key: "valid_to",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Vendor Perf.",
      dataIndex: "vendor_performance",
      key: "vendor_performance",
      render: (text) => <p>{text || ""}</p>,
    },
  ];

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const fetchDataListPriceAndRanking = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListPriceRankingIndex(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationPriceAndRanking({
        ...params.pagination,
        total: res.data.items.total,
      });
      setIsLoading(false);
      setDataListPriceAndRangking(res?.data?.items?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDataTable = (value) => {
    setFilter({
      materialGroup: value.materialGroup,
      companyCode: value.companyCode,
      type: value.type,
      vendorCode: value.vendorCode,
      validOn: moment(value.validOn).format("MM.YYYY"),
    });

    fetchDataListPriceAndRanking({
      materialGroup: value.materialGroup,
      companyCode: value.companyCode,
      type: value.type,
      vendorCode: value.vendorCode,
      validOn: moment(value.validOn).format("MM.YYYY"),
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const handleTableChangePriceAndRanking = (pagination, filters, sorter) => {
    fetchDataListPriceAndRanking({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const resetTable = useCallback(() => {
    setDataListPriceAndRangking([]);
  }, []);

  useEffect(() => {
    if (materialGroup || vendorCode || companyCode || type || validOn) {
      resetTable();
    }
  }, [resetTable, materialGroup, vendorCode, companyCode, type, validOn]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Price and Ranking</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row>
            <Space size="small">
              <Col>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    onChange={(e) => handleChange("materialGroup", e)}
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="vendorCode"
                  label="Vendor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    onChange={(e) => handleChange("vendorCode", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code } = data;
                        return (
                          <Option key={index} value={vendor_code}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("companyCode", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    onChange={(e) => handleChange("type", e)}
                    optionFilterProp="children"
                    placeholder="select your type"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={1} value={"mrp"}>
                      MRP
                    </Option>
                    <Option key={2} value={"pr"}>
                      PR
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row>
            <Space size="small">
              <Col>
                <Form.Item
                  name="validOn"
                  label="Valid On"
                  rules={[
                    {
                      required: true,
                      message: "Please input your valid on!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("validOn", e)}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          pagination={paginationPriceAndRanking}
          onChange={handleTableChangePriceAndRanking}
          bordered
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          columns={columnsTable.filter((col) => col.dataIndex !== "company_id")}
          dataSource={dataListPriceAndRangking}
          size="small"
          scroll={{ x: 2400 }}
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default PriceAndRanking;
