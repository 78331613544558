import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListMasterSpec,
  getDataListRfq,
  getDataListRFQReportDownload,
  getDataListVendor,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const RFQReport = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [paginationMasterSpec, setPaginationMasterSpec] = useState({
    current: 1,
    pageSize: 15,
  });
  const [paginationRfqReport, setPaginationRfqReport] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataListVendor, setDataListVendor] = useState([]);
  const [dataListRfq, setDataListRfq] = useState([]);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [state, setState] = useState({
    type: "",
    materialGroup: "",
    vendorCode: "",
    validOn: "",
  });
  const { type, materialGroup, vendorCode, validOn } = state;

  const fetchDataListMasterSpec = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListMasterSpec(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationMasterSpec({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListMasterSpec(res.data.items.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVendor(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMasterSpec({ pagination: paginationMasterSpec });
    fetchDataListVendor({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListMasterSpec, fetchDataListVendor]);

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendor_description",
      key: "vendor_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 1",
      dataIndex: "layer1",
      key: "layer1",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 2",
      dataIndex: "layer2",
      key: "layer2",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Layer 3",
      dataIndex: "layer3",
      key: "layer3",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Color",
      dataIndex: "zroll_clr",
      key: "zroll_clr",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Bahan 1",
      dataIndex: "bahan1",
      key: "bahan1",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Bahan 2",
      dataIndex: "bahan2",
      key: "bahan2",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Bahan 3",
      dataIndex: "bahan3",
      key: "bahan3",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Bahan 4",
      dataIndex: "bahan4",
      key: "bahan4",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Bahan 5",
      dataIndex: "bahan5",
      key: "bahan5",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Flute",
      dataIndex: "flute",
      key: "flute",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material No",
      dataIndex: "material_no",
      key: "material_no",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "material_description",
      key: "material_description",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "All Plant",
      dataIndex: "all_plant",
      key: "all_plant",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Valid To",
      dataIndex: "valid_to",
      key: "valid_to",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Per",
      dataIndex: "per",
      key: "per",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Pir Category",
      dataIndex: "pir_category",
      key: "pir_category",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Vat Code",
      dataIndex: "vat_code",
      key: "vat_code",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Purch Org",
      dataIndex: "purch_org",
      key: "purch_org",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Purch Grp",
      dataIndex: "purch_grp",
      key: "purch_grp",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Base Uom",
      dataIndex: "base_uom",
      key: "base_uom",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Submit Date",
      dataIndex: "submit_date",
      key: "submit_date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      render: (text) => <p>{text}</p>,
    },
  ];

  const fetchDataListRfqReport = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListRfq(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res.status === 201) {
      setPaginationRfqReport({
        ...params.pagination,
        total: res.data.items.total,
      });
      const mapData = res?.data?.items?.data.map((data) => ({
        id: data.id,
        vendor_code: data.vendor_code,
        vendor_description: data.vendor_description,
        material_no: data.material_no,
        material_description: data.material_description,
        all_plant: data.all_plant,
        plant: data.plant,
        valid_from: data.valid_from,
        valid_to: data.valid_to,
        price: data.price,
        currency: data.currency,
        per: data.per,
        unit: data.unit,
        pir_category: data.pir_category,
        vat_code: data.vat_code,
        purch_org: data.purch_org,
        purch_grp: data.purch_grp,
        base_uom: data.base_uom,
        submit_date: data.submit_date,
        layer1: data?.items["Layer 1"] && data?.items["Layer 1"].value,
        layer2: data?.items["Layer 2"] && data?.items["Layer 2"].value,
        layer3: data?.items["Layer 3"] && data?.items["Layer 3"].value,
        zroll_clr:
          data?.items["ZROLL_CLR"] && data?.items["ZROLL_CLR"].value_to,
        bahan1: data?.items["Bahan 1"] && data?.items["Bahan 1"].value,
        bahan2: data?.items["Bahan 2"] && data?.items["Bahan 2"].value,
        bahan3: data?.items["Bahan 3"] && data?.items["Bahan 3"].value,
        bahan4: data?.items["Bahan 4"] && data?.items["Bahan 4"].value,
        bahan5: data?.items["Bahan 5"] && data?.items["Bahan 5"].value,
        flute: data?.items["Flute"] && data?.items["Flute"].value,
      }));
      setDataListRfq(mapData);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDataTable = (value) => {
    setFilter({
      type: value.type,
      materialGroup: value.materialGroup,
      vendorCode: value.vendorCode,
      validOn: moment(value.validOn).format("DD.MM.YYYY"),
    });
    fetchDataListRfqReport({
      type: value.type,
      materialGroup: value.materialGroup,
      vendorCode: value.vendorCode,
      validOn: moment(value.validOn).format("DD.MM.YYYY"),
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find((data) =>
      type === "SKU"
        ? data !== "materialGroup" &&
          data !== "vendorCode" &&
          state[data] === ""
        : data !== "vendorCode" && state[data] === ""
    );

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        type: type,
        materialGroup: materialGroup,
        vendorCode: vendorCode,
        validOn: moment(validOn).format("DD.MM.YYYY"),
      };
      const res = await getDataListRFQReportDownload(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleTableChangeRfqReport = (pagination, filters, sorter) => {
    fetchDataListRfqReport({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const resetTable = useCallback(() => {
    setDataListRfq([]);
  }, []);

  useEffect(() => {
    if (type || vendorCode || validOn) {
      resetTable();
    }
  }, [resetTable, type, vendorCode, validOn]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>RFQ Report</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input type!",
                    },
                  ]}
                  name="type"
                  label="Type:"
                >
                  <Radio.Group
                    name="type"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  >
                    <Radio value="SKU">SKU</Radio>
                    <Radio value="SPEC">Specification</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {type === "SPEC" && (
                <Col>
                  <Form.Item
                    name="materialGroup"
                    label="Material Group"
                    rules={[
                      {
                        required: type === "SPEC" ?? false,
                        message: "Please input material group!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      showSearch
                      onChange={(e) => handleChange("materialGroup", e)}
                      loading={isLoading}
                      placeholder="Input min. 4 char"
                      onFocus={() =>
                        fetchDataListMasterSpec({
                          pagination: paginationMasterSpec,
                        })
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMasterSpec &&
                        dataListMasterSpec.map((data, index) => {
                          const { material_group_description, material_group } =
                            data;
                          return (
                            <Option key={index} value={material_group}>
                              {`${material_group} - ${material_group_description}`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col>
                <Form.Item
                  name="vendorCode"
                  label="Vendor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    onChange={(e) => handleChange("vendorCode", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code } = data;
                        return (
                          <Option key={index} value={vendor_code}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>

          <Row
            style={{
              marginBottom: MarginEnum["2x"],
            }}
          >
            <Space size="large">
              <Col>
                <Form.Item
                  name="validOn"
                  label="Valid On"
                  rules={[
                    {
                      required: true,
                      message: "Please input your valid on!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    inputReadOnly={true}
                    onChange={(e) => handleChange("validOn", e)}
                  />
                </Form.Item>
              </Col>
            </Space>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          pagination={paginationRfqReport}
          onChange={handleTableChangeRfqReport}
          loading={isLoading}
          bordered
          columns={columnsTable.filter((col) =>
            type === "SPEC" && materialGroup === "01"
              ? col.dataIndex !== "material_no" &&
                col.dataIndex !== "material_description" &&
                col.dataIndex !== "bahan1" &&
                col.dataIndex !== "bahan2" &&
                col.dataIndex !== "bahan3" &&
                col.dataIndex !== "bahan4" &&
                col.dataIndex !== "bahan5" &&
                col.dataIndex !== "flute"
              : type === "SPEC" && materialGroup === "12"
              ? col.dataIndex !== "material_no" &&
                col.dataIndex !== "material_description" &&
                col.dataIndex !== "layer1" &&
                col.dataIndex !== "layer2" &&
                col.dataIndex !== "layer3" &&
                col.dataIndex !== "zroll_clr"
              : col.dataIndex !== "layer1" &&
                col.dataIndex !== "layer2" &&
                col.dataIndex !== "layer3" &&
                col.dataIndex !== "zroll_clr" &&
                col.dataIndex !== "bahan1" &&
                col.dataIndex !== "bahan2" &&
                col.dataIndex !== "bahan3" &&
                col.dataIndex !== "bahan4" &&
                col.dataIndex !== "bahan5" &&
                col.dataIndex !== "flute"
          )}
          dataSource={dataListRfq}
          scroll={type === "SPEC" ? { x: 2000 } : { x: 1800 }}
          size="small"
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default RFQReport;
