import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListMasterMinimumOrderQty,
  getDataListCompany,
  getDataListMasterMaterialGroup,
  getDataListMasterMinimumOrderQty,
  postDataListMasterMinimumOrderQty,
  putDataListMasterMinimumOrderQty,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterMinimumOrderQty = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [dataListMasterMatGroup, setDataListMasterMatGroup] = useState([]);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataMoq, setDataMoq] = useState([]);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;
  const [fields, setFields] = useState([]);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterMatGroup(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListMinimumOrderQty = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListMasterMinimumOrderQty(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res?.status === 200 || res?.status === 201) {
      setPagination({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataMoq(res.data.items.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMinimumOrderQty({ pagination: pagination });
    fetchDataListCompany({});
    fetchDataListMasterMaterialGroup({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDataListMinimumOrderQty,
    fetchDataListCompany,
    fetchDataListMasterMaterialGroup,
  ]);

  const onFinish = async (values) => {
    setIsLoading(true);
    let data = {
      companyCode: values.companyCode,
      materialGroup: values.materialGroup,
      moq: values.moq,
      moq_kirim: values.moq_kirim,
    };

    const res = await postDataListMasterMinimumOrderQty(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
      });
      setIsLoading(false);
      form.resetFields();
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListMinimumOrderQty({ pagination: pagination });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      companyCode: values.companyCode,
      materialGroup: values.materialGroup,
      moq: values.moq,
      moq_kirim: values.moq_kirim,
    };

    const res = await putDataListMasterMinimumOrderQty(dataRow.id, data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMinimumOrderQty({ pagination: pagination });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterMinimumOrderQty(values).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListMinimumOrderQty({ pagination: pagination });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  let columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "materialGroup",
      key: "materialGroup",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc.",
      dataIndex: "materialGroupDescription",
      key: "materialGroupDescription",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      key: "moq",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "MOQ Kirim",
      dataIndex: "moq_kirim",
      key: "moq_kirim",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["moq"],
                    value: record.moq,
                  },
                  {
                    name: ["moq_kirim"],
                    value: record.moq_kirim,
                  },
                  {
                    name: ["companyCode"],
                    value: record.company_code,
                  },
                  {
                    name: ["materialGroup"],
                    value: record.materialGroup,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const onSearch = (data) => {
    if (data.length > 1) {
      fetchDataListMinimumOrderQty({
        search: data,
      });
    } else {
      fetchDataListMinimumOrderQty({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    }
  };

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListMinimumOrderQty({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Minimum Order Quantity</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row
          justify="space-between"
          style={{
            marginBottom: 12,
          }}
        >
          <Button
            onClick={() =>
              setIsModalActionVisible({
                ...isModalActionVisible,
                typeAction: "ADD",
                isShowModalAction: true,
              })
            }
            style={{
              backgroundColor: ColorPrimaryEnum.greenTosca,
              border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
            }}
            type="primary"
            icon={<FileAddOutlined />}
          >
            Add
          </Button>
          <Input.Search
            onSearch={onSearch}
            placeholder="Input min. 2 char"
            allowClear
            style={{ width: "40%" }}
          />
        </Row>
        <Table
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
          loading={isLoading}
          bordered
          columns={columnsTable.filter(
            (col) => col.dataIndex !== "companyCode"
          )}
          dataSource={dataMoq}
        />
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow.id);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            name="edit"
            onFieldsChange={(_, allFields) => setFields(allFields)}
            onFinish={onEdit}
            fields={fields}
            form={form}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    allowClear
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterMatGroup &&
                      dataListMasterMatGroup.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="moq"
                  label="Moq"
                  rules={[
                    {
                      required: true,
                      message: "Please input moq!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="moq_kirim"
                  label="Moq Kirim"
                  rules={[
                    {
                      required: true,
                      message: "Please input moq kirim!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            name="add"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterMatGroup &&
                      dataListMasterMatGroup.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="moq"
                  label="Moq"
                  rules={[
                    {
                      required: true,
                      message: "Please input moq!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="moq_kirim"
                  label="Moq Kirim"
                  rules={[
                    {
                      required: true,
                      message: "Please input moq kirim!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>
            Do you want to delete this data <b>{dataRow?.price}</b> ?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterMinimumOrderQty;
