import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { currencyFormatter } from "../global/currencyFormatter";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListMRPReportDownload,
  getDataListReportMRP,
  getDataListReportMRPVersion,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const MRPReport = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [dataListCompany, setDataListCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationMrpReport, setPaginationMrpReport] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [dataListReprotMrp, setDataListReprotMrp] = useState([]);
  const [dataListMrpVersion, setDataListMrpVersion] = useState([]);
  const [state, setState] = useState({
    companyCodeState: "",
    versionState: "",
    periodeState: "",
  });
  const { companyCodeState, versionState, periodeState } = state;

  const fetchDataListMrpReport = useCallback(
    async (params = {}) => {
      setIsLoading(true);

      const res = await getDataListReportMRP(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPaginationMrpReport({
          ...params.pagination,
          total: res.data.items.total,
        });
        setIsLoading(false);
        setDataListReprotMrp(res.data.items.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListMrpVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
    };
    const res = await getDataListReportMRPVersion(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMrpVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState]);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMrpReport({ pagination: paginationMrpReport });
    fetchDataListCompany({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListMrpReport, fetchDataListCompany]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "materialDesc",
      key: "materialDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text) => <p>{currencyFormatter(text)}</p>,
    },
  ];

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const viewDataTable = (value) => {
    setFilter({
      periode: moment(value.periode).format("MM.YYYY"),
      companyCode: value.companyCode,
      version: value.version,
    });
    fetchDataListMrpReport({
      periode: moment(value.periode).format("MM.YYYY"),
      companyCode: value.companyCode,
      version: value.version,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const handleTableChangeMrpReport = (pagination, filters, sorter) => {
    fetchDataListMrpReport({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find((data) => state[data] === "");

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        companyCode: companyCodeState || "",
        version: versionState,
        periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      };
      const res = await getDataListMRPReportDownload(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const resetTable = useCallback(() => {
    setDataListReprotMrp([]);
  }, []);

  useEffect(() => {
    if (companyCodeState || periodeState || versionState) {
      resetTable();
    }
  }, [resetTable, companyCodeState, periodeState, versionState]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>MRP Report</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form scrollToFirstError onFinish={viewDataTable}>
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    onChange={(e) => handleChange("companyCodeState", e)}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("periodeState", e)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[
                    {
                      required: true,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={!periodeState && !companyCodeState}
                    showSearch
                    style={{ width: 300 }}
                    onChange={(e) => handleChange("versionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListMrpVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMrpVersion &&
                      dataListMrpVersion.map((data, index) => {
                        const { labels, version } = data;
                        return (
                          <Option key={index} value={version}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          onChange={handleTableChangeMrpReport}
          pagination={paginationMrpReport}
          bordered
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
          columns={columnsTable}
          dataSource={dataListReprotMrp}
        />
      </div>
      {contextHolder}
    </Content>
  );
};

export default MRPReport;
