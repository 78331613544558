import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  InputNumber,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListMasterCartoonBoxFluteByVendor,
  deleteDataListMasterCartoonBoxFluteGeneral,
  deleteDataListMasterCartoonBoxLayerSekat,
  deleteDataListMasterSpecCartonBox,
  getDataListCharacteritic,
  getDataListMasterCartoonBoxFluteGeneral,
  getDataListMasterCartoonBoxFluteVendor,
  getDataListMasterCartoonBoxLayerSekat,
  getDataListMasterSpecCartoonBox,
  getDataListVendor,
  postDataListMasterCartoonBoxFluteGeneral,
  postDataListMasterFluteByVendor,
  postDataListMasterLayerSekat,
  postDataListMasterSpecCartonBox,
  putDataListMasterCartoonBoxFluteByVendor,
  putDataListMasterCartoonBoxFluteGeneral,
  putDataListMasterCartoonBoxLayerSekat,
  putDataListMasterSpecCartonBox,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const groupListData = {
  Warna: "Color",
  Bahan: "Material",
  Flute: "Flute",
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterSpesificationCartonBox = (props) => {
  const [characteristicDesc, setCharacteristicDesc] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [paginationMasterSpec, setPaginationMasterSpec] = useState({
    current: 1,
    pageSize: 10,
  });
  const [paginationFluteGeneral, setPaginationFluteGeneral] = useState({
    current: 1,
    pageSize: 10,
  });
  const [paginationFluteByVendor, setPaginationFluteByVendor] = useState({
    current: 1,
    pageSize: 10,
  });
  const [paginationLayerSekat, setPaginationLayerSekat] = useState({
    current: 1,
    pageSize: 10,
  });
  const [form] = Form.useForm();
  const [fieldsSpec, setFieldsSpec] = useState([]);
  const [fieldsLayerSekat, setFieldsLayerSekat] = useState([]);
  const [fieldsFluteVendor, setFieldsFluteVendor] = useState([]);
  const [fieldsFluteGeneral, setFieldsFluteGeneral] = useState([]);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataListFluteGeneral, setDataListFluteGeneral] = useState([]);
  const [dataListFluteVendor, setDataListFluteVendor] = useState([]);
  const [dataListLayerSekat, setDataListLayerSekat] = useState([]);
  const [dataCharacter, setDataCharacter] = useState([]);
  const [dataListVendor, setDataListVendor] = useState([]);
  const [dataGroup, setDataGroup] = useState(null);
  const [dataTypeLayerSekat, setDataTypeLayerSekat] = useState(null);
  const [dataConstantaLayerSekat, setDataConstantaLayerSekat] = useState(null);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;

  const fetchDataListMasterSpec = useCallback(async (params = {}) => {
    setIsLoading(true);

    const res = await getDataListMasterSpecCartoonBox(params).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res?.status === 200 || res?.status === 201) {
      setPaginationMasterSpec({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListMasterSpec(res.data.items.data);
      setDataGroup(res.data.groups);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListFluteGeneral = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListMasterCartoonBoxFluteGeneral(params).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
          onCancel: () => {},
        });
      }
    );

    if (res?.status === 200 || res?.status === 201) {
      setPaginationFluteGeneral({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListFluteGeneral(res.data.items.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListFluteVendor = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListMasterCartoonBoxFluteVendor(params).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
          onCancel: () => {},
        });
      }
    );

    if (res?.status === 200 || res?.status === 201) {
      setPaginationFluteByVendor({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListFluteVendor(res.data.items.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListLayerSekat = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListMasterCartoonBoxLayerSekat(params).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
          onCancel: () => {},
        });
      }
    );

    if (res?.status === 200 || res?.status === 201) {
      setPaginationLayerSekat({
        ...params.pagination,
        total: res.data.items.total,
      });
      setDataListLayerSekat(res.data.items.data);
      setDataTypeLayerSekat(res.data.types);
      setDataConstantaLayerSekat(res.data.cons);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListVendor = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {},
        onCancel: () => {},
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVendor(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataLisCharacteritic = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    const data = {
      material_group_id: props.location.state.material_group,
      search: keyword || "",
    };

    const res = await getDataListCharacteritic(data).catch((err) => {
      setIsLoading(false);
      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataCharacter(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataLisCharacteritic({});
    fetchDataListMasterSpec({
      pagination: paginationMasterSpec,
      material_group: props.location.state.material_group,
    });
    fetchDataListFluteGeneral({
      pagination: paginationFluteGeneral,
      material_group: props.location.state.material_group,
    });
    fetchDataListFluteVendor({
      pagination: paginationFluteByVendor,
      material_group: props.location.state.material_group,
    });
    fetchDataListLayerSekat({
      pagination: paginationLayerSekat,
      material_group: props.location.state.material_group,
    });
    fetchDataListVendor({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDataListFluteGeneral,
    fetchDataListMasterSpec,
    fetchDataListFluteVendor,
    fetchDataListLayerSekat,
    fetchDataListVendor,
    fetchDataLisCharacteritic,
  ]);

  const onFinish = async (values) => {
    setIsLoading(true);

    let data = {
      characteristic_id: values.characteristic_id,
      specification_id: props.location.state.id,
      group_id: parseInt(values.group_id),
      characteristic_description: values.characteristic_description,
      range: values.range,
    };

    const res = await postDataListMasterSpecCartonBox(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Specification Successfully Added</p>,
      });
    }
  };

  const onFinishFluteGeneral = async (values) => {
    setIsLoading(true);

    let data = {
      flute: values.flute,
      specification_id: props.location.state.id,
      p_tol: values.p_tol,
      l_tol: values.l_tol,
      take_up: values.take_up,
      kuping: values.kuping,
    };

    const res = await postDataListMasterCartoonBoxFluteGeneral(data).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
        });
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteGeneral({
        pagination: paginationFluteGeneral,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Flute General Successfully Added</p>,
      });
    }
  };

  const onFinishFluteByVendor = async (values) => {
    setIsLoading(true);

    let data = {
      vendor_id: values.vendor_id,
      specification_id: props.location.state.id,
    };

    const res = await postDataListMasterFluteByVendor(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteVendor({
        pagination: paginationFluteByVendor,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Flute By Vendor Successfully Added</p>,
        onOk: () =>
          props.history.push(
            "/master-specification-cartoon-box-flute-vendor-item",
            res.data.item
          ),
      });
    }
  };

  const onFinishLayerSekat = async (values) => {
    setIsLoading(true);

    let data = {
      vendor_id: values.vendor_id,
      type: values.type,
      constanta_reference: values.constanta_reference,
      specification_id: props.location.state.id,
    };

    const res = await postDataListMasterLayerSekat(data).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListLayerSekat({
        pagination: paginationLayerSekat,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
        onOk: () =>
          props.history.push(
            "/master-specification-cartoon-box-layer-sekat-item",
            res.data.item
          ),
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      characteristic_id: values.characteristic_id,
      specification_id: props.location.state.id,
      group_id: parseInt(values.group_id),
      characteristic_description: values.characteristic_description,
      range: values.range,
    };

    const res = await putDataListMasterSpecCartonBox(dataRow.id, data).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
        });
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>{res.data.success}</p>,
      });
    }
  };

  const onEditLayerSekat = async (values) => {
    setIsLoading(true);

    let data = {
      vendor_id: values.vendor_id,
      type: values.type,
      constanta_reference: values.constanta_reference,
      specification_id: props.location.state.id,
    };

    const res = await putDataListMasterCartoonBoxLayerSekat(
      dataRow.id,
      data
    ).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListLayerSekat({
        pagination: paginationLayerSekat,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onEditFluteGeneral = async (values) => {
    setIsLoading(true);

    let data = {
      flute: values.flute,
      specification_id: props.location.state.id,
      p_tol: values.p_tol,
      l_tol: values.l_tol,
      take_up: values.take_up,
      kuping: values.kuping,
    };

    const res = await putDataListMasterCartoonBoxFluteGeneral(
      dataRow.id,
      data
    ).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteGeneral({
        pagination: paginationFluteGeneral,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Flute General Successfully Updated</p>,
      });
    }
  };

  const onEditFluteByVendor = async (values) => {
    setIsLoading(true);

    let data = {
      vendor_id: values.vendor_id,
      specification_id: props.location.state.id,
    };

    const res = await putDataListMasterCartoonBoxFluteByVendor(
      dataRow.id,
      data
    ).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      fetchDataListFluteVendor({
        pagination: paginationFluteByVendor,
        material_group: props.location.state.material_group,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute Vendor Item Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterSpecCartonBox(values).catch((err) => {
      setIsLoading(false);
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
      });
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListMasterSpec({
        pagination: paginationMasterSpec,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Specification Group Deleted Successfully</p>,
      });
    }
  };

  const onDeleteFluteGeneral = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterCartoonBoxFluteGeneral(values).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
        });
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteGeneral({
        pagination: paginationFluteGeneral,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute General Deleted Successfully</p>,
      });
    }
  };

  const onDeleteFluteByVendor = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterCartoonBoxFluteByVendor(values).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
        });
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListFluteVendor({
        pagination: paginationFluteByVendor,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Flute Vendor Deleted Successfully</p>,
      });
    }
  };

  const onDeleteLayerSekat = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListMasterCartoonBoxLayerSekat(values).catch(
      (err) => {
        setIsLoading(false);
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
        });
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListLayerSekat({
        pagination: paginationLayerSekat,
        material_group: props.location.state.material_group,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  const columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "uuid",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "characteristic ID",
      dataIndex: "characteristic_id",
      key: "characteristic_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic",
      dataIndex: "characteristic",
      key: "characteristic",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Group ID",
      dataIndex: "group_id",
      key: "group_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Characteristic Desc",
      dataIndex: "characteristic_description",
      key: "characteristic_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Range",
      dataIndex: "range",
      key: "range",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT SPEC",
                });
                const tempCharacteristicId = dataCharacter.find(
                  (finding) =>
                    finding.name === record.characteristic_description
                );

                setFieldsSpec([
                  {
                    name: ["characteristic_id"],
                    value: tempCharacteristicId?.id,
                  },
                  {
                    name: ["group_id"],
                    value: record.group_id,
                  },
                  {
                    name: ["characteristic_description"],
                    value:
                      characteristicDesc || record.characteristic_description,
                  },
                  {
                    name: ["range"],
                    value: record.range,
                  },
                ]);
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE SPEC",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const columnsTableFluteGeneral = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "uuid",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Flute Vendor",
      dataIndex: "flute_vendor",
      key: "flute_vendor",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Flute",
      dataIndex: "flute",
      key: "flute",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "P Tol (mm)",
      dataIndex: "p_tol",
      key: "p_tol",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "L Tol (mm)",
      dataIndex: "l_tol",
      key: "l_tol",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Take Up",
      dataIndex: "take_up",
      key: "take_up",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Kuping (mm)",
      dataIndex: "kuping",
      key: "kuping",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFieldsFluteGeneral([
                  {
                    name: ["flute"],
                    value: record.flute,
                  },
                  {
                    name: ["p_tol"],
                    value: record.p_tol,
                  },
                  {
                    name: ["l_tol"],
                    value: record.l_tol,
                  },
                  {
                    name: ["take_up"],
                    value: record.take_up,
                  },
                  {
                    name: ["kuping"],
                    value: record.kuping,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT FLUTE GENERAL",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE FLUTE GENERAL",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const columnsTableFluteByVendor = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "uuid",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor ID",
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push(
                  "/master-specification-cartoon-box-flute-vendor-item",
                  record
                );
              }}
              type="primary"
              icon={<UnorderedListOutlined />}
            >
              List
            </Button>
            <Button
              onClick={() => {
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT FLUTE BY VENDOR",
                });
                fetchDataListVendor({ keyword: record.id });
                setFieldsFluteVendor([
                  {
                    name: ["vendor_id"],
                    value: record.vendor_id,
                  },
                ]);
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE FLUTE BY VENDOR",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const columnsTableLayerSekat = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "uuid",
      dataIndex: "uuid",
      key: "uuid",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Specification ID",
      dataIndex: "specification_id",
      key: "specification_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "material_group_description",
      key: "material_group_description",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor ID",
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendor_code",
      key: "vendor_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Constanta ref",
      dataIndex: "constanta_reference",
      key: "constanta_reference",
      render: (text) => (
        <p>
          {(dataConstantaLayerSekat && dataConstantaLayerSekat[text]) || ""}
        </p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push(
                  "/master-specification-cartoon-box-layer-sekat-item",
                  record
                );
              }}
              type="primary"
              icon={<UnorderedListOutlined />}
            >
              List
            </Button>
            <Button
              onClick={() => {
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT LAYER SEKAT",
                });
                fetchDataListVendor({ keyword: record.vendor_name });

                setFieldsLayerSekat([
                  {
                    name: ["vendor_id"],
                    value: record.vendor_id,
                  },
                  {
                    name: ["type"],
                    value: record.type,
                  },
                  {
                    name: ["constanta_reference"],
                    value: record.constanta_reference,
                  },
                ]);
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  ...isModalActionVisible,
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "DELETE LAYER SEKAT",
                })
              }
              type="danger"
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearchSpec = (data) => {
    if (data.length > 3) {
      fetchDataListMasterSpec({
        search: data,
        material_group: props.location.state.material_group,
      });
    } else {
      fetchDataListMasterSpec({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        material_group: props.location.state.material_group,
      });
    }
  };

  const onSearchFluteGeneral = (data) => {
    if (data.length > 2) {
      fetchDataListFluteGeneral({
        search: data,
        material_group: props.location.state.material_group,
      });
    } else {
      fetchDataListFluteGeneral({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        material_group: props.location.state.material_group,
      });
    }
  };

  const onSearchFluteVendor = (data) => {
    if (data.length > 3) {
      fetchDataListFluteVendor({
        search: data,
        material_group: props.location.state.material_group,
      });
    } else {
      fetchDataListFluteVendor({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        material_group: props.location.state.material_group,
      });
    }
  };

  const onSearchLayerSekat = (data) => {
    if (data.length > 3) {
      fetchDataListLayerSekat({
        search: data,
        material_group: props.location.state.material_group,
      });
    } else {
      fetchDataListLayerSekat({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        material_group: props.location.state.material_group,
      });
    }
  };

  const onSearchVendorItem = (data) => {
    if (data.length === 0) {
      fetchDataListVendor({});
    } else if (data.length > 3) {
      fetchDataListVendor({ keyword: data });
    }
  };

  const onSearchCharacteristic = (data) => {
    if (data.length === 0) {
      fetchDataLisCharacteritic({});
    } else if (data.length > 3) {
      fetchDataLisCharacteritic({ keyword: data });
    }
  };

  const onChangeCharacteristic = (data) => {
    let tempData = dataCharacter.find((finding) => finding.id === data);
    setCharacteristicDesc(tempData.name);
  };

  const handleTableChangeMasterSpec = (pagination, filters, sorter) => {
    fetchDataListMasterSpec({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  const handleTableChangeFluteGeneral = (pagination, filters, sorter) => {
    fetchDataListFluteGeneral({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  const handleTableChangeFluteByVendor = (pagination, filters, sorter) => {
    fetchDataListFluteVendor({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  const handleTableChangeLayerSekat = (pagination, filters, sorter) => {
    fetchDataListLayerSekat({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      material_group: props.location.state.material_group,
      ...filters,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Master Specification</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Row justify="space-between">
          <Title level={4}>
            {props?.location?.state?.material_group} -{" "}
            {props?.location?.state?.material_group_description}
          </Title>
          <Button
            onClick={() => props.history.push("/master-specification")}
            type="default"
            icon={<ArrowLeftOutlined />}
          >
            Back to list
          </Button>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Specification" key="1">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD SPEC",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearchSpec}
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              pagination={paginationMasterSpec}
              onChange={handleTableChangeMasterSpec}
              placeholder="Input min. 4 char"
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTable.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "specification_id" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "group_id" &&
                  col.dataIndex !== "state" &&
                  col.dataIndex !== "type" &&
                  col.dataIndex !== "material_group_description" &&
                  col.dataIndex !== "characteristic_id"
              )}
              dataSource={dataListMasterSpec}
            />
          </TabPane>
          <TabPane tab="Flute General" key="2">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD FLUTE GENERAL",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearchFluteGeneral}
                placeholder="Input min. 3 char"
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              onChange={handleTableChangeFluteGeneral}
              pagination={paginationFluteGeneral}
              placeholder="Input min. 3 char"
              loading={isLoading}
              bordered
              size="small"
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTableFluteGeneral.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "created_at" &&
                  col.dataIndex !== "updated_at" &&
                  col.dataIndex !== "flute_vendor" &&
                  col.dataIndex !== "state" &&
                  col.dataIndex !== "type" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "material_group_description" &&
                  col.dataIndex !== "specification_id"
              )}
              dataSource={dataListFluteGeneral}
            />
          </TabPane>
          <TabPane tab="Flute by Vendor" key="3">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD FLUTE BY VENDOR",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearchFluteVendor}
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              onChange={handleTableChangeFluteByVendor}
              pagination={paginationFluteByVendor}
              placeholder="Input min. 4 char"
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTableFluteByVendor.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "created_at" &&
                  col.dataIndex !== "updated_at" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "vendor_id" &&
                  col.dataIndex !== "material_group_description" &&
                  col.dataIndex !== "specification_id"
              )}
              dataSource={dataListFluteVendor}
            />
          </TabPane>
          <TabPane tab="Layer/Sekat" key="4">
            <Row
              justify="space-between"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD LAYER SEKAT",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
              <Input.Search
                onSearch={onSearchLayerSekat}
                allowClear
                style={{ width: "40%" }}
              />
            </Row>
            <Table
              onChange={handleTableChangeLayerSekat}
              pagination={paginationLayerSekat}
              placeholder="Input min. 4 char"
              size="small"
              loading={isLoading}
              bordered
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              columns={columnsTableLayerSekat.filter(
                (col) =>
                  col.dataIndex !== "uuid" &&
                  col.dataIndex !== "created_at" &&
                  col.dataIndex !== "updated_at" &&
                  col.dataIndex !== "material_group" &&
                  col.dataIndex !== "vendor_id" &&
                  col.dataIndex !== "material_group_description" &&
                  col.dataIndex !== "specification_id"
              )}
              dataSource={dataListLayerSekat}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        title={typeAction}
        forceRender={false}
        visible={isShowModalAction}
        width={
          [
            "ADD LAYER SEKAT",
            "EDIT LAYER SEKAT",
            "ADD FLUTE GENERAL",
            "ADD SPEC",
            "EDIT SPEC",
            "EDIT FLUTE GENERAL",
            "EDIT FLUTE BY VENDOR",
            "ADD FLUTE BY VENDOR",
          ].includes(typeAction)
            ? 1000
            : undefined
        }
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE SPEC" ||
          typeAction === "DELETE FLUTE GENERAL" ||
          typeAction === "DELETE FLUTE BY VENDOR" ||
          typeAction === "DELETE LAYER SEKAT"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (typeAction === "DELETE SPEC") {
                      if (isLoading) {
                        return;
                      } else {
                        onDelete(dataRow.id);
                      }
                    } else if (typeAction === "DELETE FLUTE GENERAL") {
                      if (isLoading) {
                        return;
                      } else {
                        onDeleteFluteGeneral(dataRow.id);
                      }
                    } else if (typeAction === "DELETE FLUTE BY VENDOR") {
                      if (isLoading) {
                        return;
                      } else {
                        onDeleteFluteByVendor(dataRow.id);
                      }
                    } else if (typeAction === "DELETE LAYER SEKAT") {
                      if (isLoading) {
                        return;
                      } else {
                        onDeleteLayerSekat(dataRow.id);
                      }
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT SPEC" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fieldsSpec}
            onFieldsChange={(_, allFields) => setFieldsSpec(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_id"
                  label="Characteristic"
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    onChange={onChangeCharacteristic}
                    onFocus={() => fetchDataLisCharacteritic({})}
                    onSearch={onSearchCharacteristic}
                    loading={isLoading}
                    placeholder="select your characteristic"
                  >
                    {dataCharacter &&
                      dataCharacter.map((data, index) => {
                        const { id, char_code_variable, name } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${char_code_variable} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="group_id"
                  label="Group"
                  help="1 = Color, 2 = Material, 3 = Flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select loading={isLoading} placeholder="select your group">
                    {dataGroup &&
                      Object.keys(dataGroup).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {groupListData[dataGroup[data]]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_description"
                  label="Characteristic desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic desc.!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="range"
                  label="Range"
                  rules={[
                    {
                      required: true,
                      message: "Please input range!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD SPEC" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            fields={[
              {
                name: ["characteristic_description"],
                value: characteristicDesc,
              },
            ]}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_id"
                  label="Characteristic"
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataLisCharacteritic({})}
                    onChange={onChangeCharacteristic}
                    onSearch={onSearchCharacteristic}
                    placeholder="select your characteristic"
                  >
                    {dataCharacter &&
                      dataCharacter.map((data, index) => {
                        const { id, char_code_variable, name } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${char_code_variable} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="group_id"
                  label="Group"
                  help="1 = Color, 2 = Material, 3 = Flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select loading={isLoading} placeholder="select your group">
                    {dataGroup &&
                      Object.keys(dataGroup).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {groupListData[dataGroup[data]]}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="characteristic_description"
                  label="Characteristic desc."
                  rules={[
                    {
                      required: true,
                      message: "Please input your characteristic desc.!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="range"
                  label="Range"
                  rules={[
                    {
                      required: true,
                      message: "Please input range!",
                    },
                  ]}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD FLUTE GENERAL" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinishFluteGeneral}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="flute"
                  label="flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input your flute!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="p_tol"
                  label="P Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input p tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="l_tol"
                  label="L Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your l tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="take_up"
                  label="Take Up"
                  rules={[
                    {
                      required: true,
                      message: "Please input take up!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="kuping"
                  label="Kuping (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your kuping!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD FLUTE BY VENDOR" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinishFluteByVendor}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    style={{ width: 350 }}
                    allowClear
                    showSearch
                    onFocus={() => fetchDataListVendor({})}
                    loading={isLoading}
                    placeholder="input min. 4 char"
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD LAYER SEKAT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            onFinish={onFinishLayerSekat}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    onFocus={() => fetchDataListVendor({})}
                    loading={isLoading}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    onSearch={onSearchVendorItem}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select allowClear placeholder="select your type">
                    {dataTypeLayerSekat &&
                      Object.keys(dataTypeLayerSekat).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="constanta_reference"
                  label="Constanta ref"
                  rules={[
                    {
                      required: true,
                      message: "Please input your constanta ref!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select allowClear placeholder="select your constanta ref">
                    {dataConstantaLayerSekat &&
                      Object.keys(dataConstantaLayerSekat).map(
                        (data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {dataConstantaLayerSekat[data]}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "EDIT LAYER SEKAT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            fields={fieldsLayerSekat}
            onFieldsChange={(_, allFields) => setFieldsLayerSekat(allFields)}
            onFinish={onEditLayerSekat}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    onFocus={() => fetchDataListVendor({})}
                    loading={isLoading}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    onSearch={onSearchVendorItem}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your type!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select allowClear placeholder="select your type">
                    {dataTypeLayerSekat &&
                      Object.keys(dataTypeLayerSekat).map((data, index) => {
                        return (
                          <Option key={index} value={data}>
                            {data}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="constanta_reference"
                  label="Constanta ref"
                  help="1 = Final Price, 2 = Spec Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input your constanta ref!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select allowClear placeholder="select your constanta ref">
                    {dataConstantaLayerSekat &&
                      Object.keys(dataConstantaLayerSekat).map(
                        (data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {dataConstantaLayerSekat[data]}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "EDIT FLUTE BY VENDOR" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            fields={fieldsFluteVendor}
            onFieldsChange={(_, allFields) => setFieldsFluteVendor(allFields)}
            onFinish={onEditFluteByVendor}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="vendor_id"
                  label="Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input your vendor!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    style={{ width: 350 }}
                    allowClear
                    showSearch
                    loading={isLoading}
                    onFocus={() => fetchDataListVendor({})}
                    onSearch={onSearchVendorItem}
                    optionFilterProp="children"
                    placeholder="input min. 4 char"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVendor &&
                      dataListVendor.map((data, index) => {
                        const { name, vendor_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {`${vendor_code} - ${name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "EDIT FLUTE GENERAL" ? (
          <Form
            {...formItemLayout}
            form={form}
            onFieldsChange={(_, allFields) => setFieldsFluteGeneral(allFields)}
            name="edit"
            fields={fieldsFluteGeneral}
            onFinish={onEditFluteGeneral}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="flute"
                  label="flute"
                  rules={[
                    {
                      required: true,
                      message: "Please input your flute!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="p_tol"
                  label="P Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input p tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="l_tol"
                  label="L Tol (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your l tol!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="take_up"
                  label="Take Up"
                  rules={[
                    {
                      required: true,
                      message: "Please input take up!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="kuping"
                  label="Kuping (mm)"
                  rules={[
                    {
                      required: true,
                      message: "Please input your kuping!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    allowClear
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "DELETE SPEC" ? (
          <p>
            Do you want to delete this data <b>{dataRow?.characteristic}</b> ?
          </p>
        ) : typeAction === "DELETE FLUTE GENERAL" ? (
          <p>
            Do you want to delete this data <b>{dataRow?.flute}</b> ?
          </p>
        ) : typeAction === "DELETE FLUTE BY VENDOR" ? (
          <p>
            Do you want to delete this data{" "}
            <b>{dataRow?.material_group_description}</b> ?
          </p>
        ) : (
          <p>
            Do you want to delete this data{" "}
            <b>{dataRow?.material_group_description}</b> ?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterSpesificationCartonBox;
