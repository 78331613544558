import {
  EyeOutlined,
  InboxOutlined,
  PlayCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListReportVASuggestionVersion,
  getDataListVendorAllocationCockpit,
  postDataCockpitMRP,
  postDataCockpitPR,
  postDataListVAAdjustmentUpload,
  postFileAllocationPlanOrderAdjustment,
  postFileSchedulePurchaseOrderAdjustment,
  postVendorAllocationCockpitExecuteProgram,
} from "../services/retrieveData";
import { ColorBaseEnum, ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const CockpitVendorAlloc = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [dataListVACockpit, setDataListVACockpit] = useState([]);
  const [form] = Form.useForm();
  const [dataImportFile, setDataImportFile] = useState(null);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [fields, setFields] = useState([]);
  const [dataListVASuggestionVersion, setDataListVASuggestionVersion] =
    useState([]);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;
  const [state, setState] = useState({
    companyCodeState: "",
    versionState: "",
    periodeState: "",
  });
  const { companyCodeState, versionState, periodeState } = state;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return setDataImportFile(e);
    }
    return setDataImportFile(e && e.fileList);
  };

  const fetchDataListVASuggestionVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
    };
    const res = await getDataListReportVASuggestionVersion(data).catch(
      (err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVASuggestionVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState]);

  const fetchDataListVendorAllocationCockpit = useCallback(
    async ({ periode, companyCode }) => {
      setIsLoading(true);

      const dataReq = {
        companyCode: companyCode || "",
        periode: periode ? moment(periode).format("MM.YYYY") : "",
      };

      const res = await getDataListVendorAllocationCockpit(dataReq).catch(
        (err) => {
          setIsLoading(false);
          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res && res.status === 200) {
        setIsLoading(false);
        const tempData = [
          {
            no: "1",
            dataDescription: "PR",
            period: res.data.pr_periode,
            version: res.data.pr_version,
            action: res.data.pr_action,
            status: res.data.pr_status,
            message: res.data.pr_message,
          },
          {
            no: "2",
            dataDescription: "MRP",
            period: res.data.mrp_periode,
            version: res.data.mrp_version,
            action: res.data.mrp_action,
            status: res.data.mrp_status,
            message: res.data.mrp_message,
          },
          {
            no: "3",
            dataDescription: "System Suggestion",
            period: res.data.system_sugestion_periode,
            version: res.data.system_sugestion_version,
            action: res.data.system_sugestion_action,
            status: res.data.system_sugestion_status,
            message: res.data.system_sugestion_message,
          },
          {
            no: "4",
            dataDescription: "Upload Vendor Alc Adjustment",
            period: res.data.adjustment_periode,
            version: res.data.adjustment_version,
            action: res.data.adjustment_action,
            status: res.data.adjustment_status,
            message: res.data.adjustment_message,
          },
          {
            no: "5",
            dataDescription: "Upload Plan Ord Adjustment",
            period: res.data.allocation_adjustment_periode,
            version: res.data.allocation_adjustment_version,
            action: res.data.allocation_adjustment_action,
            status: res.data.allocation_adjustment_status,
            message: res.data.allocation_adjustment_message,
          },
          {
            no: "6",
            dataDescription: "Upload Schedule PO Adjustment",
            period: res.data.schedule_adjustment_periode,
            version: res.data.schedule_adjustment_version,
            action: res.data.schedule_adjustment_action,
            status: res.data.schedule_adjustment_status,
            message: res.data.schedule_adjustment_message,
          },
        ];

        setDataListVACockpit(tempData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCompany({});
  }, [fetchDataListCompany]);

  const columnsTable = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Data Desc.",
      dataIndex: "dataDescription",
      key: "dataDescription",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (text) => (
        <p>
          {text === "under maintenance in server"
            ? "under maintenance in server"
            : moment(text).format("DD-MM-YYYY")}
        </p>
      ),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                record.no === "4" || record.no === "5" || record.no === "6"
                  ? setIsModalActionVisible({
                      ...isModalActionVisible,
                      typeAction: "UPLOAD",
                      isShowModalAction: true,
                      dataRow: record,
                    })
                  : setIsModalActionVisible({
                      ...isModalActionVisible,
                      dataRow: record,
                      typeAction: `ACTION ${record.dataDescription}`,
                      isShowModalAction: true,
                    })
              }
              type="primary"
              icon={<PlayCircleOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  const onSendDataSap = async (values) => {
    setIsLoading(true);

    let data = {
      periode: values.periode ? moment(values.periode).format("MM.YYYY") : "",
      companyCode: values.companyCode,
      schedule: values.schedule
        ? moment(values.schedule).format("DD.MM.YYYY hh:mm")
        : "",
    };

    let res = null;

    if (dataRow.no === "1") {
      res = await postDataCockpitPR(data).catch((err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      });
    } else if (dataRow.no === "2") {
      res = await postDataCockpitMRP(data).catch((err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      });
    } else {
      res = await postVendorAllocationCockpitExecuteProgram(data).catch(
        (err) => {
          modal.error({
            title: "Failed Message!",
            content: <p>{err?.response?.data?.message}</p>,
          });
          setIsLoading(false);
        }
      );
    }

    if (res && res.status === 200) {
      setIsLoading(false);
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully running 🚀🚀🚀</p>,
      });
    }
  };

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const uploadData = async () => {
    let isFilled = Object.keys(state).find(
      (data) => data !== "versionState" && state[data] === ""
    );
    if (!isFilled) {
      setIsLoading(true);
      let data = new FormData();

      data.append("excel", dataImportFile && dataImportFile[0].originFileObj);
      data.append("periode", moment(periodeState).format("MM.YYYY"));
      data.append("version", versionState);
      data.append("companyCode", companyCodeState);

      let res = null;

      if (dataRow.no === "4") {
        res = await postDataListVAAdjustmentUpload(data).catch((err) => {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {
              form.resetFields();
            },
            onCancel: () => {
              form.resetFields();
            },
          });
          setIsLoading(false);
        });
      } else if (dataRow.no === "5") {
        res = await postFileAllocationPlanOrderAdjustment(data).catch((err) => {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {
              form.resetFields();
            },
            onCancel: () => {
              form.resetFields();
            },
          });
          setIsLoading(false);
        });
      } else if (dataRow.no === "6") {
        res = await postFileSchedulePurchaseOrderAdjustment(data).catch(
          (err) => {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {
                form.resetFields();
              },
              onCancel: () => {
                form.resetFields();
              },
            });
            setIsLoading(false);
          }
        );
      }

      if (res && res.status === 200) {
        if (res.data?.url) {
          window.open(res.data.url, "_blank");
          setTimeout(() => window.location.reload(), 1000);
        } else if (res.data === "Berhasil upload") {
          setIsLoading(false);
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
          modal.success({
            title: "Success Message!",
            content: (
              <p>
                Upload success, the data is currently processing in background.
              </p>
            ),
            onOk: () => setTimeout(() => window.location.reload(), 1000),
          });
        }
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const resetTable = useCallback(() => {
    setDataListVACockpit([]);
  }, []);

  useEffect(() => {
    if (companyCodeState || periodeState || versionState) {
      resetTable();
    }
  }, [resetTable, companyCodeState, periodeState, versionState]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Cockpit Vendor Allocation</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          scrollToFirstError
          onFinish={(e) => {
            fetchDataListVendorAllocationCockpit({
              periode: e.periode,
              companyCode: e.companyCode,
            });

            setFields([
              {
                name: ["companyCode"],
                value: e.companyCode,
              },
              {
                name: ["periode"],
                value: e.periode,
              },
              {
                name: ["schedule"],
                value: "",
              },
            ]);
          }}
        >
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onChange={(e) => handleChange("companyCodeState", e)}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    onChange={(e) => handleChange("periodeState", e)}
                    picker="month"
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
              <Col hidden>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[
                    {
                      required: false,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={!periodeState || !companyCodeState}
                    showSearch
                    style={{ width: 300 }}
                    onChange={(e) => handleChange("versionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVASuggestionVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVASuggestionVersion &&
                      dataListVASuggestionVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row style={{ marginBottom: MarginEnum["2x"] }} justify="end">
            <Col>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          bordered
          columns={columnsTable.filter((col) => col.dataIndex !== "action")}
          dataSource={dataListVACockpit}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
        />
      </div>

      <Modal
        title={
          dataRow?.no === "4" || dataRow?.no === "5" || dataRow?.no === "6"
            ? dataRow?.dataDescription
            : typeAction
        }
        forceRender={false}
        visible={isShowModalAction}
        width={
          ["ACTION PR", "ACTION MRP", "ACTION System Suggestion"].includes(
            typeAction
          )
            ? 1000
            : undefined
        }
        afterClose={() => setIsSchedule(false)}
        onCancel={() => {
          if (typeAction === "UPLOAD" && dataImportFile) {
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            setIsSchedule(false);
            setIsModalActionVisible({
              ...isModalActionVisible,
              isShowModalAction: false,
            });
          }
        }}
        footer={null}
      >
        {typeAction === "UPLOAD" ? (
          <>
            <Form.Item>
              <Form.Item
                name="uploadFileMdDesign"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger name="files">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Button
              block
              onClick={uploadData}
              style={{
                backgroundColor: ColorBaseEnum.white,
                color: ColorPrimaryEnum.redVelvet,
                border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                marginBottom: MarginEnum["2x"],
              }}
              type="primary"
              loading={isLoading}
              disabled={dataImportFile ? false : true}
              icon={<UploadOutlined />}
            >
              Upload File
            </Button>
          </>
        ) : typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : ["ACTION PR", "ACTION MRP", "ACTION System Suggestion"].includes(
            typeAction
          ) ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            fields={fields}
            onFinish={onSendDataSap}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    onChange={(e) => handleChange("periodeState", e)}
                    disabled
                    picker="month"
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="schedule"
                  label="Schedule"
                  rules={[
                    {
                      required: false,
                      message: "Please input your schedule!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    disabled={isSchedule ? false : true}
                    showTime
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Space>
                {!isSchedule && (
                  <Form.Item>
                    <Button
                      onClick={() => setIsSchedule(!isSchedule)}
                      loading={isLoading}
                      type="secondary"
                    >
                      Schedule
                    </Button>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    {isSchedule ? "Send Schedule" : "Immediate"}
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        ) : null}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default CockpitVendorAlloc;
