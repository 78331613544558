import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { currencyFormatter } from "../global/currencyFormatter";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteStandartAllocation,
  deleteStandartAllocationRatio,
  getDataListMasterMaterialGroup,
  getDataListStandardAllocationJumlah,
  getDataListStandardAllocationRatio,
  postDataStandardAllocation,
  postDataStandardAllocationRatio,
  putDataStandardAllocationRatio,
  putDataStandartAllocation,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import "../styles/Dashboard.css";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const MasterStandardAllocation = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isShowInfo, setIsShowInfo] = useState(true);
  const [isEnableQtyMax, setIsEnableQtyMax] = useState(false);
  const [state, setState] = useState({
    id: "",
  });
  const { id } = state;
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;
  const [fields, setFields] = useState([]);
  const [fieldsRatio, setFieldsRatio] = useState([]);
  const [totalFieldRatio, setTotalFieldRatio] = useState([]);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataListStandart, setdataListStandart] = useState([]);
  const [dataListStandartRatio, setdataListStandartRatio] = useState([]);

  let tempJumlahVendor = null;
  tempJumlahVendor = dataListStandart.map((mapping) => mapping.qtyMax);

  let tempEmptyqtyMax = null;
  tempEmptyqtyMax = dataListStandart.find((finding) => finding.qtyMax === null);

  let tempJumlahVendorMin = null;
  tempJumlahVendorMin = dataListStandart.map((mapping) => mapping.qtyMin);

  let tempSpecificationId = null;
  tempSpecificationId = dataListMasterSpec.find((finding) => finding.id === id);

  const fetchDataListStandartAllocation = useCallback(
    async ({ material_group }) => {
      setIsLoading(true);
      let data = {
        material_group: material_group,
      };
      const res = await getDataListStandardAllocationJumlah(data).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res && res.status === 200) {
        setdataListStandart(res.data.items.data);
        setIsLoading(false);
        if (res.data.items.data.length > 0) {
          setIsShowInfo(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListStandartAllocationRatio = useCallback(
    async () => {
      setIsLoading(true);
      const res = await getDataListStandardAllocationRatio().catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res && res.status === 200) {
        setdataListStandartRatio(res.data.items);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterSpec(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListStandartAllocation({});
    fetchDataListStandartAllocationRatio({});
    fetchDataListMasterMaterialGroup({});
  }, [
    fetchDataListStandartAllocation,
    fetchDataListStandartAllocationRatio,
    fetchDataListMasterMaterialGroup,
  ]);

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const res = await postDataStandardAllocation(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListStandartAllocation({
        material_group: id,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    const res = await putDataStandartAllocation(dataRow.id, values).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListStandartAllocation({
        material_group: id,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onFinishRatio = async (values) => {
    setIsLoading(true);

    let tempValues = values;
    if (tempValues.jumlah_vendor) {
      delete tempValues.jumlah_vendor;
    }

    const getArrayValue = Object.keys(tempValues).map((data, index) => {
      return tempValues[data];
    });

    let isOnePercent = getArrayValue.reduce(
      (total, number) => total + parseFloat(number),
      0
    );

    const data = {
      jumlah_vendor: dataListStandartRatio.length + 1,
      ratio_list: getArrayValue,
    };

    if (Number(isOnePercent) === 100) {
      const res = await postDataStandardAllocationRatio(data).catch((err) => {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {
            form.resetFields();
          },
          onCancel: () => {
            form.resetFields();
          },
        });
        setIsLoading(false);
      });

      if (res && res.status === 200) {
        setIsLoading(false);
        form.resetFields();
        fetchDataListStandartAllocationRatio({});
        setIsModalActionVisible({
          ...isModalActionVisible,
          isShowModalAction: false,
        });

        modal.success({
          title: "Success Message!",
          content: <p>Successfully Added</p>,
        });
      }
    } else {
      setIsLoading(false);
      modal.warning({
        title: "Warning Message!",
        content: <p>Total ratio must be 100!</p>,
      });
    }
  };

  const onEditRatio = async (values) => {
    setIsLoading(true);

    const getArrayValue = Object.keys(values).map((data, index) => {
      return values[data];
    });

    let isOnePercent = getArrayValue.reduce(
      (total, number) => total + parseFloat(number),
      0
    );

    const data = {
      ratio_list: getArrayValue,
    };

    if (Number(isOnePercent) === 100) {
      const res = await putDataStandardAllocationRatio(
        dataRow.ratio.length,
        data
      ).catch((err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      });

      if (res && res.status === 200) {
        setIsLoading(false);
        form.resetFields();
        fetchDataListStandartAllocationRatio({});
        setIsModalActionVisible({
          ...isModalActionVisible,
          isShowModalAction: false,
        });

        modal.success({
          title: "Success Message!",
          content: <p>Successfully Updated</p>,
        });
      }
    } else {
      setIsLoading(false);
      modal.warning({
        title: "Warning Message!",
        content: <p>Total ratio must be 100!</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteStandartAllocation(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: <p>{err?.response?.data?.message}</p>,
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListStandartAllocation({
        material_group: id,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  const onDeleteRatio = async (values) => {
    setIsLoading(true);
    const res = await deleteStandartAllocationRatio(values).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: <p>{err?.response?.data?.message}</p>,
        onOk: () => {
          form.resetFields();
        },
        onCancel: () => {
          form.resetFields();
        },
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListStandartAllocationRatio({});
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  const columnsTableJumlah = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group Desc",
      dataIndex: "materialGroupDesc",
      key: "materialGroupDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Qty Min",
      dataIndex: "qtyMin",
      key: "qtyMin",
      render: (text) => <p>{currencyFormatter(text)}</p>,
    },
    {
      title: "Qty Max",
      dataIndex: "qtyMax",
      key: "qtyMax",
      render: (text) => <p>{currencyFormatter(text)}</p>,
    },
    {
      title: "Jumlah Vendor",
      dataIndex: "jumlahVendor",
      key: "jumlahVendor",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "VIEW",
                })
              }
              type="primary"
              icon={<EyeOutlined />}
            >
              View
            </Button>
            <Button
              onClick={() => {
                setIsEnableQtyMax(dataListStandart.length - 1 === index);
                setFields([
                  {
                    name: ["specification_id"],
                    value: tempSpecificationId.id,
                  },
                  {
                    name: ["qty_min"],
                    value: record.qtyMin,
                  },
                  {
                    name: ["qty_max"],
                    value: record.qtyMax,
                  },
                  {
                    name: ["jumlah_vendor"],
                    value: record.jumlahVendor,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            {dataListStandart.length - 1 === index && (
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    isShowModalAction: true,
                    dataRow: record,
                    typeAction: "DELETE",
                  })
                }
                type="danger"
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const columnsTableRatio = [
    {
      title: "Jumlah Vendor",
      dataIndex: "jumlah_vendor",
      key: "jumlah_vendor",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Rank : Ratio",
      key: "ratio",
      dataIndex: "ratio",
      render: (ratio) => (
        <>
          {ratio.map((data, index) => {
            return (
              <Tag style={{ width: 60 }} key={index} color={"geekblue"}>
                {`${index + 1} : ${data}`}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Button
              onClick={() =>
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "VIEW RATIO",
                })
              }
              type="primary"
              icon={<EyeOutlined />}
            >
              View
            </Button>
            <Button
              onClick={() => {
                let tempRatio = [];
                record.ratio.forEach((data, index) =>
                  tempRatio.push({
                    name: [`ratio_list_${index + 1}`],
                    value: data,
                  })
                );
                setFieldsRatio(tempRatio);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT RATIO",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            {dataListStandartRatio.length - 1 === index && (
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    isShowModalAction: true,
                    dataRow: record,
                    typeAction: "DELETE RATIO",
                  })
                }
                type="danger"
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const viewDataTable = (value) => {
    fetchDataListStandartAllocation({
      material_group: value.material_group,
    });
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Standard Allocation</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Jumlah Vendor" key="1">
            <Row
              justify="end"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() => {
                  if (tempEmptyqtyMax) {
                    modal.warning({
                      title: "Warning message",
                      content: <p>Please edit first for empty qty max!</p>,
                    });
                  } else {
                    setFields([
                      {
                        name: ["specification_id"],
                        value: tempSpecificationId?.id,
                      },
                      {
                        name: ["qty_min"],
                        value:
                          dataListStandart.length === 0
                            ? 0
                            : Math.max(...tempJumlahVendor) + 1,
                      },
                      {
                        name: ["qty_max"],
                        value: "",
                      },
                      {
                        name: ["jumlah_vendor"],
                        value: "",
                      },
                    ]);

                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      typeAction: "ADD",
                      isShowModalAction: true,
                    });
                  }
                }}
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
            </Row>
            {isShowInfo && (
              <p style={{ color: ColorPrimaryEnum.red }}>
                *Please for first load in this menu to get Qty Min,{" "}
                <span>
                  <b>Choose material & Click VIEW BUTTON First</b>
                </span>
              </p>
            )}
            <Form scrollToFirstError onFinish={viewDataTable}>
              <Row>
                <Col>
                  <Form.Item
                    name="material_group"
                    label="Material Group"
                    rules={[
                      {
                        required: true,
                        message: "Please input material group!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      style={{ width: 200 }}
                      showSearch
                      placeholder="Input min. 4 char"
                      onChange={(e) => handleChange("id", e)}
                      loading={isLoading}
                      onFocus={() => fetchDataListMasterMaterialGroup({})}
                      onSearch={onSearchMaterialGroupItem}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataListMasterSpec &&
                        dataListMasterSpec.map((data, index) => {
                          const { description, id } = data;
                          return (
                            <Option key={index} value={id}>
                              {description}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: MarginEnum["2x"] }}>
                  <Button
                    loading={isLoading}
                    style={{
                      backgroundColor: ColorPrimaryEnum.redVelvet,
                      border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                    }}
                    type="primary"
                    htmlType="submit"
                    icon={<EyeOutlined />}
                  >
                    View
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              loading={isLoading}
              bordered
              columns={columnsTableJumlah}
              dataSource={dataListStandart}
              size="small"
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Ratio Vendor" key="2">
            <Row
              justify="end"
              style={{
                marginBottom: 12,
              }}
            >
              <Button
                onClick={() => {
                  setFieldsRatio([
                    {
                      name: ["jumlah_vendor"],
                      value: dataListStandartRatio.length + 1,
                    },
                  ]);

                  let tempRatioListData = [];

                  for (let i = 0; i < dataListStandartRatio.length + 1; i++) {
                    tempRatioListData.push({
                      index: i + 1,
                    });
                  }

                  setTotalFieldRatio(tempRatioListData);

                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD RATIO",
                    isShowModalAction: true,
                  });
                }}
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
            </Row>
            <Table
              loading={isLoading}
              bordered
              columns={columnsTableRatio}
              dataSource={dataListStandartRatio}
              size="small"
              scroll={{ x: 1500 }}
              rowClassName={(record, index) => {
                if (index % 2 === 1) {
                  return "color-gray-2";
                } else {
                  return "color-gray-1";
                }
              }}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </div>

      <Modal
        title={
          typeAction === "EDIT RATIO"
            ? "EDIT RATIO " + dataRow.jumlah_vendor
            : typeAction === "DELETE RATIO"
            ? "DELETE RATIO " + dataRow.jumlah_vendor
            : typeAction
        }
        forceRender={false}
        visible={isShowModalAction}
        width={
          ["ADD", "EDIT", "ADD RATIO", "EDIT RATIO"].includes(typeAction)
            ? 1000
            : undefined
        }
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE" || typeAction === "DELETE RATIO"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (typeAction === "DELETE") {
                      if (isLoading) {
                        return;
                      } else {
                        onDelete(dataRow.id);
                      }
                    } else {
                      if (isLoading) {
                        return;
                      } else {
                        onDeleteRatio(dataRow.ratio.length);
                      }
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "VIEW RATIO" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {" " + dataRow[data]}
                </span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onEdit}
            fields={fields}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="specification_id"
                  label="Material Group"
                  rules={[
                    {
                      required: true,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={true}
                    style={{ width: 200 }}
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="qty_min"
                  label="Qty Min"
                  rules={[
                    {
                      required: false,
                      message: "Please input Qty Min!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    disabled={true}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="qty_max"
                  label="Qty Max"
                  rules={[
                    {
                      required: false,
                      message: "Please input Qty Max!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    min={
                      dataListStandart.length === 0
                        ? 1
                        : Math.max(...tempJumlahVendorMin) + 1
                    }
                    disabled={!isEnableQtyMax}
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="jumlah_vendor"
                  label="Jumlah Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input Jumlah Vendor",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    min={1}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "EDIT RATIO" ? (
          <div
            style={
              dataRow.jumlah_vendor > 5
                ? { height: 300, overflowY: "scroll" }
                : undefined
            }
          >
            <Form
              {...formItemLayout}
              form={form}
              name="edit"
              onFinish={onEditRatio}
              fields={fieldsRatio}
              onFieldsChange={(_, allFields) => setFieldsRatio(allFields)}
              scrollToFirstError
            >
              {dataRow.ratio.map((data, index) => (
                <Row key={index}>
                  <Col lg={12} md={24}>
                    <Form.Item
                      name={`ratio_list_${index + 1}`}
                      label={`Ratio ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: "Please input ratio!",
                        },
                      ]}
                      hasFeedback
                    >
                      <InputNumber
                        onWheel={(e) => e.target.blur()}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Form.Item>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            form={form}
            name="add"
            fields={fields}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="specification_id"
                  label="Material Group"
                  rules={[
                    {
                      required: true,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={true}
                    style={{ width: 200 }}
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="qty_min"
                  label="Qty Min"
                  rules={[
                    {
                      required: false,
                      message: "Please input Qty Min!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    disabled={true}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="qty_max"
                  label="Qty Max"
                  help="Please input more than value qty min."
                  rules={[
                    {
                      required: false,
                      message: "Please input Qty Max!",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    min={
                      dataListStandart.length === 0
                        ? 1
                        : Math.max(...tempJumlahVendor) + 2
                    }
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="jumlah_vendor"
                  label="Jumlah Vendor"
                  rules={[
                    {
                      required: true,
                      message: "Please input Jumlah Vendor",
                    },
                  ]}
                  hasFeedback
                >
                  <InputNumber onWheel={(e) => e.target.blur()} type="number" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD RATIO" ? (
          <div style={{ height: 300, overflowY: "scroll" }}>
            <Form
              {...formItemLayout}
              form={form}
              name="add"
              fields={fieldsRatio}
              onFinish={onFinishRatio}
              scrollToFirstError
            >
              <Row>
                <Col lg={12} md={24}>
                  <Form.Item
                    name="jumlah_vendor"
                    label="Jumlah Vendor"
                    rules={[
                      {
                        required: true,
                        message: "Please input Jumlah Vendor!",
                      },
                    ]}
                    hasFeedback
                  >
                    <InputNumber
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              {totalFieldRatio.map((data, index) => (
                <Row key={index}>
                  <Col lg={12} md={24}>
                    <Form.Item
                      name={`ratio_list_${index + 1}`}
                      label={`Ratio ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: "Please input ratio!",
                        },
                      ]}
                      hasFeedback
                    >
                      <InputNumber
                        onWheel={(e) => e.target.blur()}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Form.Item>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    Create
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : (
          <p>Apakah anda ingin menghapus data ini ?</p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default MasterStandardAllocation;
