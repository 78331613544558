import { EnterOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { currencyFormatter } from "../global/currencyFormatter";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListMasterMaterialGroup,
  getDataListReportVASuggestionVersion,
  getDataListSubmitAllocation,
  getDataListVAAdjustmentVersion,
  postDataSubmitAllocation,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const SubmitVendorAllocation = (props) => {
  const [defaultKeyTab, setDefaultKeyTab] = useState("rekap");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const [fields, setFields] = useState([]);
  const [dataListMasterSpec, setDataListMasterSpec] = useState([]);
  const [dataSubmitAllocation, setDataSubmitAllocation] = useState([]);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataListVASuggestionVersion, setDataListVASuggestionVersion] =
    useState([]);
  const [dataListVAAjustmentVersion, setDataListVAAjustmentVersion] = useState(
    []
  );
  const [state, setState] = useState({
    companyCodeState: "",
    versionState: "",
    periodeState: "",
    adjustmentVersionState: "",
  });
  const {
    companyCodeState,
    periodeState,
    adjustmentVersionState,
    versionState,
  } = state;
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataListVASuggestionVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
    };
    const res = await getDataListReportVASuggestionVersion(data).catch(
      (err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVASuggestionVersion(res.data.versions);
      setFields([
        {
          name: ["version"],
          value: res.data.versions[res.data.versions.length - 1].id,
        },
      ]);
      handleChange(
        "versionState",
        res.data.versions[res.data.versions.length - 1].id
      );
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState]);

  const fetchDataListVAAdjustmentVersion = useCallback(async () => {
    setIsLoading(true);
    const data = {
      periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      companyCode: companyCodeState || "",
      version: versionState || "",
    };
    const res = await getDataListVAAdjustmentVersion(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListVAAjustmentVersion(res.data.versions);
      if (res.data.versions.length === 0) {
        modal.warning({
          title: "Warning Message!",
          content: (
            <p>
              Oopps this version is empty for this periode{" "}
              {moment(periodeState).format("MM.YYYY")} in this company{" "}
              {companyCodeState} 😲
            </p>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodeState, companyCodeState, versionState]);

  const fetchDataListSubmitAllocation = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListSubmitAllocation(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data[params.key || "rekap"].total,
        });
        setDataSubmitAllocation(res.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListMasterMaterialGroup = useCallback(async ({ keyword }) => {
    setIsLoading(true);
    let data = {
      search: keyword || "",
    };
    const res = await getDataListMasterMaterialGroup(data).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListMasterSpec(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListMasterMaterialGroup({});
    fetchDataListCompany({});

    if (companyCodeState && periodeState) {
      fetchDataListVASuggestionVersion();
    }
  }, [
    fetchDataListMasterMaterialGroup,
    fetchDataListCompany,
    companyCodeState,
    periodeState,
    fetchDataListVASuggestionVersion,
  ]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const columnsTableRekap = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      render: (text) => <p>{text}</p>,
    },
    {
      title: `PR ${dataSubmitAllocation && dataSubmitAllocation.p1}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "pr1_qty",
              key: "pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "pr1_amount",
              key: "pr1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "pr1_contribution",
              key: "pr1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "pr1_sku",
              key: "pr1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_pr1_qty",
              key: "adj_pr1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_pr1_amount",
              key: "adj_pr1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_pr1_contribution",
              key: "adj_pr1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_pr1_sku",
              key: "adj_pr1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m1}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp1_qty",
              key: "mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp1_amount",
              key: "mrp1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp1_contribution",
              key: "mrp1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp1_sku",
              key: "mrp1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp1_qty",
              key: "adj_mrp1_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp1_amount",
              key: "adj_mrp1_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp1_contribution",
              key: "adj_mrp1_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp1_sku",
              key: "adj_mrp1_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m2}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp2_qty",
              key: "mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp2_amount",
              key: "mrp2_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp2_contribution",
              key: "mrp2_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp2_sku",
              key: "mrp2_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp2_qty",
              key: "adj_mrp2_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp2_amount",
              key: "adj_mrp2_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp2_contribution",
              key: "adj_mrp2_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp2_sku",
              key: "adj_mrp2_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: `Forcast ${dataSubmitAllocation && dataSubmitAllocation.m3}`,
      children: [
        {
          title: "System Sugestion",
          children: [
            {
              title: "Qty",
              dataIndex: "mrp3_qty",
              key: "mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "mrp3_amount",
              key: "mrp3_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "mrp3_contribution",
              key: "mrp3_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "mrp3_sku",
              key: "mrp3_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: "Adjustment",
          children: [
            {
              title: "Qty",
              dataIndex: "adj_mrp3_qty",
              key: "adj_mrp3_qty",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Amount",
              dataIndex: "adj_mrp3_amount",
              key: "adj_mrp3_amount",
              render: (text) => <p>{currencyFormatter(text)}</p>,
            },
            {
              title: "Contribution",
              dataIndex: "adj_mrp3_contribution",
              key: "adj_mrp3_contribution",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "SKU",
              dataIndex: "adj_mrp3_sku",
              key: "adj_mrp3_sku",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
  ];

  const columnsTableBudget = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: `PR ${dataSubmitAllocation && dataSubmitAllocation.p1}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "pr1_sugestion",
          key: "pr1_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "pr1_adjustment",
          key: "pr1_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "pr1_budget",
          key: "pr1_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "pr1_gain_loss",
          key: "pr1_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "pr1_sku",
          key: "pr1_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "pr1_os_budget",
          key: "pr1_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "pr1_os_budget_sku",
          key: "pr1_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "pr1_os_allocation",
          key: "pr1_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "pr1_os_allocation_sku",
          key: "pr1_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 1 ${dataSubmitAllocation && dataSubmitAllocation.m1}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp1_sugestion",
          key: "mrp1_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp1_adjustment",
          key: "mrp1_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp1_budget",
          key: "mrp1_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp1_gain_loss",
          key: "mrp1_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp1_sku",
          key: "mrp1_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp1_os_budget",
          key: "mrp1_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp1_os_budget_sku",
          key: "mrp1_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp1_os_allocation",
          key: "mrp1_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp1_os_allocation_sku",
          key: "mrp1_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 2 ${dataSubmitAllocation && dataSubmitAllocation.m2}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp2_sugestion",
          key: "mrp2_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp2_adjustment",
          key: "mrp2_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp2_budget",
          key: "mrp2_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp2_gain_loss",
          key: "mrp2_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp2_sku",
          key: "mrp2_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp2_os_budget",
          key: "mrp2_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp2_os_budget_sku",
          key: "mrp2_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp2_os_allocation",
          key: "mrp2_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp2_os_allocation_sku",
          key: "mrp2_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
    {
      title: `MRP 3 ${dataSubmitAllocation && dataSubmitAllocation.m3}`,
      children: [
        {
          title: "Sugestion",
          dataIndex: "mrp3_sugestion",
          key: "mrp3_sugestion",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Adjustment",
          dataIndex: "mrp3_adjustment",
          key: "mrp3_adjustment",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Budget",
          dataIndex: "mrp3_budget",
          key: "mrp3_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "Gain Loss",
          dataIndex: "mrp3_gain_loss",
          key: "mrp3_gain_loss",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "SKU",
          dataIndex: "mrp3_sku",
          key: "mrp3_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget",
          dataIndex: "mrp3_os_budget",
          key: "mrp3_os_budget",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Budget SKU",
          dataIndex: "mrp3_os_budget_sku",
          key: "mrp3_os_budget_sku",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation",
          dataIndex: "mrp3_os_allocation",
          key: "mrp3_os_allocation",
          render: (text) => <p>{text}</p>,
        },
        {
          title: "OS Allocation SKU",
          dataIndex: "mrp3_os_allocation_sku",
          key: "mrp3_os_allocation_sku",
          render: (text) => <p>{text}</p>,
        },
      ],
    },
  ];

  const columnsTable = {
    rekap: columnsTableRekap,
    budget: columnsTableBudget,
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmited = async () => {
    setIsLoading(true);

    const isEMptyField = Object.keys(state).find(
      (finding) => finding !== "adjustmentVersionState" && state[finding] === ""
    );

    if (!isEMptyField) {
      const data = {
        periode: periodeState || "",
        version: versionState || "",
        adjustment_version: adjustmentVersionState || "",
      };
      const res = await postDataSubmitAllocation(data).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res && res.status === 200) {
        setIsLoading(false);

        modal.success({
          title: "Success Message!",
          content: <p>Success submit data 🥳🥳🥳</p>,
        });
      }
    } else {
      setIsLoading(false);

      modal.warning({
        title: "Warning Message!",
        content: <p>{isEMptyField} cant be empty, please fill 😲😲😲</p>,
      });
    }
  };

  const onSearchMaterialGroupItem = (data) => {
    if (data.length === 0) {
      fetchDataListMasterMaterialGroup({});
    } else if (data.length > 3) {
      fetchDataListMasterMaterialGroup({ keyword: data });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListSubmitAllocation({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const resetTable = useCallback(() => {
    setDataSubmitAllocation([]);
  }, []);

  useEffect(() => {
    if (
      companyCodeState ||
      periodeState ||
      versionState ||
      adjustmentVersionState
    ) {
      resetTable();
    }
  }, [
    resetTable,
    companyCodeState,
    periodeState,
    versionState,
    adjustmentVersionState,
  ]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Submit Vendor Allocation</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          fields={fields}
          scrollToFirstError
          onFinish={(e) => {
            setDefaultKeyTab("rekap");
            setFilter({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
              adjustment_version: e.adjustment_version || "",
              version: e.version || "",
              materialGroup: e.materialGroup || "",
            });

            fetchDataListSubmitAllocation({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
              adjustment_version: e.adjustment_version || "",
              version: e.version || "",
              materialGroup: e.materialGroup || "",
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
        >
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    onChange={(e) => handleChange("companyCodeState", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    picker="month"
                    inputReadOnly={true}
                    onChange={(e) => handleChange("periodeState", e)}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>

          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[
                    {
                      required: true,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={true}
                    defaultValue={versionState.id}
                    value={versionState.id}
                    showSearch
                    style={{ width: 300 }}
                    onChange={(e) => handleChange("versionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVASuggestionVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVASuggestionVersion &&
                      dataListVASuggestionVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="adjustment_version"
                  label="Adjustment Version"
                  rules={[
                    {
                      required: false,
                      message: "Please input version!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled={
                      !periodeState || !companyCodeState || !versionState
                    }
                    showSearch
                    style={{ width: 180 }}
                    onChange={(e) => handleChange("adjustmentVersionState", e)}
                    loading={isLoading}
                    onFocus={() => fetchDataListVAAdjustmentVersion()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListVAAjustmentVersion &&
                      dataListVAAjustmentVersion.map((data, index) => {
                        const { labels, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {labels}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col hidden>
                <Form.Item
                  name="materialGroup"
                  label="Material Group"
                  rules={[
                    {
                      required: false,
                      message: "Please input material group!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    placeholder="Input min. 4 char"
                    onFocus={() => fetchDataListMasterMaterialGroup({})}
                    onSearch={onSearchMaterialGroupItem}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListMasterSpec &&
                      dataListMasterSpec.map((data, index) => {
                        const { description, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {description}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Space>
              <Button
                hidden={
                  dataSubmitAllocation.length === 0
                    ? true
                    : dataSubmitAllocation
                    ? false
                    : true
                }
                onClick={handleSubmited}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<EnterOutlined />}
              >
                Submit
              </Button>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Space>
          </Row>
        </Form>
        <Tabs
          onChange={(e) => {
            setDefaultKeyTab(e);
            setFilter({
              ...filter,
              key: e,
            });
            fetchDataListSubmitAllocation({
              ...filter,
              key: e,
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
          activeKey={defaultKeyTab}
          defaultActiveKey={defaultKeyTab}
        >
          {Object.keys(dataSubmitAllocation).map((key, index) => {
            if (
              key === "submitted" ||
              key === "m1" ||
              key === "m2" ||
              key === "m3" ||
              key === "p1"
            ) {
              // eslint-disable-next-line
              return;
            }
            return (
              <TabPane
                tab={`${key.charAt(0).toUpperCase() + key.slice(1)} Data`}
                key={key}
              >
                <Table
                  pagination={pagination}
                  onChange={handleTableChange}
                  loading={isLoading}
                  bordered
                  rowClassName={(record, index) => {
                    if (index % 2 === 1) {
                      return "color-gray-2";
                    } else {
                      return "color-gray-1";
                    }
                  }}
                  size="small"
                  columns={columnsTable[key]}
                  dataSource={
                    dataSubmitAllocation && dataSubmitAllocation[key]?.data
                  }
                  scroll={key === "rekap" ? { x: 2800 } : { x: 3800 }}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>

      {contextHolder}
    </Content>
  );
};

export default SubmitVendorAllocation;
