import "antd/dist/antd.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import ApprovalRFQ from "./pages/ApprovalRFQ";
import ApprovalVendorAlloc from "./pages/ApprovalVendorAlloc";
import BudgetReport from "./pages/BudgetReport";
import CockpitVendorAlloc from "./pages/CockpitVendorAlloc";
import ConfigCompany from "./pages/ConfigCompany";
import ConfigPlant from "./pages/ConfigPlant";
import ConfigUser from "./pages/ConfigUser";
import ConfigVendor from "./pages/ConfigVendor";
import Dashboard from "./pages/Dashboard";
import FinalAllocation from "./pages/FinalAllocation";
import Login from "./pages/Login";
import SSOPage from "./pages/SSOPage";
import MasterCommitReguler from "./pages/MasterCommitReguler";
import MasterCommitUtama from "./pages/MasterCommitUtama";
import MasterDesign from "./pages/MasterDesign";
import MasterRankingWeighting from "./pages/MasterRankingWeighting";
import MasterSpesification from "./pages/MasterSpesification";
import MasterSpesificationCartonBox from "./pages/MasterSpesificationCartonBox";
import MasterSpesificationItem from "./pages/MasterSpesificationItem";
import MasterStandardAllocation from "./pages/MasterStandardAllocation";
import MRPReport from "./pages/MRPReport";
import PriceAndRanking from "./pages/PriceAndRanking";
import PriceAchievement from "./pages/PriceAchievement";
import PRReport from "./pages/PRReport";
import ReportVendorPerformance from "./pages/ReportVendorPerformance";
import RFQReport from "./pages/RFQReport";
import SimulationAllocation from "./pages/SimulationAllocation";
import SubmitVendorAllocation from "./pages/SubmitVendorAllocation";
import UploadBudget from "./pages/UploadBudget";
import UploadRFQ from "./pages/UploadRFQ";
import VAAdjustment from "./pages/VAAdjustment";
import VASuggestionReport from "./pages/VASuggestionReport";
import VendorPerformance from "./pages/VendorPerformance";
import MasterSpesificationFluteVendorItem from "./pages/MasterSpesificationFluteVendorItem";
import MasterSpesificationLayerSekatItem from "./pages/MasterSpesificationLayerSekatItem";
import PageNotFound from "./pages/PageNotFound";
import { ProtectedRoute } from "./protectedRoute";
import MasterMinimumOrderQty from "./pages/MasterMinimumOrderQty";
import AllocationPlanOrder from "./pages/AllocationPlanOrder";
import SchedulePurchaseOrder from "./pages/SchedulePurchaseOrder";
import ApprovalVendorAllocHome from "./pages/ApprovalVendorAllocHome";
import ScheduleFinalPurchaseOrder from "./pages/ScheduleFinalPurchaseOrder";
import SubmitSchedulePurchaseOrder from "./pages/SubmitSchedulePurchaseOrder";
import ApprovalSchedulePurchaseOrderHome from "./pages/ApprovalSchedulePurchaseOrderHome";
import ApprovalSchedulePurchaseOrder from "./pages/ApprovalSchedulePurchaseOrder";
import Setting from "./pages/Setting";
import WelcomePage from "./pages/WelcomePage";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/sso" component={SSOPage} />
        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/welcome-page" component={WelcomePage} />
        <ProtectedRoute
          exact
          path="/master-specification"
          component={MasterSpesification}
        />
        <ProtectedRoute exact path="/master-design" component={MasterDesign} />
        <ProtectedRoute
          exact
          path="/rangking-weighting"
          component={MasterRankingWeighting}
        />
        <ProtectedRoute
          exact
          path="/commit-utama"
          component={MasterCommitUtama}
        />
        <ProtectedRoute
          exact
          path="/commit-reguler"
          component={MasterCommitReguler}
        />
        <ProtectedRoute
          exact
          path="/standard-allocation"
          component={MasterStandardAllocation}
        />
        <ProtectedRoute exact path="/upload-rfq" component={UploadRFQ} />
        <ProtectedRoute exact path="/approval-rfq" component={ApprovalRFQ} />
        <ProtectedRoute
          exact
          path="/vendor-performance"
          component={VendorPerformance}
        />
        <ProtectedRoute exact path="/upload-budget" component={UploadBudget} />
        <ProtectedRoute
          exact
          path="/cockpit-vendor-alloc"
          component={CockpitVendorAlloc}
        />
        <ProtectedRoute
          exact
          path="/submit-vendor-alloc"
          component={SubmitVendorAllocation}
        />
        <ProtectedRoute
          exact
          path="/approval-vendor-alloc"
          component={ApprovalVendorAlloc}
        />
        <ProtectedRoute exact path="/rfq-report" component={RFQReport} />
        <ProtectedRoute
          exact
          path="/report-vendor-performance"
          component={ReportVendorPerformance}
        />
        <ProtectedRoute
          exact
          path="/price-and-ranking"
          component={PriceAndRanking}
        />
        <ProtectedRoute
          exact
          path="/price-achievement"
          component={PriceAchievement}
        />
        <ProtectedRoute exact path="/mrp-report" component={MRPReport} />
        <ProtectedRoute exact path="/pr-report" component={PRReport} />\
        <ProtectedRoute
          exact
          path="/va-suggestion-report"
          component={VASuggestionReport}
        />
        <ProtectedRoute exact path="/va-adjustment" component={VAAdjustment} />
        <ProtectedRoute exact path="/budget-report" component={BudgetReport} />
        <ProtectedRoute
          exact
          path="/simulation-allocation"
          component={SimulationAllocation}
        />
        <ProtectedRoute
          exact
          path="/final-allocation"
          component={FinalAllocation}
        />
        <ProtectedRoute exact path="/user" component={ConfigUser} />
        <ProtectedRoute exact path="/company" component={ConfigCompany} />
        <ProtectedRoute exact path="/plant" component={ConfigPlant} />
        <ProtectedRoute exact path="/vendor" component={ConfigVendor} />
        <ProtectedRoute
          exact
          path="/master-specification-item"
          component={MasterSpesificationItem}
        />
        <ProtectedRoute
          exact
          path="/master-specification-cartoon-box"
          component={MasterSpesificationCartonBox}
        />
        <ProtectedRoute
          exact
          path="/master-specification-cartoon-box-flute-vendor-item"
          component={MasterSpesificationFluteVendorItem}
        />
        <ProtectedRoute
          exact
          path="/master-specification-cartoon-box-layer-sekat-item"
          component={MasterSpesificationLayerSekatItem}
        />
        <ProtectedRoute exact path="/moq" component={MasterMinimumOrderQty} />
        <ProtectedRoute
          exact
          path="/allocation-plan-order"
          component={AllocationPlanOrder}
        />
        <ProtectedRoute
          exact
          path="/schedule-plan-order"
          component={SchedulePurchaseOrder}
        />
        <ProtectedRoute
          exact
          path="/approval-vendor-alloc-home"
          component={ApprovalVendorAllocHome}
        />
        <ProtectedRoute
          exact
          path="/final-schedule-purchase-order"
          component={ScheduleFinalPurchaseOrder}
        />
        <ProtectedRoute
          exact
          path="/submit-schedule-purchase-order"
          component={SubmitSchedulePurchaseOrder}
        />
        <ProtectedRoute
          exact
          path="/home-approval-schedule-purchase-order"
          component={ApprovalSchedulePurchaseOrderHome}
        />
        <ProtectedRoute
          exact
          path="/approval-schedule-purchase-order-item"
          component={ApprovalSchedulePurchaseOrder}
        />
        <ProtectedRoute exact path="/setting" component={Setting} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default App;
