import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  getDataListCompany,
  getDataListFinalReport,
  getExportFinalReport,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const FinalAllocation = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [dataListCompany, setDataListCompany] = useState([]);
  const [filter, setFilter] = useState(null);
  const [dataFinalAllocation, setDataFinalAllocation] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataFinalAllocationPeriode, setDataFinalAllocationPeriode] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    companyCodeState: "",
    periodeState: "",
  });
  const { companyCodeState, periodeState } = state;

  const fetchDataListFinalAllocation = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListFinalReport(params).catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200 || res?.status === 201) {
        setPagination({
          ...params.pagination,
          total: res.data.items.total,
        });
        setDataFinalAllocation(res.data.items.data);
        setDataFinalAllocationPeriode(res.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListCompany({});
  }, [fetchDataListCompany]);

  const columnsTable = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "Material Group",
      dataIndex: "material_group",
      key: "material_group",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Vendor Desc.",
      dataIndex: "vendorDesc",
      key: "vendorDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Material Desc.",
      dataIndex: "materialDesc",
      key: "materialDesc",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "PR",
      children: [
        {
          title: moment(dataFinalAllocationPeriode?.pr1_month).format(
            "MMM-YYYY"
          ),
          children: [
            {
              title: "Qty",
              dataIndex: "pr1_qty_allocation",
              key: "pr1_qty_allocation",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "pr1_price_allocation",
              key: "pr1_price_allocation",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
    {
      title: "MRP",
      children: [
        {
          title: moment(dataFinalAllocationPeriode?.mrp1_month).format(
            "MMM-YYYY"
          ),
          children: [
            {
              title: "Qty",
              dataIndex: "mrp1_qty_allocation",
              key: "mrp1_qty_allocation",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp1_price_allocation",
              key: "mrp1_price_allocation",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: moment(dataFinalAllocationPeriode?.mrp2_month).format(
            "MMM-YYYY"
          ),
          children: [
            {
              title: "Qty",
              dataIndex: "mrp2_qty_allocation",
              key: "mrp2_qty_allocation",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp2_price_allocation",
              key: "mrp2_price_allocation",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
        {
          title: moment(dataFinalAllocationPeriode?.mrp3_month).format(
            "MMM-YYYY"
          ),
          children: [
            {
              title: "Qty",
              dataIndex: "mrp3_qty_allocation",
              key: "mrp3_qty_allocation",
              render: (text) => <p>{text}</p>,
            },
            {
              title: "Price",
              dataIndex: "mrp3_price_allocation",
              key: "mrp3_price_allocation",
              render: (text) => <p>{text}</p>,
            },
          ],
        },
      ],
    },
  ];

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const downloadTable = async () => {
    let isFilled = Object.keys(state).find((data) => state[data] === "");

    if (!isFilled) {
      setIsLoading(true);
      let data = {
        companyCode: companyCodeState || "",
        periode: periodeState ? moment(periodeState).format("MM.YYYY") : "",
      };
      const res = await getExportFinalReport(data).catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          modal.warning({
            title: "Warning Message!",
            content: <p>{messageContentSession}</p>,
            onOk: () => {
              localStorage.removeItem("dataUser");
              props.history.push("/");
            },
          });
        } else {
          modal.error({
            title: "Failed Message!",
            content: (
              <p>{err?.response?.data?.message || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
        }
      });

      if (res?.status === 200) {
        setIsLoading(false);
        window.open(res.data.url, "_blank");
      }
    } else {
      modal.warning({
        title: "Warning Message!",
        content: (
          <p>
            {`${isFilled} field is mandatory, please check and fill the data`}
          </p>
        ),
      });
    }
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchDataListFinalAllocation({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const resetTable = useCallback(() => {
    setDataFinalAllocation([]);
  }, []);

  useEffect(() => {
    if (companyCodeState || periodeState) {
      resetTable();
    }
  }, [resetTable, companyCodeState, periodeState]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Final Allocation</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          scrollToFirstError
          onFinish={(e) => {
            setFilter({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
            });
            fetchDataListFinalAllocation({
              periode: e.periode ? moment(e.periode).format("MM.YYYY") : "",
              companyCode: e.companyCode || "",
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
        >
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="companyCode"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    onChange={(e) => handleChange("companyCodeState", e)}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code } = data;
                        return (
                          <Option key={index} value={company_code}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="periode"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your periode!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    onChange={(e) => handleChange("periodeState", e)}
                    picker="month"
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end">
            <Col style={{ marginBottom: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                type="primary"
                htmlType="submit"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                onClick={downloadTable}
                loading={isLoading}
                type="primary"
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          bordered
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
          size="small"
          columns={columnsTable}
          dataSource={dataFinalAllocation}
          scroll={{ x: 1500 }}
        />
      </div>

      {contextHolder}
    </Content>
  );
};

export default FinalAllocation;
