import { Layout, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { postLogin } from "../services/retrieveData";
import "../styles/Dashboard.css";

const Login = (props) => {
  const queryString = props.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hashValue = urlParams.get("hash");
  const xValue = urlParams.get("x");
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const onHandleSSOLogin = async () => {
      let data = new FormData();

      data.append("hash", hashValue);
      data.append("x", xValue);
      setIsLoading(true);
      const res = await postLogin(data).catch((err) => {
        if (err?.response.status !== 200) {
          modal.error({
            title: "Failed Login!",
            content: (
              <p>{err?.response?.data?.error || "Internal server error"}</p>
            ),
            onOk: () => {},
          });
          setIsLoading(false);
        }
      });

      if (res && res.status === 200) {
        if (res.data === "") {
          setIsLoading(false);
          modal.error({
            title: "Failed login!",
            content: <p>Username/password is not correct, please try again</p>,
            onOk: () => {},
            onCancel: () => {},
          });
        } else {
          localStorage.setItem("dataUser", JSON.stringify(res.data));
          props.history.push("/welcome-page", {
            isShowPopNotif: true,
          });
          setIsLoading(false);
        }
      }
    };

    onHandleSSOLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashValue, xValue]);

  return (
    <Layout>
      <Spin
        spinning={isLoading}
        tip="Loading with SSO will short direct you to application..."
      ></Spin>
      {contextHolder}
    </Layout>
  );
};

export default Login;
