import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { messageContentSession } from "../global/messageGlobal";
import {
  deleteDataListPriceAchievementDelete,
  getDataListCompany,
  getDataListPriceAchievementIndex,
  postDataListPriceAchievementStore,
  putDataListPriceAchievementUpdate,
} from "../services/retrieveData";
import { ColorPrimaryEnum } from "../styles/Colors";
import { MarginEnum } from "../styles/Spacer";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const PriceAchievement = (props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [paginationPriceAchievement, setPaginationPriceAchievement] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataListCompany, setDataListCompany] = useState([]);
  const [dataPriceAchievement, setDataPriceAchievement] = useState([]);
  const [isModalActionVisible, setIsModalActionVisible] = useState({
    dataRow: null,
    typeAction: "",
    isShowModalAction: false,
  });
  const [state, setState] = useState({
    companyCodeState: "",
  });
  const { companyCodeState } = state;
  const { isShowModalAction, typeAction, dataRow } = isModalActionVisible;
  const [fieldFilter, setFieldFilter] = useState([
    {
      name: ["company_id"],
      value: null,
    },
    {
      name: ["search"],
      value: "",
    },
  ]);

  const [fields, setFields] = useState([]);

  const fetchDataListPriceAchievement = useCallback(
    async (params = {}) => {
      setIsLoading(true);
      const res = await getDataListPriceAchievementIndex(params).catch(
        (err) => {
          setIsLoading(false);

          if (err?.response?.data?.message === "Unauthenticated.") {
            modal.warning({
              title: "Warning Message!",
              content: <p>{messageContentSession}</p>,
              onOk: () => {
                localStorage.removeItem("dataUser");
                props.history.push("/");
              },
            });
          } else {
            modal.error({
              title: "Failed Message!",
              content: (
                <p>{err?.response?.data?.message || "Internal server error"}</p>
              ),
              onOk: () => {},
            });
          }
        }
      );

      if (res?.status === 200 || res?.status === 201) {
        setPaginationPriceAchievement({
          ...params.pagination,
          total: res.data.items.total,
        });
        setDataPriceAchievement(res.data.items.data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchDataListCompany = useCallback(async (params = {}) => {
    setIsLoading(true);
    const res = await getDataListCompany(params).catch((err) => {
      setIsLoading(false);

      if (err?.response?.data?.message === "Unauthenticated.") {
        modal.warning({
          title: "Warning Message!",
          content: <p>{messageContentSession}</p>,
          onOk: () => {
            localStorage.removeItem("dataUser");
            props.history.push("/");
          },
        });
      } else {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
          onOk: () => {},
        });
      }
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      setDataListCompany(res.data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataListPriceAchievement({ pagination: paginationPriceAchievement });
    fetchDataListCompany({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataListPriceAchievement, fetchDataListCompany]);

  const onSearchCompany = (data) => {
    if (data.length === 0) {
      fetchDataListCompany({});
    } else if (data.length > 2) {
      fetchDataListCompany({ search: data });
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    let data = {
      company_id: values.company_id,
      price_achievement: values.price_achievement,
    };

    const res = await postDataListPriceAchievementStore(data).catch((err) => {
      modal.error({
        title: "Failed Message!",
        content: (
          <p>{err?.response?.data?.message || "Internal server error"}</p>
        ),
      });
      setIsLoading(false);
    });

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListPriceAchievement({
        company_id: values.company_id,
        pagination: paginationPriceAchievement,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      modal.success({
        title: "Success Message!",
        content: <p>Successfully Added</p>,
      });
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);

    let data = {
      company_id: values.company_id,
      price_achievement: values.price_achievement,
    };

    const res = await putDataListPriceAchievementUpdate(dataRow.id, data).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: <p>{err?.response?.data?.message}</p>,
        });
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });
      setIsLoading(false);
      form.resetFields();
      fetchDataListPriceAchievement({
        company_id: values.company_id,
        pagination: paginationPriceAchievement,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Successfully Updated</p>,
      });
    }
  };

  const onDelete = async (values) => {
    setIsLoading(true);
    const res = await deleteDataListPriceAchievementDelete(values.id).catch(
      (err) => {
        modal.error({
          title: "Failed Message!",
          content: (
            <p>{err?.response?.data?.message || "Internal server error"}</p>
          ),
        });
        form.resetFields();
        setIsLoading(false);
      }
    );

    if (res && res.status === 200) {
      setIsLoading(false);
      form.resetFields();
      fetchDataListPriceAchievement({
        company_id: values.company_id,
        pagination: paginationPriceAchievement,
      });
      setIsModalActionVisible({
        ...isModalActionVisible,
        isShowModalAction: false,
      });

      modal.success({
        title: "Success Message!",
        content: <p>Deleted Successfully</p>,
      });
    }
  };

  let columnsTable = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, rowData, index) => <p>{index + 1}</p>,
    },
    {
      title: "company ID",
      dataIndex: "company_id",
      key: "company_id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price Rank",
      dataIndex: "price_rank",
      key: "price_rank",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price Achievement",
      dataIndex: "price_achievement",
      key: "price_achievement",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Submit Date",
      dataIndex: "submit_date",
      key: "submit_date",
      render: (text) => <p>{moment(text).format("DD-MM-YYYY")}</p>,
    },
    {
      title: "Submit By",
      dataIndex: "submit_by",
      key: "submit_by",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Update Date",
      dataIndex: "update_date",
      key: "update_date",
      render: (text) => <p>{text ? moment(text).format("DD-MM-YYYY") : ""}</p>,
    },
    {
      title: "Update By",
      dataIndex: "update_by",
      key: "update_by",
      render: (text) => <p>{text || ""}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setFields([
                  {
                    name: ["company_id"],
                    value: record.company_id,
                  },
                  {
                    name: ["price_achievement"],
                    value: record.price_achievement,
                  },
                ]);
                setIsModalActionVisible({
                  isShowModalAction: true,
                  dataRow: record,
                  typeAction: "EDIT",
                });
              }}
              type="default"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            {dataPriceAchievement.length - 1 === index &&
              Math.ceil(
                paginationPriceAchievement.total /
                  paginationPriceAchievement.pageSize
              ) === paginationPriceAchievement.current && (
                <Button
                  onClick={() =>
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: true,
                      dataRow: record,
                      typeAction: "DELETE",
                    })
                  }
                  type="danger"
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              )}
          </Space>
        );
      },
    },
  ];

  let findCompanyId = fieldFilter.find(
    (finding) => finding.name[0] === "company_id"
  );

  const onSearch = (data) => {
    if (data.length > 0) {
      fetchDataListPriceAchievement({
        search: data,
      });
    } else {
      fetchDataListPriceAchievement({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        company_id: filter.company_id,
      });
    }
  };

  const handleTableChangePriceAchievement = (pagination, filters, sorter) => {
    fetchDataListPriceAchievement({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: pagination,
      ...filters,
      ...filter,
    });
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const resetTable = useCallback(() => {
    setDataPriceAchievement([]);
  }, []);

  useEffect(() => {
    if (companyCodeState) {
      resetTable();
    }
  }, [resetTable, companyCodeState]);

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Title level={4}>Price Achievement</Title>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          onFinish={(value) => {
            setFilter({ company_id: value.company_id });
            fetchDataListPriceAchievement({
              company_id: value.company_id,
              pagination: {
                current: 1,
                pageSize: 10,
              },
            });
          }}
          scrollToFirstError
          onFieldsChange={(_, allFields) => setFieldFilter(allFields)}
          fields={fieldFilter}
        >
          <Row style={{ marginBottom: MarginEnum["2x"] }}>
            <Space size="large">
              <Col>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    defaultValue={findCompanyId.value}
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    onChange={(e) => handleChange("companyCodeState", e)}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="search"
                  label="Search"
                  rules={[
                    {
                      required: false,
                      message: "Please search!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Search
                    allowClear
                    placeholder="Input min. 1 char"
                    onSearch={onSearch}
                  />
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end" style={{ marginBottom: MarginEnum["2x"] }}>
            <Col style={{ marginLeft: MarginEnum["2x"] }}>
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: ColorPrimaryEnum.redVelvet,
                  border: `1px solid ${ColorPrimaryEnum.redVelvet}`,
                }}
                htmlType="submit"
                type="primary"
                icon={<EyeOutlined />}
              >
                View
              </Button>
            </Col>
            <Col
              hidden={dataPriceAchievement.length > 0 ? false : true}
              style={{ marginLeft: MarginEnum["2x"] }}
            >
              <Button
                onClick={() =>
                  setIsModalActionVisible({
                    ...isModalActionVisible,
                    typeAction: "ADD",
                    isShowModalAction: true,
                  })
                }
                style={{
                  backgroundColor: ColorPrimaryEnum.greenTosca,
                  border: `1px solid ${ColorPrimaryEnum.greenTosca}`,
                }}
                type="primary"
                icon={<FileAddOutlined />}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          loading={isLoading}
          onChange={handleTableChangePriceAchievement}
          pagination={paginationPriceAchievement}
          bordered
          columns={columnsTable.filter(
            (col) => col.dataIndex !== "id" && col.dataIndex !== "company_id"
          )}
          dataSource={dataPriceAchievement}
          size="small"
          rowClassName={(record, index) => {
            if (index % 2 === 1) {
              return "color-gray-2";
            } else {
              return "color-gray-1";
            }
          }}
        />
      </div>

      <Modal
        title={
          typeAction === "ADD"
            ? `Add Ranking ${paginationPriceAchievement.total + 1}`
            : `EDIT Ranking ${dataRow?.price_rank}`
        }
        forceRender={false}
        visible={isShowModalAction}
        width={["ADD", "EDIT"].includes(typeAction) ? 1000 : undefined}
        afterClose={() => form.resetFields()}
        onCancel={() => {
          form.resetFields();
          setIsModalActionVisible({
            ...isModalActionVisible,
            isShowModalAction: false,
          });
        }}
        footer={
          typeAction === "DELETE"
            ? [
                <Button
                  key="back"
                  onClick={() => {
                    form.resetFields();
                    setIsModalActionVisible({
                      ...isModalActionVisible,
                      isShowModalAction: false,
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  loading={isLoading}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    } else {
                      onDelete(dataRow);
                    }
                  }}
                  type="danger"
                  key="delete"
                >
                  Yes, I am sure
                </Button>,
              ]
            : null
        }
      >
        {typeAction === "VIEW" ? (
          dataRow &&
          Object.keys(dataRow).map((data, index) => {
            if (data === "key") {
              return null;
            }
            return (
              <p key={index} style={{ fontWeight: "bold", color: "#595959" }}>
                {data.replace("_", " ")} :{" "}
                <span style={{ fontWeight: "normal" }}>{dataRow[data]}</span>
              </p>
            );
          })
        ) : typeAction === "EDIT" ? (
          <Form
            {...formItemLayout}
            name="edit"
            form={form}
            onFinish={onEdit}
            onFieldsChange={(_, allFields) => setFields(allFields)}
            fields={fields}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="price_achievement"
                  label="Price Achievement"
                  rules={[
                    {
                      required: true,
                      message: "Please input price achievement!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : typeAction === "ADD" ? (
          <Form
            {...formItemLayout}
            name="add"
            form={form}
            onFinish={onFinish}
            fields={[
              {
                name: ["company_id"],
                value: findCompanyId.value,
              },
            ]}
            scrollToFirstError
          >
            <Row>
              <Col lg={12} md={24}>
                <Form.Item
                  name="company_id"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company!",
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    loading={isLoading}
                    onSearch={onSearchCompany}
                    onFocus={() => fetchDataListCompany({})}
                    placeholder="Input min. 3 char"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataListCompany &&
                      dataListCompany.map((data, index) => {
                        const { company_code, id } = data;
                        return (
                          <Option key={index} value={id}>
                            {company_code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  name="price_achievement"
                  label="Price Achievement"
                  rules={[
                    {
                      required: true,
                      message: "Please input price achievement!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <p>
            Do you want to delete this data <b>{dataRow?.price_rank}</b> ?
          </p>
        )}
      </Modal>
      {contextHolder}
    </Content>
  );
};

export default PriceAchievement;
